<template lang="html">
  <div id="blog-page">

    <section class="container oversized-container px-sm-0">
      <div class="d-block mt-3">
        <router-link class="link-back" :to="{ name: 'homePage'}"><i class="fal fa-chevron-left icon"></i> <span>Regresar</span></router-link>
      </div>
    </section>

    <section class="container oversized-container articles-section">
      <div class="row">
        <div class="col-12 box-gral-top">
          <div class="row align-items-center no-gutters">
            <div class="col">
              <h2 class="g-s1-title">Notas de interés</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="row row-articles-v2">
        <div class="col-12 col-lg-6 col-article-sample-2 no-border" v-for="(a, aInx) in blogs" :key="'aInx-'+aInx">
          <router-link class="box" to="/notas-de-interes/1">
            <div class="col box-image">
              <img class="placed-backg" src="public/images/pages/blog/empty-2.png" v-bind:style="{ backgroundImage: 'url('+a.imageUrl+')' }">
            </div>

            <div class="col box-descr">
              <h6 class="name">{{a.title}}</h6>
                  <h6 class="date">{{a.created}}</h6>

              <!-- <div class="descr">
                Descripción de nota para captar el interés del usuario de ejemplo de doble línea ejemplo sobre tema ejemplo o simplemente un showcase de la nota.
              </div> -->
            </div>
          </router-link>
        </div>
      </div>

     <!--  <div class="row">
        <div class="col-12 col-pagination-sample-1">
          <b-pagination-nav :link-gen="linkGen" :number-of-pages="10" use-router></b-pagination-nav>
        </div>
      </div> -->
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      blogs: [
      ],
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },

    linkGen(pageNum) {
      return {
        name: 'blogPage',
        query: { keywords: this.$route.query.keywords, page: pageNum }
      }
    },
    getBlogs(){
          axios.get(tools.url("/api/blogs")).then((response)=>{
          this.blogs = response.data;
          
        }).catch((error)=>{

        });
      }
  },
  mounted(){
      this.getBlogs();
  }
}
</script>
