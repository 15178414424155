<template lang="html">
  <div class="col-12 col-lg-11 col-xl-9 table-container-s1">
    <div v-if="$parent.showFLegalesComp" style="font-family: sans-serif !important; text-align: justify;">
      <h4 class="table-title">Fundamentos legales</h4>

      <table class="table table-hover" v-for="(item, tabl1Inx) in items" :key="'tabl1Inx-'+tabl1Inx">
        <thead>
          <tr>
            <th scope="col" width="178">{{ item.title1 }}</th>
            <th scope="col">{{ item.title2 }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, rtInx) in item.content">
            <th>{{ row.c1 }}</th>
            <td v-html="row.c2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      items: [
        { title1: 'Ley/Art./Párrafo/ Fracc.', title2: '7. Alcance de persona moral',
          content: [
            { c1: 'LISR-A7-P1',
              c2: 'Cuando en esta Ley se haga mención a persona moral, se entienden comprendidas, entre otras, las sociedades mercantiles, los organismos descentralizados que realicen preponderantemente actividades empresariales, las instituciones de crédito, las sociedades y asociaciones civiles y la asociación en participación cuando a través de ella se realicen actividades empresariales en México.'
            }, 
            { c1: 'LISR-A7-P2',
              c2: 'ALCANCE DE ACCIONES Y DE ACCIONISTAS<br>En los casos en los que se haga referencia a acciones, se entenderán incluidos los certificados de aportación patrimonial emitidos por las sociedades nacionales de crédito, las partes sociales, las participaciones en asociaciones civiles y los certificados de participación ordinarios emitidos con base en fideicomisos sobre acciones que sean autorizados conforme a la legislación aplicable en materia de inversión extranjera. Cuando se haga referencia a accionistas, quedarán comprendidos los titulares de los certificados a que se refiere este párrafo, de las partes sociales y de las participaciones señaladas. Tratándose de sociedades cuyo capital esté representado por partes sociales, cuando en esta Ley se haga referencia al costo comprobado de adquisición de acciones, se deberá considerar la parte alícuota que representen las partes sociales en el capital social de la sociedad de que se trate.'
            },
            
          ]

        },
        { title1: '', title2: 'Tasa del ISR de las personas morales',
          content: [
            { c1: 'LISR-A9-P1',
              c2: 'Las personas morales deberán calcular el impuesto sobre la renta, aplicando al resultado fiscal obtenido en el ejercicio la tasa del 30%.'
            }, 
          ]

        },
        { title1: '', title2: 'ISR por dividendos o utilidades distribuidos',
          content: [
            { c1: 'LISR-A10-P1',
              c2: 'Las personas morales que distribuyan dividendos o utilidades deberán calcular y enterar el impuesto que corresponda a los mismos, aplicando la tasa establecida en el artículo 9 de la presente Ley. Para estos efectos, los dividendos o utilidades distribuidos se adicionarán con el impuesto sobre la renta que se deba pagar en los términos de este artículo. Para determinar el impuesto que se debe adicionar a los dividendos o utilidades, éstos se deberán multiplicar por el factor de 1.4286 y al resultado se le aplicará la tasa establecida en el citado artículo 9 de esta Ley. El impuesto correspondiente a las utilidades distribuidas a que se refiere el artículo 78 de la presente Ley, se calculará en los términos de dicho precepto.'
            }, 
            { c1: '',
              c2: 'DIVIDENDOS O UTILIDADES PROVENIENTES DE CUFIN'
            },
            { c1: 'LISR-A10-P3',
              c2: 'No se estará obligado al pago del impuesto a que se refiere este artículo cuando los dividendos o utilidades provengan de la cuenta de utilidad fiscal neta que establece la presente Ley.'
            },
            { c1: '',
              c2: 'PAGO DEL IMPUESTO'
            },
            { c1: 'LISR-A10-P4',
              c2: 'El impuesto a que se refiere este artículo, se pagará además del impuesto del ejercicio a que se refiere el artículo 9 de esta Ley, tendrá el carácter de pago definitivo y se enterará ante las oficinas autorizadas, a más tardar el día 17 del mes inmediato siguiente a aquél en el que se pagaron los dividendos o utilidades."'
            },
            { c1: '',
              c2: 'ACREDITAMIENTO DEL ISR PAGADO POR DISTRIBUCION DE DIVIDENDOS O UTILIDADES'
            },
            { c1: 'LISR-A10-P5',
              c2: 'Cuando los contribuyentes a que se refiere este artículo distribuyan dividendos o utilidades y como consecuencia de ello paguen el impuesto que establece este artículo, podrán acreditar dicho impuesto de acuerdo a lo siguiente:'
            },
            { c1: 'LISR-A10-P5-F1',
              c2: 'I. El acreditamiento únicamente podrá efectuarse contra el impuesto sobre la renta del ejercicio que resulte a cargo de la persona moral en el ejercicio en el que se pague el impuesto a que se refiere este artículo.'
            },

            { c1: 'LISR-A10-P5-F1',
              c2: 'El monto del impuesto que no se pueda acreditar conforme al párrafo anterior, se podrá acreditar hasta en los dos ejercicios inmediatos siguientes contra el impuesto del ejercicio y contra los pagos provisionales de los mismos. Cuando el impuesto del ejercicio sea menor que el monto que se hubiese acreditado en los pagos provisionales, únicamente se considerará acreditable contra el impuesto del ejercicio un monto igual a este último.'
            },
            { c1: 'LISR-A10-P5-F1',
              c2: 'Cuando el contribuyente no acredite en un ejercicio el impuesto a que se refiere el cuarto párrafo de este artículo, pudiendo haberlo hecho conforme al mismo, perderá el derecho a hacerlo en los ejercicios posteriores hasta por la cantidad en la que pudo haberlo efectuado.'
            },
            { c1: 'LISR-A10-P7',
              c2: 'ISR SOBRE INTERESES O PRESTAMOS QUE SE CONSIDERAN DIVIDENDOS'
            },
            { c1: '',
              c2: 'Las personas morales que distribuyan los dividendos o utilidades a que se refiere el artículo 140 fracciones I y II de esta Ley, calcularán el impuesto sobre dichos dividendos o utilidades aplicando sobre los mismos la tasa establecida en el artículo 9 de la presente Ley. Este impuesto tendrá el carácter de definitivo.'
            },
          ]

        },
        { title1: '', title2: 'Otras obligaciones',
          content: [
            { c1: 'LISR-A76-P1',
              c2: 'Los contribuyentes que obtengan ingresos de los señalados en este Título, además de las obligaciones establecidas en otros artículos de esta Ley, tendrán las siguientes:'
            }, 
            { c1: 'LISR-A76-P1-F11-Inc. a)',
              c2: 'PM QUE PAGUEN DIVIDENDOS O UTILIDADES<br>Tratándose de personas morales que hagan los pagos por concepto de dividendos o utilidades a personas físicas o morales:<br>a) Efectuar los pagos con cheque nominativo no negociable del contribuyente expedido a nombre del accionista o a través de transferencias de fondos reguladas por el Banco de México a la cuenta de dicho accionista.'
            },

            { c1: 'LISR-A76-P1-F11-Inc. b)',
              c2: 'b) Proporcionar a las personas a quienes les efectúen pagos por los conceptos a que se refiere esta fracción, comprobante fiscal en el que se señale su monto, el impuesto sobre la renta retenido en términos de los artículos 140 y 164 de esta Ley, así como si éstos provienen de las cuentas establecidas en los artículos 77 y 85 de esta Ley, según se trate, o si se trata de los dividendos o utilidades a que se refiere el primer párrafo del artículo 10 de la misma. Este comprobante se entregará cuando se pague el dividendo o utilidad.'
            },
            
          ]

        },
        { title1: '', title2: 'Cuenta de utilidad fiscal neta',
          content: [
            { c1: 'LISR-A77-P1',
              c2: 'Las personas morales llevarán una cuenta de utilidad fiscal neta. Esta cuenta se adicionará con la utilidad fiscal neta de cada ejercicio, así como con los dividendos o utilidades percibidos de otras personas morales residentes en México y con los ingresos, dividendos o utilidades sujetos a regímenes fiscales preferentes en los términos del décimo párrafo del artículo 177 de esta Ley, y se disminuirá con el importe de los dividendos o utilidades pagados, con las utilidades distribuidas a que se refiere el artículo 78 de esta Ley, cuando en ambos casos provengan del saldo de dicha cuenta. Para los efectos de este párrafo, no se incluyen los dividendos o utilidades en acciones o los reinvertidos en la suscripción y aumento de capital de la misma persona que los distribuye, dentro de los 30 días naturales siguientes a su distribución. Para determinar la utilidad fiscal neta a que se refiere este párrafo, se deberá disminuir, en su caso, el monto que resulte en los términos de la fracción II del artículo 10 de esta Ley.'
            }, 
            { c1: 'LISR-A77-P2',
              c2: 'ACTUALIZACION DEL SALDO DE LA CUFIN<br>El saldo de la cuenta prevista en este artículo que se tenga al último día de cada ejercicio, sin incluir la utilidad fiscal neta del mismo, se actualizará por el periodo comprendido desde el mes en que se efectuó la última actualización y hasta el último mes del ejercicio de que se trate. Cuando se distribuyan o se perciban dividendos o utilidades con posterioridad a la actualización prevista en este párrafo, el saldo de la cuenta que se tenga a la fecha de la distribución o de percepción, se actualizará por el periodo comprendido desde el mes en el que se efectuó la última actualización y hasta el mes en el que se distribuyan o se perciban los dividendos o utilidades."'
            },
            
          ]

        },
        { title1: '', title2: 'Acumulación de dividendos y opción para acreditar el ISR',
          content: [
            { c1: 'LISR-A140-P1',
              c2: 'Las personas físicas deberán acumular a sus demás ingresos, los percibidos por dividendos o utilidades. Dichas personas físicas podrán acreditar, contra el impuesto que se determine en su declaración anual, el impuesto sobre la renta pagado por la sociedad que distribuyó los dividendos o utilidades, siempre que quien efectúe el acreditamiento a que se refiere este párrafo considere como ingreso acumulable, además del dividendo o utilidad percibido, el monto del impuesto sobre la renta pagado por dicha sociedad correspondiente al dividendo o utilidad percibido y además cuenten con la constancia y el comprobante fiscal a que se refiere la fracción XI del artículo 76 de esta Ley. Para estos efectos, el impuesto pagado por la sociedad se determinará aplicando la tasa del artículo 9 de esta Ley, al resultado de multiplicar el dividendo o utilidad percibido por el factor de 1.4286.'
            }, 
            { c1: '',
              c2: 'TASA ADICIONAL SOBRE DIVIDENDOS O UTILIDADES DISTRIBUIDOS POR PM'
            },
            { c1: 'LISR-A140-P2',
              c2: 'No obstante lo dispuesto en el párrafo anterior, las personas físicas estarán sujetas a una tasa adicional del 10% sobre los dividendos o utilidades distribuidos por las personas morales residentes en México. Estas últimas, estarán obligadas a retener el impuesto cuando distribuyan dichos dividendos o utilidades, y lo enterarán conjuntamente con el pago provisional del periodo que corresponda. El pago realizado conforme a este párrafo será definitivo.'
            },

            { c1: '',
              c2: 'RETENCION Y ENTERO DEL IMPUESTO'
            },

            { c1: 'LISR-A140-P3',
              c2: 'En los supuestos a que se refiere la fracción III de este artículo, el impuesto que retenga la persona moral se enterará a más tardar en la fecha en que se presente o debió presentarse la declaración del ejercicio correspondiente.'
            },

          ]

        },
        { title1: '', title2: 'Cálculo del impuesto anual',
          content: [
            { c1: 'LISR-A152-P1',
              c2: 'Las personas físicas calcularán el impuesto del ejercicio sumando, a los ingresos obtenidos conforme a los Capítulos I,III,IV,V,VI,VIII y IX de este Título, después de efectuar las deducciones autorizadas en dichos Capítulos, la utilidad gravable determinada conforme a las Secciones I o II del Capítulo II de este Título, al resultado obtenido se le disminuirá, en su caso, las deducciones a que se refiere el artículo 151 de esta Ley. A la cantidad que se obtenga se le aplicará la siguiente:'
            }, 
            { c1: '',
              c2: 'ACREDITAMIENTOS CONTRA EL ISR ANUAL'
            },
            { c1: 'LISR-A152-P3',
              c2: 'Contra el impuesto anual calculado en los términos de este artículo, se podrán efectuar los siguientes acreditamientos:'
            }, 
            { c1: 'LISR-A152-P3-F1',
              c2: 'PAGOS PROVISIONALES<br>El importe de los pagos provisionales efectuados durante el año de calendario.'
            }, 
            { c1: 'LISR-A152-P3-F2',
              c2: 'IMPUESTO ACREDITABLE<br>El impuesto acreditable en los términos de los artículos 5,140 y 145, penúltimo párrafo, de esta Ley.'
            }, 
            { c1: '',
              c2: 'SALDOS A FAVOR'
            }, 
            { c1: 'LISR-A152-P4',
              c2: 'En los casos en los que el impuesto a cargo del contribuyente sea menor que la cantidad que se acredite en los términos de este artículo, únicamente se podrá solicitar la devolución o efectuar la compensación del impuesto efectivamente pagado o que le hubiera sido retenido. Para los efectos de la compensación a que se refiere este párrafo, el saldo a favor se actualizará por el periodo comprendido desde el mes inmediato anterior en el que se presentó la declaración que contenga el saldo a favor y hasta el mes inmediato anterior al mes en el que se compense.'
            }, 
            { c1: '',
              c2: 'ACTUALIZACION DE LAS CANTIDADES DE LAS TARIFAS'
            }, 
            { c1: 'LISR-A152-P5',
              c2: 'Cuando la inflación observada acumulada desde el último mes que se utilizó en el cálculo de la última actualización de las cantidades establecidas en moneda nacional de las tarifas contenidas en este artículo y en el artículo 96 de esta Ley, exceda del 10%, dichas cantidades se actualizarán por el periodo comprendido desde el último mes que se utilizó en el cálculo de la última actualización y hasta el último mes del ejercicio en el que se exceda el porcentaje citado. Para estos efectos, se aplicará el factor de actualización que resulte de dividir el Indice Nacional de Precios al Consumidor del mes inmediato anterior al más reciente del periodo, entre el Indice Nacional de Precios al Consumidor correspondiente al último mes que se utilizó en el cálculo de la última actualización. Dicha actualización entrará en vigor a partir del 1 de enero del ejercicio siguiente en el que se haya presentado el mencionado incremento.'
            },
            
          ]

        },
        { title1: 'RM-3.9.1.', title2: 'Información y constancias sobre dividendos o utilidades distribuidos y retenciones del impuesto sobre los mismos',
          content: [
            { c1: '',
              c2: 'Para los efectos de los artículos 140, segundo párrafo, 142, fracción V, segundo párrafo y 164, fracción I, quinto párrafo de la Ley del ISR y 114, fracciones II y III de su Reglamento, las personas que perciban ingresos por dividendos o utilidades pagados en especie mediante la entrega de acciones de personas morales distintas de quienes realizan la distribución, podrán otorgar autorización para que se enajenen las acciones que sean necesarias para cubrir el impuesto causado, o bien proveer recursos, a efecto de que dichos intermediarios efectúen la retención y entero correspondientes. En caso contrario, las personas que perciban tales ingresos efectuarán el pago del impuesto respectivo a más tardar el día 17 del mes de calendario inmediato posterior al momento de su causación.<br>Para efectos de realizar la retención a que se refiere el párrafo anterior, los intermediarios financieros tomarán como base el precio de cierre de la acción correspondiente al día anterior al que se hayan distribuido los dividendos pagados en especie.'
            }, 

          ]

        },
        { title1: '', title2: 'Reglas para el reparto de las ganancias o pérdidas',
          content: [
            { c1: 'LGSM-A16-P1',
              c2: 'En el reparto de las ganancias o pérdidas se observarán, salvo pacto en contrario, las reglas siguientes:'
            }, 
            { c1: 'LGSM-A16-P1-F1',
              c2: 'La distribución de las ganancias o pérdidas entre los socios capitalistas se hará proporcionalmente a sus aportaciones;'
            }, 
            { c1: 'LGSM-A16-P1-F2',
              c2: 'Al socio industrial corresponderá la mitad de las ganancias, y si fueren varios, esa mitad se dividirá entre ellos por igual, y'
            }, 
            { c1: 'LGSM-A16-P1-F3',
              c2: 'El socio o socios industriales no reportarán las pérdidas.'
            }, 


          ]

        },
      
      ],
    }
  },

  watch: {
  	'$parent.showFLegalesComp': function(newVal, oldVal) {
      if(newVal == true){
        this.$refs['modal-flegales'].show();
      }
    }
  }
}
</script>
