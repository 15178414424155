<template lang="html">
  <div class="col-12 col-lg-8 col-form">

    <div class="box-form">
      <b-form class="form" @submit="onSubmit">
        <b-form-group class="custom-form-group"
          label="Correo"
          label-for="il-email">
          <b-form-input
            id="il-email"
            v-model="form.email"
            type="email"
            placeholder="correo@ejemplo.com"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group class="custom-form-group"
          label="Contraseña"
          label-for="il-password">
          <b-form-input
            id="il-password"
            v-model="form.password"
            type="password"
            placeholder="********"
            required
          ></b-form-input>
        </b-form-group>

        <!-- <p class="text-right">
          <router-link class="link-blue" to="/recuperar-contrasena"><u>Olvidé mi contraseña</u></router-link>
        </p> -->

        <p class="mt-5 pt-4">
          <b-button type="submit" class="btn _btn-st1">Acceder a mi cuenta</b-button>
         <!--  <router-link class="btn _btn-st1 btn-blue" :to="{ name: 'homePage' }">Comenzar sin acceder</router-link> -->
        </p>

        <!-- <p class="mt-4">
          ¿Nuevo aqui? <router-link class="link-blue" to="/registrarse">Crear una cuenta nueva</router-link>
        </p> -->
      </b-form>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      formErrors: [],

      form: {
        email: '',
        password: ''
      }
    }
  },

  watch:{
    '$root.logged':function(val)
    {
      if(val)
      {
        this.$router.push("/inicio");
      }
    }
  },

  methods: {
    onSubmit(event) {
        event.preventDefault();
      this.formErrors = [];
      axios.post(tools.url('/api/login'),this.form).then((response)=>{
        this.$root.user = response.data.user;
        this.$root.token = response.data.token;
        localStorage.setItem("tokenFront",this.$root.token);
        this.$root.logged = true;
        this.$router.push("/inicio");
      }).catch((error)=>{
          alert("Credenciales incorrectas");
      });
    }
  },

  mounted()
  {
    if(this.$root.logged)
      this.$router.push("/inicio");
    }
}
</script>
