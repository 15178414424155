<template lang="html">
  <div id="article-page">

    <section class="container oversized-container px-sm-0">
      <div class="d-block my-3">
        <router-link class="link-back" to="/notas-de-interes"><i class="fal fa-chevron-left icon"></i> <span>Regresar</span></router-link>
      </div>
    </section>

    <section class="container oversized-container content-section pl-sm-0">
      <div class="row justify-content-center">

        <!-- Artículo actual -->
        <div class="col-lg-8 col-article">
          <div class="box-image">
            <img :src="blog.imageUrl">
          </div>
          <h1 class="title">{{blog.title}}</h1>
          <h5 class="extra">Por Tirant contadores® <span class="txt-blue ml-3 date">{{blog.created}}</span></h5>

          <div class="descr">
            <p v-html="blog.body">
              
            </p>
          </div>
        </div>
        <!--  -->

        <!-- Artículos -->
        <!-- <div class="col-lg col-blog">
          <div class="row">
            <div class="col-12 col-article-sample-1 sample-2" v-for="(arti, arInx) in 3" :key="'ar-'+arInx">
              <router-link class="box-article" to="/notas-de-interes/1">
                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/blog/article-1.jpg)' }">
                  <img src="public/images/pages/blog/empty.png">
                </div>

                <div class="box-descr">
                  <h5>Título de este artículo con dos lineas máximo</h5>

                  <div class="descr">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magn.
                    </p>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div> -->
        <!--  -->
      </div>
    </section>

  </div>
</template>


<script>
export default {
  data(){
    return{
      blog: [
      ],
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },

    linkGen(pageNum) {
      return {
        name: 'blogPage',
        query: { keywords: this.$route.query.keywords, page: pageNum }
      }
    },
    getBlogs(){
          axios.get(tools.url("/api/blogs/"+this.$route.params.id)).then((response)=>{
          this.blog = response.data;
          
        }).catch((error)=>{

        });
      }
  },
  mounted(){
      this.getBlogs();
  }
}
</script>
