<template lang="html">
  <div id="notifications-page">

    <section class="container oversized-container px-sm-0">
      <div class="d-block mt-3">
        <router-link class="link-back" :to="{ name: 'homePage'}"><i class="fal fa-chevron-left icon"></i> <span>Regresar</span></router-link>
      </div>
    </section>

    <section class="container oversized-container notifications-section">
      <div class="row">
        <div class="col-12 box-gral-top">
          <div class="row align-items-center no-gutters">
            <div class="col">
              <h2 class="g-s1-title">Notificaciones recientes</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="row row-notifications">
        <div class="col-12 col-lg-6 col-notification-sample-1 no-border">
          <div class="box px-3 px-sm-0 pr-lg-3">
            <div class="col col-info">
              <h6 class="name">Nombre de título de nota</h6>
              <h6 class="date">Hoy hace 5 minutos - Por Tirant</h6>

              <div class="descr">
                Notificación de proceso de actualización o ajuste en proceso bla.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row row-notifications">
        <div class="col-12 col-lg-6 col-notification-sample-1 no-border" v-for="(x, nInx) in 4" :key="'nInx-'+nInx">
          <div class="box px-3 px-sm-0 pr-lg-3">
            <div class="col col-info">
              <h6 class="name">ISR por pago de Aguinaldo</h6>
              <h6 class="date">Hoy hace 5 minutos - Por Equipo y/o ubicación actual X.</h6>
              <!-- <div class="descr">
                Notificación de proceso de actualización o ajuste en proceso bla.
              </div> -->
            </div>

            <div class="col col-calc">
              <router-link to="">Ver cálculo</router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-pagination-sample-1">
          <b-pagination-nav :link-gen="linkGen" :number-of-pages="10" use-router></b-pagination-nav>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      blog: [
        { img: 'public/images/pages/blog/blog-1.jpg' },
        { img: 'public/images/pages/blog/blog-2.jpg' },
        { img: 'public/images/pages/blog/blog-3.jpg' },
        { img: 'public/images/pages/blog/blog-1.jpg' },

        { img: 'public/images/pages/blog/blog-1.jpg' },
        { img: 'public/images/pages/blog/blog-2.jpg' },
        { img: 'public/images/pages/blog/blog-3.jpg' },
        { img: 'public/images/pages/blog/blog-1.jpg' },
        { img: 'public/images/pages/blog/blog-1.jpg' },
        { img: 'public/images/pages/blog/blog-2.jpg' },
        { img: 'public/images/pages/blog/blog-3.jpg' },
        { img: 'public/images/pages/blog/blog-1.jpg' },
      ],
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },

    linkGen(pageNum) {
      return {
        name: 'blogPage',
        query: { keywords: this.$route.query.keywords, page: pageNum }
      }
    }
  }
}
</script>
