<template lang="html">
  <div id="catalog-form-page">

    <section class="container oversized-container main-section">
      <div class="row pt-4">
        <div class="col col-lg-3 pl-sm-0 col-link">
          <a class="link-back" href="javascript:history.back();" ><i class="fal fa-chevron-left icon"></i> <span>Regresar</span></a>
        </div>

        <div class="col-lg col-titles">
          <h1>ISR por el pago de las indemnizaciones por retiro</h1>
          <hr />
        </div>

        <div class="col col-lg-3 col-empty"></div>
      </div>

      <div class="row justify-content-center">
        <!-- Paso 1, Form -->
        <div class="col-12 col-mid col-form" v-if="step == 1">
          <h5 class="mb-4 title">DATOS GENERALES</h5>

          <b-form @submit="onSubmit">
          	<b-form-group class="custom-form-group-s2"
              label="Año del cálculo: "
              label-for="i-anio">
              	<div class="row">
                	<div class="col-12 col-sm">
                  		<b-form-select v-model="form.anio" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
		                    <b-form-select-option :value="null" disabled selected>Año</b-form-select-option>
		                    <b-form-select-option :value="year.value" v-for="(year, yindx) in yearsone" :key="yindx">{{year.value}}</b-form-select-option>

		                </b-form-select>
                	</div>
             	</div>
            </b-form-group>



            <b-form-group class="custom-form-group-s2"
              label="Salario diario:"
              label-for="i-vent" id="input-salario_diario">

              <currency-input id="i-vent" class="form-control"
                v-model="form.salario_diario" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.salario_diario != null" style="color: red;"><small>{{validation.salario_diario}}</small></div>
            </b-form-group>


            <b-form-group class="custom-form-group-s2"
              label="Días de aguinaldo según las prestaciones del empleador:"
              label-for="i-nul" id="input-dias_aguinaldo">

              <b-form-input
                id="i-nul"
                v-model="form.dias_aguinaldo"
                type="text"
                placeholder="0.00"
                @keypress="isNumber($event)"
                required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"

              ></b-form-input>
              <div v-if="validation.dias_aguinaldo != null" style="color: red;"><small>{{validation.dias_aguinaldo}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Días de vacaciones según las prestaciones del empleador:"
              label-for="i-nul" id="input-dias_vacaciones">

              <b-form-input
                id="i-nul"
                v-model="form.dias_vacaciones"
                type="text"
                placeholder="0.00"
                @keypress="isNumber($event)"
                required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"

              ></b-form-input>
              <div v-if="validation.dias_vacaciones != null" style="color: red;"><small>{{validation.dias_vacaciones}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Días de vacaciones según la LFT y fecha de ingreso:"
              label-for="i-nul" id="input-dias_vacaciones_lft" v-if="this.form.dias_vacaciones == 0 || this.form.dias_vacaciones == null">

              <b-form-input
                id="i-nul"
                v-model="form.dias_vacaciones_lft"
                type="text"
                placeholder="0.00"
                @keypress="isNumber($event)"
                disabled oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"

              ></b-form-input>
              <div v-if="validation.dias_vacaciones_lft != null" style="color: red;"><small>{{validation.dias_vacaciones_lft}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Días de vacaciones pendientes de otorgar (años anteriores):"
              label-for="i-nul" id="input-dias_vacaciones_pedientes">
              <b-form-input
                id="i-nul"
                v-model="form.dias_vacaciones_pedientes"
                type="text"
                placeholder="0.00"
                @keypress="isNumber($event)"
                required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"
              ></b-form-input>
              <div v-if="validation.dias_vacaciones_pedientes != null" style="color: red;"><small>{{validation.dias_vacaciones_pedientes}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Prima vacacional según las prestaciones del empleador:"
              label-for="i-nul" id="input-prima_vacacional">

              <b-form-input
                id="i-nul"
                v-model="form.prima_vacacional"
                type="text"
                placeholder="0.00"
                @keypress="isNumber($event)"
                required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"

              ></b-form-input>
              <div v-if="validation.prima_vacacional != null" style="color: red;"><small>{{validation.prima_vacacional}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Importe del último sueldo mensual ordinario:"
              label-for="i-vent" id="input-ultimo_sueldo">

              <currency-input id="i-vent" class="form-control"
                v-model="form.ultimo_sueldo" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.ultimo_sueldo != null" style="color: red;"><small>{{validation.ultimo_sueldo}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Salarios devengados y no pagados:"
              label-for="i-vent" id="input-salario_devengados">

              <currency-input id="i-vent" class="form-control"
                v-model="form.salario_devengados" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.salario_devengados != null" style="color: red;"><small>{{validation.salario_devengados}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Otros ingresos gravados pendientes de pago (por ej., bonos):"
              label-for="i-vent" id="input-otros_ingresos_gravados">

              <currency-input id="i-vent" class="form-control"
                v-model="form.otros_ingresos_gravados" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.otros_ingresos_gravados != null" style="color: red;"><small>{{validation.otros_ingresos_gravados}}</small></div>
            </b-form-group>

           	<b-form-group class="custom-form-group-s2"
              label="Otros ingresos exentos (por ej., caja o fondo de ahorro):"
              label-for="i-vent" id="input-otros_ingresos_exentos">

              <currency-input id="i-vent" class="form-control"
                v-model="form.otros_ingresos_exentos" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.otros_ingresos_exentos != null" style="color: red;"><small>{{validation.otros_ingresos_exentos}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Otros ingresos que forman parte del SDI para efectos de la indemnización (considerar monto diario):"
              label-for="i-vent" id="input-otros_ingresos_sdi">

              <currency-input id="i-vent" class="form-control"
                v-model="form.otros_ingresos_sdi" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.otros_ingresos_sdi != null" style="color: red;"><small>{{validation.otros_ingresos_sdi}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Ingresos gravados y pagados en el mismo mes por los cuales ya se retuvo el ISR:"
              label-for="i-vent" id="input-ingresos_gravados_retenidos_retenidos">

              <currency-input id="i-vent" class="form-control"
                v-model="form.ingresos_gravados_retenidos_retenidos" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.ingresos_gravados_retenidos_retenidos != null" style="color: red;"><small>{{validation.ingresos_gravados_retenidos_retenidos}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="ISR retenido antes de la baja y del mismo mes:"
              label-for="i-vent" id="input-isr_retenio_antes_baja">

              <currency-input id="i-vent" class="form-control"
                v-model="form.isr_retenio_antes_baja" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.isr_retenio_antes_baja != null" style="color: red;"><small>{{validation.isr_retenio_antes_baja}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="¿Trabajador con fondo de ahorro?: "
              label-for="i-fondo_ahorro">
              	<div class="row">
                	<div class="col-12 col-sm">
                  		<b-form-select v-model="form.fondo_ahorro" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
		                    <b-form-select-option :value="1">Sí</b-form-select-option>
		                   	<b-form-select-option :value="0">No</b-form-select-option>
		                </b-form-select>
                	</div>
             	</div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="% de aportación al fondo de ahorro por parte del empleador:"
              label-for="i-nul" id="input-aportacion_fondo_ahorro">

              <b-form-input
                id="i-nul"
                v-model="form.aportacion_fondo_ahorro"
                type="text"
                placeholder="0.00"
                @keypress="isNumber($event)"
                required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"

              ></b-form-input>
              <div v-if="validation.aportacion_fondo_ahorro != null" style="color: red;"><small>{{validation.aportacion_fondo_ahorro}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="¿Zona geográfica del trabajador?: "
              label-for="i-zona_geografica">
              	<div class="row">
                	<div class="col-12 col-sm">
                  		<b-form-select v-model="form.zona_geografica" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
		                    <b-form-select-option value="rp" >RP</b-form-select-option>
		                   	<b-form-select-option value="zlfn" >ZLFN</b-form-select-option>
		                </b-form-select>
                	</div>
             	</div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Fecha de ingreso del colaborador:">
              <div class="row">
                <div class="col-12 col-sm">
                  <b-form-select v-model="form.mes1" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Mes</b-form-select-option>
                    <b-form-select-option :value="month.value" v-for="(month, mindx) in months" :key="mindx">{{month.name}}</b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select v-model="form.dia1" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Día</b-form-select-option>
                    <b-form-select-option :value="day.value" v-for="(day, dndx) in daysone" :key="dndx">{{day.value}}</b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select v-model="form.anio1" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Año</b-form-select-option>
                    <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>
                  </b-form-select>
                </div>
              </div>
              <div v-if="validation.fecha_ingreso_trabajdor != null" style="color: red;"><small>{{validation.fecha_ingreso_trabajdor}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Fecha de la baja del colaborador:">
              <div class="row">
                <div class="col-12 col-sm">
                  <b-form-select v-model="form.mes2" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Mes</b-form-select-option>
                    <b-form-select-option :value="month.value" v-for="(month, mindx) in months" :key="mindx">{{month.name}}</b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select v-model="form.dia2" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Día</b-form-select-option>
                    <b-form-select-option :value="day.value" v-for="(day, dndx) in daystwo" :key="dndx">{{day.value}}</b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select v-model="form.anio2" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Año</b-form-select-option>
                    <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>
                  </b-form-select>
                </div>
              </div>
              <div v-if="validation.fecha_baja_trabajdor != null" style="color: red;"><small>{{validation.fecha_baja_trabajdor}}</small></div>
            </b-form-group>

            <p class="mt-3 mt-lg-5 pt-sm-4 text-center">
              <b-button type="submit" class="_btn-st1 btn-blue" id="btn_calc">Calcular</b-button>
            </p>
          </b-form>
        </div>
        <!-- Paso 1, FIN -->

        <!-- Paso 2, Mostrar datos -->
        <div class="col-12 col-mid col-results" v-if="step == 2">
          <!-- <h5 class="title">DATOS GENERALES</h5> -->
          <div class="row">
            <!-- <div class="col-12 col-line">
              <hr />
            </div> -->

            <div class="col-12 col-subbox">
              <div class="row align-items-center">
               <div class="col col-text">
                  <h2 class="trans-250 title with-link" @click="scrolltoTable">CONSULTAR FUNDAMENTOS LEGALES <i class="fas fa-info-circle icon"></i></h2>
                </div> 

                <!-- <div class="col col-share">
                  <a class="trans-150 btn-share" v-b-modal.modal-compartir>Compartir <i class="fas fa-share-alt"></i></a>
                </div> -->
              </div>
            </div>

            <div class="col-12 col-info">

              <div class="row row-body">
                <div class="col col-detail">
                  Total de ingresos y prestaciones por pagar al {{detailInfo.fecha_baja}}:
                </div>
                <div class="col col-med">
                  {{detailInfo.total_ingresos_prestaciones}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  Total de pagos de la indemnización al {{detailInfo.fecha_baja}}:
                </div>
                <div class="col col-med">
                  {{detailInfo.total_pagos_indemnizacion}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  ISR a retener por finiquito (o saldo a favor):
                </div>
                <div class="col col-med">
                  {{detailInfo.isr_retener_por_finiquito}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  ISR a retener por los ingresos de la indemnización:
                </div>
                <div class="col col-med">
                  {{detailInfo.isr_reter_por_ingresos}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  Neto a pagar al colaborador:
                </div>
                <div class="col col-med">
                  {{detailInfo.neto_pagar_colaborador}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  Total de ingresos gravados por finiquito al {{detailInfo.fecha_baja}}:
                </div>
                <div class="col col-med">
                  {{detailInfo.total_ingresos_gravados_finiquitos}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  Total de ingresos gravados por la indemnización al {{detailInfo.fecha_baja}}:
                </div>
                <div class="col col-med">
                  {{detailInfo.total_ingresos_gravados_indemnizacion}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  Tasa de retención conforme al último sueldo ordinario:
                </div>
                <div class="col col-med">
                  {{detailInfo.tasa_retencion_conforme}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  Años completos de servicio del colaborador:
                </div>
                <div class="col col-med">
                  {{detailInfo.anios_servicios}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  Total de años de servicio del colaborador para Art.76 lft:
                </div>
                <div class="col col-med">
                  {{detailInfo.total_anios_servicio_art76}}
                </div>
              </div>


            </div>

            <div class="col-12 col-options">
             <!--  <p class="mb-3">
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.excel" target="_blank">Exportar a Excel</a>
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.pdf" target="_blank">Exportar a PDF</a>
              </p> -->
              <p>
                <b-button type="submit" class="_btn-st1 btn-blue" @click="otherCalc">Hacer otro cálculo</b-button>
              </p>
            </div>

          </div>
        </div>

        <div class="col-12 ml-lg-4 col-actions" v-if="step == 2">
          <div class="box">
            <h5 class="mb-2 a-title-1">ACCIONES</h5>

            <div class="box-btns">
              <a class="btn-action" :href="detailInfo.links.excel" target="_blank"><i class="icon i-xls"></i> <span>Exportar a Excel</span></a>
              <a class="btn-action" :href="detailInfo.links.pdf" target="_blank"><i class="icon i-pdf"></i> Exportar a PDF</a>
              <!-- <a class="btn-action" href="#"><i class="icon i-share"></i> Compartir</a> -->
              <button class="btn-action" @click="$refs['modal-personalize'].show()"><i class="icon i-edt"></i> Personalizar</button>
            </div>

            <!-- <div class="box-line">
              <hr />
            </div>

            <div class="box-content">
              <b-card class="card-s1">
                <a v-b-toggle.collapse-1-inner class="a-title-1">FUNDAMENTOS LEGALES</a>
                <b-collapse id="collapse-1-inner" class="collapse-content">
                  <b-card>
                    <p>Proximamente...</p>
                  </b-card>
                </b-collapse>
              </b-card>
            </div> -->

          </div>
        </div>
        <!-- Paso 2, FIN -->

        <!-- Fundamentos legales -->
        <flegales-component id="table-flegales"></flegales-component>
        <!--  -->

      </div>
    </section>

    <!-- ******** Modals ******** -->
    <!-- Modal "Compartir a Correo" -->

    <!--  -->
    <b-modal id="modal-loading" ref="modal-loading" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc modal-class="modal-st-1">
        <div style="text-align: center;">
          <img class="img-fluid" src="public/images/shared/logo.svg" style="width: 230px">
          <br><br><h3>Realizando cálculo</h3>
        </div>
    </b-modal>

    <b-modal id="modal-personalize" ref="modal-personalize" hide-footer hide-header centered  modal-class="modal-st-1">
        <b-form @submit="onSubmitPersonalize">
            <b-form-group class="col-lg-6 custom-group-s1" label="Logo" >
                    <div class="col-sm-9">
                        <div class="fileinput fileinput-new" data-provides="fileinput">
                          <div class="fileinput-preview fileinput-exists thumbnail" >
                          </div>
                          <div>
                            <span class="btn btn-white btn-file">
                              <input type="file" accept=".pdf,image/*" name="logo">
                            </span>
                          </div>
                        </div>
                    </div>
              </b-form-group>
              <div class="box-footer">
                <button  type="button" class="btn _btn-st1" @click="$refs['modal-personalize'].hide()">Cancelar</button>
                <b-button style="text-align: center;" type="submit" class="_btn-st1 btn-blue">Personalizar</b-button>
               
              </div>
               <div class="box-footer" style="text-align: center;">
                 <a style="text-align: center;" v-if="pesonalizeUrl" class="btn _btn-st1 btn-blue" :href="pesonalizeUrl" target="_blank"><i class="fas fa-download"></i> Descargar</a>
               </div>

        </b-form>
    </b-modal>

  </div>
</template>

<script>
import FlegalesComponent from './components/fleg-comp-isr-pago-inmenzaciones-retiro.vue';
export default {
  data(){
    return{
      step: 1,
      // showModalFLegales: false,
      showFLegalesComp: false,

      form: {
          anio:null,
          mes:null,

      },
      /*form: {
          anio:2021,
          mes:8,
          total_ingresos:8000000,
          total_deducciones:4000000,
          amortizacion_de_perdida:2000000,
          pagos_provisionales:500000,
          isr_retenido:50000,
          graval_tasa_general:1000000,
          graval_tasa_cero:50000,
          exentos_para_iva:50000,
          iva_acreditable:100000,
          iva_acreditable_periodos:20000,
          iva_retenido:70000,
      },*/

      formComp: {
        email: null
      },
      yearsone:[],
      years:[],
      months:[
        {value:1,name:'Enero'},
        {value:2,name:'Febrero'},
        {value:3,name:'Marzo'},
        {value:4,name:'Abril'},
        {value:5,name:'Mayo'},
        {value:6,name:'Junio'},
        {value:7,name:'Julio'},
        {value:8,name:'Agosto'},
        {value:9,name:'Septiembre'},
        {value:10,name:'Octubre'},
        {value:11,name:'Noviembre'},
        {value:12,name:'Diciembre'},
      ],
      daysone:[],
      daystwo:[],

      detailInfo:[],
        validation:{
  			salario_diario:null,
  			dias_aguinaldo:null,
  			dias_vacaciones:null,
        dias_vacaciones_lft:null,
  			dias_vacaciones_pedientes:null,
  			prima_vacacional:null,
  			ultimo_sueldo:null,
  			salario_devengados:null,
  			otros_ingresos_gravados:null,
  			otros_ingresos_exentos:null,
  			otros_ingresos_sdi:null,
  			ingresos_gravados_retenidos_retenidos:null,
  			isr_retenio_antes_baja:null,
  			fondo_ahorro:null,
  			aportacion_fondo_ahorro:null,
  			zona_geografica:null,
  			fecha_ingreso_trabajdor:null,
  			fecha_baja_trabajdor:null,

  		},
      pesonalizeUrl:null,
        rawImg:null
    }
  },
  watch:{
    'form.mes1':function(val)
    {
      var fecha = new Date();
      if (this.form.anio1 == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = this.form.anio1;
      }
      var d = new Date(anio, val, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daysone = days;
      this.calculateDiasVacacionesLft();
    },
    'form.anio1':function(val)
    {
      var fecha = new Date();
      if (val == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = val;
      }
      var d = new Date(anio, this.form.mes1, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daysone = days;
      this.calculateDiasVacacionesLft();
    },
    'form.mes2':function(val)
    {
      var fecha = new Date();
      if (this.form.anio2 == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio = this.form.anio2;
      }
      var d = new Date(anio, val, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daystwo = days;
      this.calculateDiasVacacionesLft();
    },
    'form.anio2':function(val)
    {
      var fecha = new Date();
      if (val == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = val;
      }
      var d = new Date(anio, this.form.mes2, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daystwo = days;
      this.calculateDiasVacacionesLft();
    },
  },
  methods: {
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    onSubmit(event) { // Enviar formulario del paso 1
      event.preventDefault();

		this.salario_diario = null;
		this.dias_aguinaldo = null;
		this.dias_vacaciones = null;
    this.dias_vacaciones_lft = null;
		this.dias_vacaciones_pedientes = null;
		this.prima_vacacional = null;
		this.ultimo_sueldo = null;
		this.salario_devengados = null;
		this.otros_ingresos_gravados = null;
		this.otros_ingresos_exentos = null;
		this.otros_ingresos_sdi = null;
		this.ingresos_gravados_retenidos_retenidos = null;
		this.isr_retenio_antes_baja = null;
		this.fondo_ahorro = null;
		this.aportacion_fondo_ahorro = null;
		this.zona_geografica = null;
		this.fecha_ingreso_trabajdor = null;
		this.fecha_baja_trabajdor = null;

    if(this.anio <= 2022){ 
        if (this.form.dias_vacaciones > 0 && this.form.dias_vacaciones < 6) {
            this.validation.dias_vacaciones = '"Días de vacaciones según las prestaciones del empleador" no debe de ser menor a 6';
            var scrollt = document.getElementById("input-dias_vacaciones").offsetTop;
            setTimeout( ()=>{
              window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
            }, 200);
            return;
        }
      }
      else{
        if (this.form.dias_vacaciones > 0 && this.form.dias_vacaciones < 12) {
            this.validation.dias_vacaciones = '"Días de vacaciones según las prestaciones del empleador" no debe de ser menor a 12';
            var scrollt = document.getElementById("input-dias_vacaciones").offsetTop;
            setTimeout( ()=>{
              window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
            }, 200);
            return;
        }
      }

      if (this.form.salario_diario == 0 || this.form.salario_diario == null) {
          this.validation.salario_diario = '"Salario diario" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-salario_diario").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      if (this.form.ultimo_sueldo == 0 || this.form.ultimo_sueldo == null) {
          this.validation.ultimo_sueldo = '"Importe del último sueldo mensual ordinario" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-ultimo_sueldo").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
    	if (this.form.ultimo_sueldo == 0 || this.form.ultimo_sueldo == null) {
          this.validation.ultimo_sueldo = '"Importe del último sueldo mensual ordinario" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-ultimo_sueldo").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }

      this.$refs['modal-loading'].show();
      $('#btn_calc').hide();

      axios.post(tools.url("/calculateTwelve"),this.form).then((response)=>{
          this.detailInfo = response.data;
          this.$refs['modal-loading'].hide();
          this.step = 2;
          $('#btn_calc').show();
      }).catch((error)=>{
          $('#btn_calc').show();
          this.$refs['modal-loading'].hide();
          alert('El calculo no puede ser realizado con la información ingresada, porfavor modifica los parámetros e intenta de nuevo.');
      });
    },
    otherCalc(){
      location.reload();
    },

    scrolltoTable(){
      var tablePosY = document.getElementById("table-flegales").offsetTop;
      this.showFLegalesComp = true;

      setTimeout( ()=>{
        window.scrollTo({ top: tablePosY, left: 0, behavior: 'smooth' });
      }, 200);
    },

    onSubmitComp(event) { // Enviar formulario compartir del Modal "Compartir a Correo"
      event.preventDefault();
      this.$refs['modal-compartir'].hide();

      console.log("Compartir cálculo");
    },

    onSubmitCalc(event) { // Guardar cálculo y regresar a paso 1
      event.preventDefault();

      var keys = Object.keys(this.form);
      var resetForm = {};
      keys.forEach(key => resetForm[key] = null);
      this.form = resetForm;

      this.step = 1;
      this.$refs['modal-gcalculo'].hide();

      console.log("Hacer otro cálculo");
    },
    onSubmitPersonalize(event) { 
      event.preventDefault();
        this.pesonalizeUrl = null;
        const file =  jQuery('input[name="logo"]')[0].files[0];
        const reader = new FileReader()
        var self = this;
        reader.onloadend = () => {
           self.rawImg = reader.result;
           self.onSubmitPersonalizeData();
        }
        reader.readAsDataURL(file);

    },
    onSubmitPersonalizeData(){
         //enviamos el resultado y el logo
        this.$refs['modal-loading'].show();

        var formData = new FormData();
        formData.append("logo", this.rawImg);
        formData.append("formdata", JSON.stringify(this.detailInfo));

        axios.post(tools.url("/calculatePesonalize/12"),formData).then((response)=>{
            this.pesonalizeUrl = response.data.link;
            this.$refs['modal-loading'].hide();
            
        }).catch((error)=>{
            this.$refs['modal-loading'].hide();
            alert('Ocurrio un error al generar pdf');
        });
    },
    calculateDiasVacacionesLft(){
      const startDate = new Date(this.form.anio1+'-'+this.form.mes1+'-'+this.form.dia1);
      const endDate = new Date(this.form.anio2+'-'+this.form.mes2+'-'+this.form.dia2);

      const oneDayMs = 1000 * 60 * 60 * 24;
      const diffMs = endDate.getTime() - startDate.getTime();
      const diffDays = Math.floor(diffMs / oneDayMs);
      const years = Math.floor(diffDays / 365);
      const months = Math.floor(diffDays / 30.44) % 12;
      const days = diffDays - (years * 365) - (Math.floor(months * 30.44));
       
      var anios_por_fraccion = years;
      if(months > 5){
        anios_por_fraccion++;
      }
      var dias_vacaciones = 0;
      if(this.form.anio <= 2022)
      { 
          if(anios_por_fraccion >= 0.01 && anios_por_fraccion <= 1){
            dias_vacaciones = 6;
          }
          else if(anios_por_fraccion >= 1.01 && anios_por_fraccion <= 2){
            dias_vacaciones = 8;
          }
          else if(anios_por_fraccion >= 2.01 && anios_por_fraccion <= 3){
            dias_vacaciones = 10;
          }
          else if(anios_por_fraccion >= 3.01 && anios_por_fraccion <= 4){
            dias_vacaciones = 12;
          }
          else if(anios_por_fraccion >= 4.01 && anios_por_fraccion <= 9){
            dias_vacaciones = 14;
          }
          else if(anios_por_fraccion >= 9.01 && anios_por_fraccion <= 14){
            dias_vacaciones = 16;
          }
          else if(anios_por_fraccion >= 14.01 && anios_por_fraccion <= 19){
            dias_vacaciones = 18;
          }
          else if(anios_por_fraccion >= 19.01 && anios_por_fraccion <= 24){
            dias_vacaciones = 20;
          }
          else if(anios_por_fraccion >= 24.01 && anios_por_fraccion <= 29){
            dias_vacaciones = 22;
          }
          else if(anios_por_fraccion >= 29.01 && anios_por_fraccion <= 34){
            dias_vacaciones = 24;
          }
          else if(anios_por_fraccion >= 34.01 && anios_por_fraccion <= 39){
            dias_vacaciones = 26;
          }
      }
      else{
          if(anios_por_fraccion >= 0.01 && anios_por_fraccion <= 1){
            dias_vacaciones = 12;
          }
          else if(anios_por_fraccion >= 1.01 && anios_por_fraccion <= 2){
            dias_vacaciones = 14;
          }
          else if(anios_por_fraccion >= 2.01 && anios_por_fraccion <= 3){
            dias_vacaciones = 16;
          }
          else if(anios_por_fraccion >= 3.01 && anios_por_fraccion <= 4){
            dias_vacaciones = 18;
          }
          else if(anios_por_fraccion >= 4.01 && anios_por_fraccion <= 5){
            dias_vacaciones = 20;
          }
          else if(anios_por_fraccion >= 5.01 && anios_por_fraccion <= 10){
            dias_vacaciones = 22;
          }
          else if(anios_por_fraccion >= 10.01 && anios_por_fraccion <= 15){
            dias_vacaciones = 24;
          }
          else if(anios_por_fraccion >= 15.01 && anios_por_fraccion <= 20){
            dias_vacaciones = 26;
          }
          else if(anios_por_fraccion >= 20.01 && anios_por_fraccion <= 25){
            dias_vacaciones = 28;
          }
          else if(anios_por_fraccion >= 25.01 && anios_por_fraccion <= 30){
            dias_vacaciones = 30;
          }
          else if(anios_por_fraccion >= 30.01 && anios_por_fraccion <= 35){
            dias_vacaciones = 32;
          }
          else if(anios_por_fraccion >= 35.01 && anios_por_fraccion <= 40){
            dias_vacaciones = 32;
          } 
      }
      var bisiesto = (this.form.anio % 400 === 0) ? true : 
  			  (this.form.anio % 100 === 0) ? false : 
  				this.form.anio % 4 === 0;

        var vaciones_proporcionales_data = 0;
        var vaciones_proporcionales = 0;
        if(bisiesto == true){
          vaciones_proporcionales_data = dias_vacaciones / 366;
          var exp = Math.pow(10, 4 || 2); // 2 decimales por defecto
          vaciones_proporcionales = parseInt(vaciones_proporcionales_data * exp, 10) / exp;
        }
        else{
          vaciones_proporcionales_data = dias_vacaciones / 365;

          var exp = Math.pow(10, 4 || 2); // 2 decimales por defecto
          vaciones_proporcionales = parseInt(vaciones_proporcionales_data * exp, 10) / exp;
        }

        Date(this.form.anio1+'-'+this.form.mes1+'-'+this.form.dia1);

        const date1 = new Date('01/01/'+this.form.anio2);
        const date2 = new Date(this.form.mes2+'/'+this.form.dia2+'/'+this.form.anio2);
        
        const diffTime = Math.abs(date2 - date1);
        var diffDayss = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

        var dias_vacaciones_lft = vaciones_proporcionales * diffDayss;
        var exp = Math.pow(10, 4 || 2); // 2 decimales por defecto
        var dias_vacaciones_lft_round = parseInt(dias_vacaciones_lft * exp, 10) / exp;

        this.form.dias_vacaciones_lft = Math.round(dias_vacaciones_lft_round);
     
    }
  },

  mounted(){
    var fecha = new Date();
    var anio = fecha.getFullYear() + 1;
    var years = [];
    for (var i = 2016; i <= anio; i++) {
        var aux = {
          'value':i
        };
        years.push(aux);
    }

    years.reverse();
    this.years = years;



     var yearsone = [];
    for (var i = 2016; i <= anio; i++) {
        var aux = {
          'value':i
        };
        yearsone.push(aux);
    }

    yearsone.reverse();
    this.yearsone = yearsone;
  },

  components: {
    FlegalesComponent
  },
}
</script>
