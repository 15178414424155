<template lang="html">
  <header id="header" v-if="$route.name != 'accessPage'" v-bind:class="{ active : isMenuActive }">

    <div class="header-content">
      <div class="container oversized-container">
        <div class="row align-items-center no-gutters">
          <div class="col col-logo">
            <a class="logo-link" href="https://contadores.tirantonline.com.mx/base/conmex">
              <img src="public/images/shared/logo.svg" alt="Tirant">
            </a>

            <div class="line"></div>

            <div class="box-text">
              <h5>calculadoras<br />fiscales</h5>
            </div>
          </div>

          <!-- <div class="col col-user-info">
            NOTA IMPORTANTE. Solo poner EL PRIMER nombre, NO poner 2 nombres
            <h5>Bienvenido <span class="txt-blue">Eduardo</span></h5>
            <h6>a la plataforma de herramientas</h6>
          </div> -->

          <div class="col col-header-content">
            <div class="row no-gutters align-items-center">
              <div class="col col-menu">

              
                <router-link class="nv-link hide-767" :to="{ name: 'homePage' }">
                  <i class="fas fa-home-lg-alt icon"></i> Inicio
                </router-link>

                <!-- <router-link class="nv-link hide-991" to="/usuario">
                  <i class="fal fa-user icon"></i> Mi cuenta
                </router-link> -->
              </div>

              <div class="col col-navigation">
                <div class="box">
                  <!-- <div class="col col-profile">
                    <router-link class="placed-backg profile-photo" to="/usuario" v-bind:style="{ backgroundImage: 'url(public/images/shared/profile-photo.jpg)' }"></router-link>
                  </div> -->

                  <!-- <div class="col col-button">
                    <a class="btn-nav" v-bind:class="{ active : isMenuActive }" @click="isMenuActive = !isMenuActive"><i class="fal fa-bars"></i></a>
                  </div> -->
                </div>

                <div class="menu-container" v-bind:class="{ active : isMenuActive }">
                  <div class="trans-250 bg-menu" @click="isMenuActive = false"></div>

                  <div class="trans-250 box-menu">
                    <ul>
                      <!-- <li>
                        <router-link class="nav-link" to="">
                          <i class="n-notif">1</i> <span>Notificaciones</span>
                        </router-link>
                      </li>

                      <li class="li-sep"><a class="nav-link nav-sep"><hr /></a></li> -->

                      <li class="d-sm-none">
                        <router-link class="nav-link nav-home" :to="{ name: 'homePage' }">
                          <i class="fas fa-home-lg-alt icon"></i> Inicio
                        </router-link>
                      </li>
                      <!-- <li class="d-lg-none">
                        <router-link class="nav-link" to="/usuario">
                          <i class="fal fa-user icon"></i> Mi Cuenta
                        </router-link>
                      </li> -->
                      <li>
                        <router-link class="nav-link" to="/catalogos">
                          <i class="fal fa-calculator icon"></i> Catálogo de herramientas
                        </router-link>
                      </li>
                      <!-- <li>
                        <router-link class="nav-link" to="/notificaciones">
                          <i class="fas fa-bell icon"></i> Notificaciones recientes
                        </router-link>
                      </li> -->
                      <li>
                        <router-link class="nav-link" to="/notas-de-interes">
                          <i class="fal fa-typewriter icon"></i> Notas de interes
                        </router-link>
                      </li>

                      <li class="li-sep"><a class="nav-link nav-sep"><hr /></a></li>

                      <!-- <li>
                        <router-link class="nav-link" to="">
                          <i class="fal fa-question-circle icon"></i> Ayuda
                        </router-link>
                      </li> -->
                      <!-- <li>
                        <router-link class="nav-link" to="/terminos-y-condiciones">
                          <i class="fal fa-clipboard-list icon"></i> Términos y condiciones
                        </router-link>
                      </li>
                      <li>
                        <router-link class="nav-link" to="/aviso-de-privacidad">
                          <i class="fal fa-sticky-note icon"></i> Aviso de privacidad
                        </router-link>
                      </li>

                      <li class="li-sep"><a class="nav-link nav-sep"><hr /></a></li>

                      <li>
                        <router-link class="nav-link" to="/" @click.native="logout">
                          <i class="fal fa-sign-out-alt icon"></i> Cerrar sesión
                        </router-link>
                      </li> -->
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </header>
</template>

<script>
export default {
  data(){
    return{
      isMenuActive: false,
    }
  },

  watch: {
    '$route' (to, from){
      this.isMenuActive = false;
    },

    isMenuActive (val, Oldval) {
      if(this.isMenuActive == true){
        document.getElementById("app").classList.add("active-menu");
      }else{
        document.getElementById("app").classList.remove("active-menu");
      }
    },
    
  },
  methods:{
      logout(){
        axios.post(tools.url("/api/logout")).then((response)=>{
            this.$root.user = {};
            this.$root.logged=null;
            this.$root.type_logged='';
            this.$router.push('/');
          }).catch(()=>{});
      },
  }
}
</script>
