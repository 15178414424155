<template lang="html">
  <div class="col-12 col-lg-11 col-xl-9 table-container-s1">
    <div v-if="$parent.showFLegalesComp" style="font-family: sans-serif !important; text-align: justify;">
      <h4 class="table-title">Fundamentos legales</h4>

      <table class="table table-hover" v-for="(item, tabl1Inx) in items" :key="'tabl1Inx-'+tabl1Inx">
        <thead>
          <tr>
            <th scope="col" width="178">{{ item.title1 }}</th>
            <th scope="col">{{ item.title2 }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, rtInx) in item.content">
            <th>{{ row.c1 }}</th>
            <td v-html="row.c2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      items: [
        { title1: 'Ley/Art./Párrafo/ Fracc.', title2: 'CAPÍTULO IV<br>DE LOS INGRESOS POR ENAJENACIÓN DE BIENES',
          content: [
              { c1: 'LISR-120-P1',
                c2: 'Las personas que obtengan ingresos por enajenación de bienes, podrán efectuar las deducciones a que se refiere el artículo 121 de esta Ley; con la ganancia así determinada se calculará el impuesto anual como sigue:'
              },
              { c1: 'LISR-120-P1-F1',
                c2: 'La ganancia se dividirá entre el número de años transcurridos entre la fecha de adquisición y la de enajenación, sin exceder de 20 años.'
              },
              { c1: 'LISR-120-P1-F2',
                c2: 'El resultado que se obtenga conforme a la fracción anterior, será la parte de la ganancia que se sumará a los demás ingresos acumulables del año de calendario de que se trate y se calculará, en los términos de este Título, el impuesto correspondiente a los ingresos acumulables.'
              },

          ]
        },
        { title1: '', title2: 'Deducciones autorizadas',
          content: [
            { c1: 'LISR-121-P1',
              c2: 'Las personas físicas que obtengan ingresos por la enajenación de bienes podrán efectuar las siguientes deducciones:'
            },
            { c1: '',
              c2: 'COSTO COMPROBADO DE ADQUISICION'
            },
            { c1: 'LISR-121-P1-F1',
              c2: 'El costo comprobado de adquisición que se actualizará en los términos del artículo 124 de esta Ley. En el caso de bienes inmuebles, el costo actualizado será cuando menos 10% del monto de la enajenación de que se trate.'
            },
            { c1: '',
              c2: 'INVERSIONES EN CONSTRUCCIONES'
            },
            { c1: 'LISR-121-P1-F2',
              c2: 'El importe de las inversiones hechas en construcciones, mejoras y ampliaciones, cuando se enajenen bienes inmuebles o certificados de participación inmobiliaria no amortizables. Estas inversiones no incluyen los gastos de conservación. El importe se actualizará en los términos del artículo 124 de esta Ley.'
            },
            { c1: '',
              c2: 'GASTOS NOTARIALES, IMPUESTOS, DERECHOS, IMPUESTO LOCAL POR ENAJENACION DE INMUEBLES Y AVALUOS'
            },
            { c1: 'LISR-121-P1-F3',
              c2: 'Los gastos notariales, impuestos y derechos, por escrituras de adquisición y de enajenación, así como el impuesto local por los ingresos por enajenación de bienes inmuebles, pagados por el enajenante. Serán deducibles los pagos efectuados con motivo del avalúo de bienes inmuebles.'
            },
            { c1: '',
              c2: 'COMISIONES Y MEDIACIONES'
            },
            { c1: 'LISR-121-P1-F4',
              c2: 'Las comisiones y mediaciones pagadas por el enajenante, con motivo de la adquisición o de la enajenación del bien.'
            },
            { c1: '',
              c2: 'GANANCIA BASE DEL IMPUESTO'
            },
            { c1: 'LISR-121-P2',
              c2: 'La diferencia entre el ingreso por enajenación y las deducciones a que se refiere este artículo, será la ganancia sobre la cual, siguiendo el procedimiento señalado en el artículo 120 de esta Ley, se calculará el impuesto.'
            },
            { c1: '',
              c2: 'ACTUALIZACION DE GASTOS NOTARIALES, COMISIONES, ETC.'
            },
            { c1: 'LISR-121-P3',
              c2: 'Las deducciones a que se refieren las fracciones III y IV de este artículo se actualizarán por el periodo comprendido desde el mes en el que se efectuó la erogación respectiva y hasta el mes inmediato anterior a aquél en el que se realice la enajenación.'
            },
           
          ]
        },
        { title1: '', title2: 'Determinación del costo de adquisición',
          content: [
            { c1: 'LISR-123',
              c2: 'El costo de adquisición será igual a la contraprestación que se haya pagado para adquirir el bien, sin incluir los intereses ni las erogaciones a que se refiere el artículo anterior; cuando el bien se hubiese adquirido a título gratuito o por fusión o escisión de sociedades, se estará a lo dispuesto por el artículo 124 de esta Ley.'
            },           
          ]
        },
        { title1: '', title2: 'Actualización del costo de adquisición de inmuebles',
          content: [
            { c1: 'LISR-124-P1',
              c2: 'Para actualizar el costo comprobado de adquisición y, en su caso, el importe de las inversiones deducibles, tratándose de bienes inmuebles y de certificados de participación inmobiliaria no amortizables, se procederá como sigue:'
            },
            { c1: 'LISR-124-P1-F1',
              c2: 'Se restará del costo comprobado de adquisición, la parte correspondiente al terreno y el resultado será el costo de construcción. Cuando no se pueda efectuar esta separación se considerará como costo del terreno el 20% del costo total.'
            },
            { c1: 'LISR-124-P1-F2',
              c2: 'El costo de construcción deberá disminuirse a razón del 3% anual por cada año transcurrido entre la fecha de adquisición y la de enajenación; en ningún caso dicho costo será inferior al 20% del costo inicial. El costo resultante se actualizará por el periodo comprendido desde el mes en el que se realizó la adquisición y hasta el mes inmediato anterior a aquél en el que se efectúe la enajenación. Las mejoras o adaptaciones que implican inversiones deducibles deberán sujetarse al mismo tratamiento.'
            },
            { c1: '',
              c2: 'ACTUALIZACION DEL COSTO DE TERRENOS'
            },
            { c1: 'LISR-124-P1-F4',
              c2: 'En el caso de terrenos el costo de adquisición se actualizará por el periodo comprendido desde el mes en el que se realizó la adquisición y hasta el mes inmediato anterior a aquél en el que se efectúe la enajenación.'
            },
           
          ]
        },
        { title1: '', title2: 'Diferencia entre el valor de avalúo y el de enajenación',
          content: [
            { c1: 'LISR-125-P1',
              c2: 'Los contribuyentes podrán solicitar la práctica de un avalúo por corredor público titulado o institución de crédito, autorizados por las autoridades fiscales. Dichas autoridades estarán facultadas para practicar, ordenar o tomar en cuenta, el avalúo del bien objeto de enajenación y cuando el valor del avalúo exceda en más de un 10% de la contraprestación pactada por la enajenación, el total de la diferencia se considerará ingreso del adquirente en los términos del Capítulo V del Título IV de esta Ley; en cuyo caso, se incrementará su costo con el total de la diferencia citada.'
            },
            
          ]
        },
        { title1: '', title2: 'Pago provisional por cada operación',
          content: [
            { c1: 'LISR-126-P1',
              c2: 'Los contribuyentes que obtengan ingresos por la enajenación de bienes inmuebles, efectuarán pago provisional por cada operación, aplicando la tarifa que se determine conforme al siguiente párrafo a la cantidad que se obtenga de dividir la ganancia entre el número de años transcurridos entre la fecha de adquisición y la de enajenación, sin exceder de 20 años. El resultado que se obtenga conforme a este párrafo se multiplicará por el mismo número de años en que se dividió la ganancia, siendo el resultado el impuesto que corresponda al pago provisional.'
            },
            { c1: '',
              c2: 'TARIFA APLICABLE'
            },
            { c1: 'LISR-126-P2',
              c2: 'La tarifa aplicable para el cálculo de los pagos provisionales que se deban efectuar en los términos de este artículo, se determinará tomando como base la tarifa del artículo 96 de esta Ley, sumando las cantidades correspondientes a las columnas relativas al límite inferior, límite superior y cuota fija, que en los términos de dicho artículo resulten para cada uno de los meses del año en que se efectúe la enajenación y que correspondan al mismo renglón identificado por el por ciento para aplicarse sobre el excedente del límite inferior. Tratándose de los meses del mismo año, posteriores a aquél en que se efectúe la enajenación, la tarifa mensual que se considerará para los efectos de este párrafo, será igual a la del mes en que se efectúe la enajenación. Las autoridades fiscales mensualmente realizarán las operaciones aritméticas previstas en este párrafo para calcular la tarifa aplicable en dicho mes, la cual publicará en el Diario Oficial de la Federación.'
            },
            { c1: '',
              c2: 'OPERACIONES CONSIGNADAS EN ESCRITURAS PUBLICAS'
            },
            { c1: 'LISR-126-P3',
              c2: 'En operaciones consignadas en escrituras públicas, el pago provisional se hará mediante declaración que se presentará dentro de los quince días siguientes a aquél en que se firme la escritura o minuta. Los notarios, corredores, jueces y demás fedatarios, que por disposición legal tengan funciones notariales, calcularán el impuesto bajo su responsabilidad y lo enterarán en las oficinas autorizadas; así mismo deberán proporcionar al contribuyente que efectúe la operación correspondiente, conforme a las reglas de carácter general que emita el Servicio de Administración Tributaria, la información relativa a la determinación de dicho cálculo y deberá expedir comprobante fiscal, en el que conste la operación, así como el impuesto retenido que fue enterado. Dichos fedatarios, dentro los quince días siguientes a aquel en el que se firme la escritura o minuta, en el mes de febrero de cada año, deberán presentar ante las oficinas autorizadas, la información que al efecto establezca el Código Fiscal de la Federación respecto de las operaciones realizadas en el ejercicio inmediato anterior.'
            },
          
          ]
        },
        { title1: 'LISR-127', title2: 'Pago a la entidad federativa por enajenación de inmuebles',
          content: [
            { c1: 'LISR-127-P1',
              c2: 'Con independencia de lo dispuesto en el artículo 126 de esta Ley, los contribuyentes que enajenen terrenos, construcciones o terrenos y construcciones, efectuarán un pago por cada operación, aplicando la tasa del 5% sobre la ganancia obtenida en los términos de este Capítulo, el cual se enterará mediante declaración que presentarán ante las oficinas autorizadas de la entidad federativa en la cual se encuentre ubicado el inmueble de que se trate.'
            },
            { c1: '',
              c2: 'ACREDITAMIENTO CONTRA EL PAGO PROVISIONAL'
            },
            { c1: 'LISR-127-P2',
              c2: 'El impuesto que se pague en los términos del párrafo anterior será acreditable contra el pago provisional que se efectúe por la misma operación en los términos del artículo 126 de esta Ley. Cuando el pago a que se refiere este artículo exceda del pago provisional determinado conforme al citado precepto, únicamente se enterará el impuesto que resulte conforme al citado artículo 126 de esta Ley a la entidad federativa de que se trate.'
            },
            { c1: '',
              c2: 'OPERACIONES CONSIGNADAS EN ESCRITURAS PUBLICAS'
            },
            { c1: 'LISR-127-P3',
              c2: 'En el caso de operaciones consignadas en escrituras públicas, los notarios, corredores, jueces y demás fedatarios, que por disposición legal tengan funciones notariales, calcularán el pago a que se refiere este artículo bajo su responsabilidad y lo enterarán en las oficinas autorizadas a que se refiere el mismo en el mismo plazo señalado en el tercer párrafo del artículo 126 de esta Ley, y deberá expedir comprobante fiscal, en el que conste el monto de la operación, así como el impuesto retenido que fue enterado.'
            },
            { c1: '',
              c2: 'ACREDITAMIENTO CONTRA EL ISR DEL EJERCICIO'
            },
            { c1: 'LISR-127-P5',
              c2: 'El pago efectuado conforme a este artículo será acreditable contra el impuesto del ejercicio.'
            },
            { c1: '',
              c2: 'INGRESOS QUE NO SE CONSIDERAN'
            },
            { c1: 'LISR-127-P4',
              c2: 'No se considerarán ingresos por enajenación, los que deriven de la transmisión de propiedad de bienes por causa de muerte, donación o fusión de sociedades ni los que deriven de la enajenación de bonos, de valores y de otros títulos de crédito, siempre que el ingreso por la enajenación se considere interés en los términos del artículo 8 de esta Ley.'
            },
          ]
        },
        { title1: '', title2: 'Opción para la actualización de deducciones',
          content: [
            { c1: 'RM-R 3.15.1',
              c2: 'Para los efectos del artículo 121 de la Ley del ISR, las personas físicas que obtengan ingresos por la enajenación de inmuebles, podrán optar por efectuar la actualización de las deducciones, conforme a lo previsto en ese mismo artículo, o bien, aplicando la tabla contenida en el Anexo 9.'
            },
            
          ]
        },
        
        
        
      ],
    }
  },

  watch: {
  	'$parent.showFLegalesComp': function(newVal, oldVal) {
      if(newVal == true){
        this.$refs['modal-flegales'].show();
      }
    }
  }
}
</script>
