<template lang="html">
  <div class="col-12 col-lg-11 col-xl-9 table-container-s1">
    <div v-if="$parent.showFLegalesComp" style="font-family: sans-serif !important; text-align: justify;">
      <h4 class="table-title">Fundamentos legales</h4>

      <table class="table table-hover" v-for="(item, tabl1Inx) in items" :key="'tabl1Inx-'+tabl1Inx">
        <thead>
          <tr>
            <th scope="col" width="178">{{ item.title1 }}</th>
            <th scope="col" v-html="item.title2 "></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, rtInx) in item.content">
            <th>{{ row.c1 }}</th>
            <td v-html="row.c2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      items: [
      
      { title1: '', title2: '',
          content: [
            { c1: 'LSS-27-P1',
              c2: 'Integración del salario base de cotización<br>El salario base de cotización se integra con los pagos hechos en efectivo por cuota diaria, gratificaciones, percepciones, alimentación, habitación, primas, comisiones, prestaciones en especie y cualquiera otra cantidad o prestación que se entregue al trabajador por su trabajo. Se excluyen como integrantes del salario base de cotización, dada su naturaleza, los siguientes conceptos:'
            },
            { c1: 'LSS-27-P1-F1',
              c2: 'INSTRUMENTOS DE TRABAJO<br>Los instrumentos de trabajo tales como herramientas, ropa y otros similares;'
            },
            { c1: 'LSS-27-P1-F2',
              c2: 'EL AHORRO, BAJO CIERTAS CONDICIONES<br>El ahorro, cuando se integre por un depósito de cantidad semanaria, quincenal o mensual igual del trabajador y de la empresa; si se constituye en forma diversa o puede el trabajador retirarlo más de dos veces al año, integrará salario; tampoco se tomarán en cuenta las cantidades otorgadas por el patrón para fines sociales de carácter sindical;'
            },
            { c1: 'LSS-27-P1-F3',
              c2: 'EL AHORRO, BAJO CIERTAS CONDICIONES<br>El ahorro, cuando se integre por un depósito de cantidad semanaria, quincenal o mensual igual del trabajador y de la empresa; si se constituye en forma diversa o puede el trabajador retirarlo más de dos veces al año, integrará salario; tampoco se tomarán en cuenta las cantidades otorgadas por el patrón para fines sociales de carácter sindical;'
            },
            { c1: 'LSS-27-P1-F4',
              c2: 'CUOTAS IMSS, APORTACIONES INFONAVIT Y PTU<br>Las cuotas que en términos de esta Ley le corresponde cubrir al patrón, las aportaciones al Instituto del Fondo Nacional de la Vivienda para los Trabajadores, y las participaciones en las utilidades de la empresa;'
            },
            { c1: 'LSS-27-P1-F5',
              c2: 'ALIMENTACION Y HABITACION, SU PROPORCIONALIDAD<br>La alimentación y la habitación cuando se entreguen en forma onerosa a los trabajadores; se entiende que son onerosas estas prestaciones cuando el trabajador pague por cada una de ellas, como mínimo, el veinte por ciento del salario mínimo general diario que rija en el Distrito Federal;'
            },
            { c1: 'LSS-27-P1-F6',
              c2: 'DESPENSAS, SU PROPORCIONALIDAD<br>Las despensas en especie o en dinero, siempre y cuando su importe no rebase el cuarenta por ciento del salario mínimo general diario vigente en el Distrito Federal;'
            },
            { c1: 'LSS-27-P1-F7',
              c2: 'PREMIOS POR ASISTENCIA Y PUNTUALIDAD, SU PROPORCIONALIDAD<br>Los premios por asistencia y puntualidad, siempre que el importe de cada uno de estos conceptos no rebase el diez por ciento del salario base de cotización;'
            },
            { c1: 'LSS-27-P1-F8',
              c2: 'APORTACIONES PARA PLANES DE PENSIONES, SUS CONDICIONANTES<br>Las cantidades aportadas para fines sociales, considerándose como tales las entregadas para constituir fondos de algún plan de pensiones establecido por el patrón o derivado de contratación colectiva. Los planes de pensiones serán sólo los que reúnan los requisitos que establezca la Comisión Nacional del Sistema de Ahorro para el Retiro, y'
            },
            { c1: 'LSS-27-P1-F9',
              c2: 'TIEMPO EXTRAORDINARIO, SUS LIMITANTES<br>El tiempo extraordinario dentro de los márgenes señalados en la Ley Federal del Trabajo .'
            },
            { c1: 'LSS-27-P2',
              c2: 'REGISTRO CONTABLE DE CONCEPTOS<br>Para que los conceptos mencionados en este precepto se excluyan como integrantes del salario base de cotización, deberán estar debidamente registrados en la contabilidad del patrón.'
            },
            { c1: 'LSS-27-P3',
              c2: 'INTEGRACION DE LOS EXCEDENTES<br>En los conceptos previstos en las fracciones VI, VII y IX cuando el importe de estas prestaciones rebase el porcentaje establecido, solamente se integrarán los excedentes al salario base de cotización.'
            },
            
          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LSS-28',
              c2: '28. Límites del salario base de cotización<br>Los asegurados se inscribirán con el salario base de cotización que perciban en el momento de su afiliación, estableciéndose como límite superior el equivalente a veinticinco veces el salario mínimo general que rija en el Distrito Federal y como límite inferior el salario mínimo general del área geográfica respectiva.'
            },
           
          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LSS-30-P1',
              c2: 'Para determinar el salario diario base de cotización se estará a lo siguiente:'
            },
            { c1: 'LSS-30-P1-F1',
              c2: 'SALARIO FIJO<br>Cuando además de los elementos fijos del salario el trabajador percibiera regularmente otras retribuciones periódicas de cuantía previamente conocida, éstas se sumarán a dichos elementos fijos;'
            },
            { c1: 'LSS-30-P1-F2',
              c2: 'SALARIO VARIABLE<br>Si por la naturaleza del trabajo, el salario se integra con elementos variables que no puedan ser previamente conocidos, se sumarán los ingresos totales percibidos durante los dos meses inmediatos anteriores y se dividirán entre el número de días de salario devengado en ese periodo. Si se trata de un trabajador de nuevo ingreso, se tomará el salario probable que le corresponda en dicho periodo, y'
            },

          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LFT-68-P1',
              c2: 'Prolongación del tiempo extraordinario mayor a nueve horas a la semana<br>Los trabajadores no están obligados a prestar sus servicios por un tiempo mayor del permitido en este capítulo.'
            },
            { c1: 'LFT-68-P2',
              c2: 'La prolongación del tiempo extraordinario que exceda de nueve horas a la semana, obliga al patrón a pagar al trabajador el tiempo excedente con un doscientos por ciento más del salario que corresponda a las horas de la jornada, sin perjuicio de las sanciones establecidas en esta Ley.'
            },

          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LFT-84',
              c2: 'Integración del salario fijo y variable<br>El salario se integra con los pagos hechos en efectivo por cuota diaria, gratificaciones, percepciones, habitación, primas, comisiones, prestaciones en especie y cualquiera otra cantidad o prestación que se entregue al trabajador por su trabajo.'
            },
          ]

        },
        { title1: '', title2: '',
          content: [
            { c1: ' ACDO.SA2.HCT.250117/26.P.DJ',
              c2: 'Acuerdo ACDO.SA2.HCT.250117/26.P.DJ del 25-I-2017, que el límite inferior del SBC será el salario mínimo, pues está expresamente prohibida la inscripción al Instituto por debajo de ese límite, y el límite máximo será de 25 UMAS por constituir una referencia<br>Por tanto, debe considerarse que de conformidad con los artículos 4 y 5 de la LUMA , en el DO de 8-I-2021'
            },
          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LUMA-4',
              c2: 'Metodología para calcular y determinar anualmente el valor actualizado de la UMA<br><br>El valor actualizado de la UMA se calculará y determinará anualmente por el INEGI, de conformidad con el siguiente método:<br><br>DETERMINACIÓN DEL VALOR DIARIO<br>I. El valor diario se determinará multiplicando el valor diario de la UMA del año inmediato anterior por el resultado de la suma de uno más la variación interanual del índice Nacional de Precios al Consumidor del mes de diciembre del año inmediato anterior.<br><br>DETERMINACIÓN DEL VALOR MENSUAL<br>II. El valor mensual será el producto de multiplicar el valor diario de la UMA por 30.4.<br><br>DETERMINACIÓN DEL VALOR ANUAL<br>III. El valor anual será el producto de multiplicar el valor mensual de la UMA por 12.'
            },
            { c1: 'LUMA-5',
              c2: 'Publicación del valor de la UMA e inicio de vigencia de éste<br>El INEGI publicará en el Diario Oficial de la Federación dentro de los primeros diez días del mes de enero de cada año el valor diario, mensual y anual en moneda nacional de la UMA y entrarán en vigor dichos valores el 1o. de febrero de dicho año.'
            },

          ]
        },

        
      ],
    }
  },

  watch: {
  	'$parent.showFLegalesComp': function(newVal, oldVal) {
      if(newVal == true){
        this.$refs['modal-flegales'].show();
      }
    }
  }
}
</script>
