<template lang="html">
  <div id="catalog-form-page">

    <section class="container oversized-container main-section">
      <div class="row pt-4">
        <div class="col col-lg-3 pl-sm-0 col-link">
          <a class="link-back" href="javascript:history.back();" ><i class="fal fa-chevron-left icon"></i> <span>Regresar</span></a>
        </div>

        <div class="col-lg col-titles">
          <h1>ISR e IVA por actividades empresariales y profesionales</h1>
          <hr />
        </div>

        <div class="col col-lg-3 col-empty"></div>
      </div>

      <div class="row justify-content-center">
        <!-- Paso 1, Form -->
        <div class="col-12 col-mid col-form" v-if="step == 1">
          <h5 class="mb-4 title">DATOS GENERALES</h5>

          <b-form @submit="onSubmit">
          	<b-form-group class="custom-form-group-s2"
              label="Año del cálculo: "
              label-for="i-anio">
              	<div class="row">
                	<div class="col-12 col-sm">
                  		<b-form-select v-model="form.anio" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
		                    <b-form-select-option :value="null" disabled selected>Año</b-form-select-option>
		                    <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>

		                </b-form-select>
                	</div>
             	</div>
            </b-form-group>


            <b-form-group class="custom-form-group-s2">
                  <b-form-select v-model="form.mes" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Mes</b-form-select-option>
                    <b-form-select-option :value="month.value" v-for="(month, mindx) in months" :key="mindx">{{month.name}}</b-form-select-option>

                  </b-form-select>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Total de ingresos acumulables del período:"
              label-for="i-vent" id="input-total_ingresos">

              <currency-input id="i-vent" class="form-control"
                v-model="form.total_ingresos" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.total_ingresos != null" style="color: red;"><small>{{validation.total_ingresos}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Total de deducciones del período"
              label-for="i-vent" id="input-total_deducciones">

              <currency-input id="i-vent" class="form-control"
                v-model="form.total_deducciones" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.total_deducciones != null" style="color: red;"><small>{{validation.total_deducciones}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="PTU pagada en el ejercicio:"
              label-for="i-vent" id="input-ptu_pagada">

              <currency-input id="i-vent" class="form-control"
                v-model="form.ptu_pagada" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.ptu_pagada != null" style="color: red;"><small>{{validation.ptu_pagada}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Amortización de pérdidas:"
              label-for="i-vent" id="input-amortizacion_de_perdida">

              <currency-input id="i-vent" class="form-control"
                v-model="form.amortizacion_de_perdida" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.amortizacion_de_perdida != null" style="color: red;"><small>{{validation.amortizacion_de_perdida}}</small></div>
            </b-form-group>



            <b-form-group class="custom-form-group-s2"
              label="Pagos provisionales efectuados con anterioridad:"
              label-for="i-vent" id="input-pagos_provisionales">

              <currency-input id="i-vent" class="form-control"
                v-model="form.pagos_provisionales" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.pagos_provisionales != null" style="color: red;"><small>{{validation.pagos_provisionales}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="ISR retenido:"
              label-for="i-vent" id="input-isr_retenido">

              <currency-input id="i-vent" class="form-control"
                v-model="form.isr_retenido" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.isr_retenido != null" style="color: red;"><small>{{validation.isr_retenido}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Gravados a la tasa general:"
              label-for="i-vent"  id="input-graval_tasa_general">

              <currency-input id="i-vent" class="form-control"
                v-model="form.graval_tasa_general" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.graval_tasa_general != null" style="color: red;"><small>{{validation.graval_tasa_general}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Gravados a la tasa del 0%:"
              label-for="i-vent" id="input-graval_tasa_cero">

              <currency-input id="i-vent" class="form-control"
                v-model="form.graval_tasa_cero" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.graval_tasa_cero != null" style="color: red;"><small>{{validation.graval_tasa_cero}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Exentos para IVA:"
              label-for="i-vent" id="input-exentos_para_iva">

              <currency-input id="i-vent" class="form-control"
                v-model="form.exentos_para_iva" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.exentos_para_iva != null" style="color: red;"><small>{{validation.exentos_para_iva}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="IVA acreditable del período:"
              label-for="i-vent" id="input-iva_acreditable">

              <currency-input id="i-vent" class="form-control"
                v-model="form.iva_acreditable" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.iva_acreditable != null" style="color: red;"><small>{{validation.iva_acreditable}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="IVA acreditable de períodos anteriores pendiente de aplicar:"
              label-for="i-vent" id="input-iva_acreditable_periodos">

              <currency-input id="i-vent" class="form-control"
                v-model="form.iva_acreditable_periodos" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.iva_acreditable_periodos != null" style="color: red;"><small>{{validation.iva_acreditable_periodos}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="IVA retenido:"
              label-for="i-vent" id="input-iva_retenido">

              <currency-input id="i-vent" class="form-control"
                v-model="form.iva_retenido" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.iva_retenido != null" style="color: red;"><small>{{validation.iva_retenido}}</small></div>
            </b-form-group>

            <p class="mt-3 mt-lg-5 pt-sm-4 text-center">
              <b-button type="submit" class="_btn-st1 btn-blue" id="btn_calc">Calcular</b-button>
            </p>
          </b-form>
        </div>
        <!-- Paso 1, FIN -->

        <!-- Paso 2, Mostrar datos -->
        <div class="col-12 col-mid col-results" v-if="step == 2">
          <!-- <h5 class="title">DATOS GENERALES</h5> -->
          <div class="row">
            <!-- <div class="col-12 col-line">
              <hr />
            </div> -->

            <div class="col-12 col-subbox">
              <div class="row align-items-center">
               <div class="col col-text">
                  <h2 class="trans-250 title with-link" @click="scrolltoTable">CONSULTAR FUNDAMENTOS LEGALES <i class="fas fa-info-circle icon"></i></h2>
                </div>

                <!-- <div class="col col-share">
                  <a class="trans-150 btn-share" v-b-modal.modal-compartir>Compartir <i class="fas fa-share-alt"></i></a>
                </div> -->
              </div>
            </div>

            <div class="col-12 col-info">

              <div class="row row-body">
                <div class="col col-detail">
                  Base gravable para el pago provisional:
                </div>
                <div class="col col-med">
                  {{detailInfo.base_gravable}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  ISR según tarifa Art. <span style="font-size: 15px;font-weight: 400;position: relative;bottom: 3px;">9</span>6 LISR:
                </div>
                <div class="col col-med">
                  {{detailInfo.isr_segun_tar}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  ISR retenido:
                </div>
                <div class="col col-med">
                  {{detailInfo.isr_retenido}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  Pagos provisionales efectuados con anterioridad:
                </div>
                <div class="col col-med">
                  {{detailInfo.pagos_provisionales}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  ISR a pagar:
                </div>
                <div class="col col-med col-strong">
                  {{detailInfo.isr_pagar}}
                </div>
              </div>


              <div class="row row-body">
                <div class="col col-detail">
                  IVA trasladado:
                </div>
                <div class="col col-med">
                  {{detailInfo.iva_trasladado}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  IVA acreditable del período:
                </div>
                <div class="col col-med">
                  {{detailInfo.iva_acreditable}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  IVA retenido:
                </div>
                <div class="col col-med">
                  {{detailInfo.iva_retenido}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  IVA a cargo (saldo a favor) del período:
                </div>
                <div class="col col-med">
                  {{detailInfo.iva_acargo}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  IVA acreditable de períodos anteriores pendiente de aplicar:
                </div>
                <div class="col col-med">
                  {{detailInfo.iva_acreditable_periodos}}
                </div>
              </div>


              <div class="row row-body">
                <div class="col col-detail">
                  IVA a pagar:
                </div>
                <div class="col col-med col-strong">
                  {{detailInfo.iva_apagar}}
                </div>
              </div>


              <div class="row row-body ">
                <div class="col col-detail col-strong">
                  Total de ISR e IVA por pagar:
                </div>
                <div class="col col-med col-strong">
                  {{detailInfo.total_pagar}}
                </div>
              </div>


            </div>

            <div class="col-12 col-options">
              <!-- <p class="mb-3">
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.excel" target="_blank">Exportar a Excel</a>
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.pdf" target="_blank">Exportar a PDF</a>
              </p> -->
              <p>
                <b-button type="submit" class="_btn-st1 btn-blue" @click="otherCalc">Hacer otro cálculo</b-button>
              </p>
            </div>

          </div>
        </div>

        <div class="col-12 ml-lg-4 col-actions" v-if="step == 2">
          <div class="box">
            <h5 class="mb-2 a-title-1">ACCIONES</h5>

            <div class="box-btns">
              <a class="btn-action" :href="detailInfo.links.excel" target="_blank"><i class="icon i-xls"></i> <span>Exportar a Excel</span></a>
              <a class="btn-action" :href="detailInfo.links.pdf" target="_blank"><i class="icon i-pdf"></i> Exportar a PDF</a>
              <!-- <a class="btn-action" href="#"><i class="icon i-share"></i> Compartir</a> -->
              <button class="btn-action" @click="$refs['modal-personalize'].show()"><i class="icon i-edt"></i> Personalizar</button>
            </div>

            <!-- <div class="box-line">
              <hr />
            </div>

            <div class="box-content">
              <b-card class="card-s1">
                <a v-b-toggle.collapse-1-inner class="a-title-1">FUNDAMENTOS LEGALES</a>
                <b-collapse id="collapse-1-inner" class="collapse-content">
                  <b-card>
                    <p>Proximamente...</p>
                  </b-card>
                </b-collapse>
              </b-card>
            </div> -->

          </div>
        </div>
        <!-- Paso 2, FIN -->

        <!-- Fundamentos legales -->
        <flegales-component id="table-flegales"></flegales-component>
        <!--  -->

      </div>
    </section>

    <!-- ******** Modals ******** -->
    <!-- Modal "Compartir a Correo" -->

    <!--  -->
    <b-modal id="modal-loading" ref="modal-loading" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc modal-class="modal-st-1">
        <div style="text-align: center;">
          <img class="img-fluid" src="public/images/shared/logo.svg" style="width: 230px">
          <br><br><h3>Realizando cálculo</h3>
        </div>
    </b-modal>

    <b-modal id="modal-personalize" ref="modal-personalize" hide-footer hide-header centered  modal-class="modal-st-1">
        <b-form @submit="onSubmitPersonalize">
            <b-form-group class="col-lg-6 custom-group-s1" label="Logo" >
                    <div class="col-sm-9">
                        <div class="fileinput fileinput-new" data-provides="fileinput">
                          <div class="fileinput-preview fileinput-exists thumbnail" >
                          </div>
                          <div>
                            <span class="btn btn-white btn-file">
                              <input type="file" accept=".pdf,image/*" name="logo">
                            </span>
                          </div>
                        </div>
                    </div>
              </b-form-group>
              <div class="box-footer">
                <button  type="button" class="btn _btn-st1" @click="$refs['modal-personalize'].hide()">Cancelar</button>
                <b-button style="text-align: center;" type="submit" class="_btn-st1 btn-blue">Personalizar</b-button>
               
              </div>
               <div class="box-footer" style="text-align: center;">
                 <a style="text-align: center;" v-if="pesonalizeUrl" class="btn _btn-st1 btn-blue" :href="pesonalizeUrl" target="_blank"><i class="fas fa-download"></i> Descargar</a>
               </div>

        </b-form>
    </b-modal>

  </div>
</template>

<script>
import FlegalesComponent from './components/fleg-comp-isr-e-iva-actividades.vue';
export default {
  data(){
    return{
      step: 1,
      // showModalFLegales: false,
      showFLegalesComp: false,

      form: {
          anio:null,
          mes:null,

      },
      /*form: {
          anio:2021,
          mes:8,
          total_ingresos:8000000,
          total_deducciones:4000000,
          amortizacion_de_perdida:2000000,
          pagos_provisionales:500000,
          isr_retenido:50000,
          graval_tasa_general:1000000,
          graval_tasa_cero:50000,
          exentos_para_iva:50000,
          iva_acreditable:100000,
          iva_acreditable_periodos:20000,
          iva_retenido:70000,
      },*/

      formComp: {
        email: null
      },

      years:[],
      months:[
        {value:1,name:'Enero'},
        {value:2,name:'Febrero'},
        {value:3,name:'Marzo'},
        {value:4,name:'Abril'},
        {value:5,name:'Mayo'},
        {value:6,name:'Junio'},
        {value:7,name:'Julio'},
        {value:8,name:'Agosto'},
        {value:9,name:'Septiembre'},
        {value:10,name:'Octubre'},
        {value:11,name:'Noviembre'},
        {value:12,name:'Diciembre'},
      ],
      daysone:[],
      daystwo:[],

      detailInfo:[],
      validation:{
        total_ingresos:null,
        total_deducciones:null,
        amortizacion_de_perdida:null,
        pagos_provisionales:null,
        isr_retenido:null,
        graval_tasa_general:null,
        graval_tasa_cero:null,
        exentos_para_iva:null,
        iva_acreditable:null,
        iva_acreditable_periodos:null,
        iva_retenido:null,
        ptu_pagada:null
      },
      pesonalizeUrl:null,
      rawImg:null
    }
  },
  watch:{
    'form.mes1':function(val)
    {
      var fecha = new Date();
      if (this.form.anio1 == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = this.form.anio1;
      }
      var d = new Date(anio, val, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daysone = days;
    },
    'form.anio1':function(val)
    {
      var fecha = new Date();
      if (val == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = val;
      }
      var d = new Date(anio, this.form.mes1, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daysone = days;
    },
    'form.mes2':function(val)
    {
      var fecha = new Date();
      if (this.form.anio2 == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio = this.form.anio2;
      }
      var d = new Date(anio, val, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daystwo = days;
    },
    'form.anio2':function(val)
    {
      var fecha = new Date();
      if (val == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = val;
      }
      var d = new Date(anio, this.form.mes2, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daystwo = days;
    },
  },
  methods: {
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    onSubmit(event) { // Enviar formulario del paso 1
      event.preventDefault();
      this.validation.total_ingresos = null;
      this.validation.total_deducciones = null;
      this.validation.amortizacion_de_perdida = null;
      this.validation.pagos_provisionales = null;
      this.validation.isr_retenido = null;
      this.validation.graval_tasa_general = null;
      this.validation.graval_tasa_cero = null;
      this.validation.exentos_para_iva = null;
      this.validation.iva_acreditable = null;
      this.validation.iva_acreditable_periodos = null;
      this.validation.iva_retenido = null;
      this.validation.ptu_pagada = null;


      if (this.form.total_ingresos == 0 || this.form.total_ingresos == null) {
          this.validation.total_ingresos = '"Total de ingresos acumulables del período" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-total_ingresos").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      if (this.form.total_deducciones == 0 || this.form.total_deducciones == null) {
          this.validation.total_deducciones = '"Total de deducciones del período" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-total_deducciones").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      /*if (this.form.amortizacion_de_perdida == 0 || this.form.amortizacion_de_perdida == null) {
          this.validation.amortizacion_de_perdida = '"Amortización de pérdidas" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-amortizacion_de_perdida").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      if (this.form.pagos_provisionales == 0 || this.form.pagos_provisionales == null) {
          this.validation.pagos_provisionales = '"Pagos provisionales efectuados con anterioridad" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-pagos_provisionales").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      if (this.form.isr_retenido == 0 || this.form.isr_retenido == null) {
          this.validation.isr_retenido = '"ISR retenido" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-isr_retenido").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }**/
      if (this.form.graval_tasa_general == 0 || this.form.graval_tasa_general == null) {
          this.validation.graval_tasa_general = '"Gravados a la tasa general" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-graval_tasa_general").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      /*if (this.form.graval_tasa_cero == 0 || this.form.graval_tasa_cero == null) {
          this.validation.graval_tasa_cero = '"Gravados a la tasa del 0%" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-graval_tasa_cero").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      if (this.form.exentos_para_iva == 0 || this.form.exentos_para_iva == null) {
          this.validation.exentos_para_iva = '"Exentos para IVA" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-exentos_para_iva").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      if (this.form.iva_acreditable == 0 || this.form.iva_acreditable == null) {
          this.validation.iva_acreditable = '"IVA acreditable del período:" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-iva_acreditable").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      if (this.form.iva_acreditable_periodos == 0 || this.form.iva_acreditable_periodos == null) {
          this.validation.iva_acreditable_periodos = '"IVA acreditable de períodos anteriores pendiente de aplicar" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-iva_acreditable_periodos").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      if (this.form.iva_retenido == 0 || this.form.iva_retenido == null) {
          this.validation.iva_retenido = '"IVA retenido" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-iva_retenido").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }*/

      this.$refs['modal-loading'].show();
      $('#btn_calc').hide();

      axios.post(tools.url("/calculateThree"),this.form).then((response)=>{
          this.detailInfo = response.data;
          this.$refs['modal-loading'].hide();
          this.step = 2;
      }).catch((error)=>{
          $('#btn_calc').show();
          this.$refs['modal-loading'].hide();
          alert('El calculo no puede ser realizado con la información ingresada, porfavor modifica los parámetros e intenta de nuevo.');
      });
    },
    otherCalc(){
      location.reload();
    },

    scrolltoTable(){
      var tablePosY = document.getElementById("table-flegales").offsetTop;
      this.showFLegalesComp = true;

      setTimeout( ()=>{
        window.scrollTo({ top: tablePosY, left: 0, behavior: 'smooth' });
      }, 200);
    },

    onSubmitComp(event) { // Enviar formulario compartir del Modal "Compartir a Correo"
      event.preventDefault();
      this.$refs['modal-compartir'].hide();

      console.log("Compartir cálculo");
    },

    onSubmitCalc(event) { // Guardar cálculo y regresar a paso 1
      event.preventDefault();

      var keys = Object.keys(this.form);
      var resetForm = {};
      keys.forEach(key => resetForm[key] = null);
      this.form = resetForm;

      this.step = 1;
      this.$refs['modal-gcalculo'].hide();

      console.log("Hacer otro cálculo");
    },
    onSubmitPersonalize(event) { 
      event.preventDefault();
        this.pesonalizeUrl = null;
        const file =  jQuery('input[name="logo"]')[0].files[0];
        const reader = new FileReader()
        var self = this;
        reader.onloadend = () => {
           self.rawImg = reader.result;
           self.onSubmitPersonalizeData();
        }
        reader.readAsDataURL(file);

    },
    onSubmitPersonalizeData(){
         //enviamos el resultado y el logo
        this.$refs['modal-loading'].show();

        var formData = new FormData();
        formData.append("logo", this.rawImg);
        formData.append("formdata", JSON.stringify(this.detailInfo));

        axios.post(tools.url("/calculatePesonalize/3"),formData).then((response)=>{
            this.pesonalizeUrl = response.data.link;
            this.$refs['modal-loading'].hide();
            
        }).catch((error)=>{
            this.$refs['modal-loading'].hide();
            alert('Ocurrio un error al generar pdf');
        });
    }
  },

  mounted(){
    var fecha = new Date();
    var anio = fecha.getFullYear();
    var years = [];
    for (var i = 2013; i <= anio; i++) {
        var aux = {
          'value':i
        };
        years.push(aux);
    }

    years.reverse();
    this.years = years;
  },

  components: {
    FlegalesComponent
  },
}
</script>
