<template lang="html">
  <div class="col-12 col-lg-11 col-xl-9 table-container-s1">
    <div v-if="$parent.showFLegalesComp" style="font-family: sans-serif !important; text-align: justify;">
      <h4 class="table-title">Fundamentos legales</h4>

      <table class="table table-hover" v-for="(item, tabl1Inx) in items" :key="'tabl1Inx-'+tabl1Inx">
        <thead>
          <tr>
            <th scope="col" width="178">{{ item.title1 }}</th>
            <th scope="col">{{ item.title2 }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, rtInx) in item.content">
            <th>{{ row.c1 }}</th>
            <td v-html="row.c2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>



<script>
export default {
  data(){
    return{
      items: [
        { title1: '', title2: '',
          content: [
            { c1: 'LSS-27-P1',
              c2: 'Integración del salario base de cotización<br>El salario base de cotización se integra con los pagos hechos en efectivo por cuota diaria, gratificaciones, percepciones, alimentación, habitación, primas, comisiones, prestaciones en especie y cualquiera otra cantidad o prestación que se entregue al trabajador por su trabajo. Se excluyen como integrantes del salario base de cotización, dada su naturaleza, los siguientes conceptos:'
            },
            { c1: 'LSS-27-P1-F1',
              c2: 'INSTRUMENTOS DE TRABAJO<br>Los instrumentos de trabajo tales como herramientas, ropa y otros similares;'
            },
            { c1: 'LSS-27-P1-F2',
              c2: 'EL AHORRO, BAJO CIERTAS CONDICIONES<br>El ahorro, cuando se integre por un depósito de cantidad semanaria, quincenal o mensual igual del trabajador y de la empresa; si se constituye en forma diversa o puede el trabajador retirarlo más de dos veces al año, integrará salario; tampoco se tomarán en cuenta las cantidades otorgadas por el patrón para fines sociales de carácter sindical;'
            },
            { c1: 'LSS-27-P1-F3',
              c2: 'EL AHORRO, BAJO CIERTAS CONDICIONES<br>El ahorro, cuando se integre por un depósito de cantidad semanaria, quincenal o mensual igual del trabajador y de la empresa; si se constituye en forma diversa o puede el trabajador retirarlo más de dos veces al año, integrará salario; tampoco se tomarán en cuenta las cantidades otorgadas por el patrón para fines sociales de carácter sindical;'
            },
            { c1: 'LSS-27-P1-F4',
              c2: 'CUOTAS IMSS, APORTACIONES INFONAVIT Y PTU<br>Las cuotas que en términos de esta Ley le corresponde cubrir al patrón, las aportaciones al Instituto del Fondo Nacional de la Vivienda para los Trabajadores, y las participaciones en las utilidades de la empresa;'
            },
            { c1: 'LSS-27-P1-F5',
              c2: 'ALIMENTACION Y HABITACION, SU PROPORCIONALIDAD<br>La alimentación y la habitación cuando se entreguen en forma onerosa a los trabajadores; se entiende que son onerosas estas prestaciones cuando el trabajador pague por cada una de ellas, como mínimo, el veinte por ciento del salario mínimo general diario que rija en el Distrito Federal;'
            },
            { c1: 'LSS-27-P1-F6',
              c2: 'DESPENSAS, SU PROPORCIONALIDAD<br>Las despensas en especie o en dinero, siempre y cuando su importe no rebase el cuarenta por ciento del salario mínimo general diario vigente en el Distrito Federal;'
            },
            { c1: 'LSS-27-P1-F7',
              c2: 'PREMIOS POR ASISTENCIA Y PUNTUALIDAD, SU PROPORCIONALIDAD<br>Los premios por asistencia y puntualidad, siempre que el importe de cada uno de estos conceptos no rebase el diez por ciento del salario base de cotización;'
            },
            { c1: 'LSS-27-P1-F8',
              c2: 'APORTACIONES PARA PLANES DE PENSIONES, SUS CONDICIONANTES<br>Las cantidades aportadas para fines sociales, considerándose como tales las entregadas para constituir fondos de algún plan de pensiones establecido por el patrón o derivado de contratación colectiva. Los planes de pensiones serán sólo los que reúnan los requisitos que establezca la Comisión Nacional del Sistema de Ahorro para el Retiro, y'
            },
            { c1: 'LSS-27-P1-F9',
              c2: 'TIEMPO EXTRAORDINARIO, SUS LIMITANTES<br>El tiempo extraordinario dentro de los márgenes señalados en la Ley Federal del Trabajo .'
            },
            { c1: 'LSS-27-P2',
              c2: 'REGISTRO CONTABLE DE CONCEPTOS<br>Para que los conceptos mencionados en este precepto se excluyan como integrantes del salario base de cotización, deberán estar debidamente registrados en la contabilidad del patrón.'
            },
            { c1: 'LSS-27-P3',
              c2: 'INTEGRACION DE LOS EXCEDENTES<br>En los conceptos previstos en las fracciones VI, VII y IX cuando el importe de estas prestaciones rebase el porcentaje establecido, solamente se integrarán los excedentes al salario base de cotización.'
            },
            
          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LSS-28',
              c2: '28. Límites del salario base de cotización<br>Los asegurados se inscribirán con el salario base de cotización que perciban en el momento de su afiliación, estableciéndose como límite superior el equivalente a veinticinco veces el salario mínimo general que rija en el Distrito Federal y como límite inferior el salario mínimo general del área geográfica respectiva.'
            },
           
          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LSS-30-P1',
              c2: 'Para determinar el salario diario base de cotización se estará a lo siguiente:'
            },
            { c1: 'LSS-30-P1-F1',
              c2: 'SALARIO FIJO<br>Cuando además de los elementos fijos del salario el trabajador percibiera regularmente otras retribuciones periódicas de cuantía previamente conocida, éstas se sumarán a dichos elementos fijos;'
            },
            { c1: 'LSS-30-P1-F2',
              c2: 'SALARIO VARIABLE<br>Si por la naturaleza del trabajo, el salario se integra con elementos variables que no puedan ser previamente conocidos, se sumarán los ingresos totales percibidos durante los dos meses inmediatos anteriores y se dividirán entre el número de días de salario devengado en ese periodo. Si se trata de un trabajador de nuevo ingreso, se tomará el salario probable que le corresponda en dicho periodo, y'
            },

          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LFT-68-P1',
              c2: 'Prolongación del tiempo extraordinario mayor a nueve horas a la semana<br>Los trabajadores no están obligados a prestar sus servicios por un tiempo mayor del permitido en este capítulo.'
            },
            { c1: 'LFT-68-P2',
              c2: 'La prolongación del tiempo extraordinario que exceda de nueve horas a la semana, obliga al patrón a pagar al trabajador el tiempo excedente con un doscientos por ciento más del salario que corresponda a las horas de la jornada, sin perjuicio de las sanciones establecidas en esta Ley.'
            },

          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LFT-84',
              c2: 'Integración del salario fijo y variable<br>El salario se integra con los pagos hechos en efectivo por cuota diaria, gratificaciones, percepciones, habitación, primas, comisiones, prestaciones en especie y cualquiera otra cantidad o prestación que se entregue al trabajador por su trabajo.'
            },
          ]

        },
        { title1: '', title2: '',
          content: [
            { c1: ' ACDO.SA2.HCT.250117/26.P.DJ',
              c2: 'Acuerdo ACDO.SA2.HCT.250117/26.P.DJ del 25-I-2017, que el límite inferior del SBC será el salario mínimo, pues está expresamente prohibida la inscripción al Instituto por debajo de ese límite, y el límite máximo será de 25 UMAS por constituir una referencia<br>Por tanto, debe considerarse que de conformidad con los artículos 4 y 5 de la LUMA , en el DO de 8-I-2021'
            },
          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LUMA-4',
              c2: 'Metodología para calcular y determinar anualmente el valor actualizado de la UMA<br><br>El valor actualizado de la UMA se calculará y determinará anualmente por el INEGI, de conformidad con el siguiente método:<br><br>DETERMINACIÓN DEL VALOR DIARIO<br>I. El valor diario se determinará multiplicando el valor diario de la UMA del año inmediato anterior por el resultado de la suma de uno más la variación interanual del índice Nacional de Precios al Consumidor del mes de diciembre del año inmediato anterior.<br><br>DETERMINACIÓN DEL VALOR MENSUAL<br>II. El valor mensual será el producto de multiplicar el valor diario de la UMA por 30.4.<br><br>DETERMINACIÓN DEL VALOR ANUAL<br>III. El valor anual será el producto de multiplicar el valor mensual de la UMA por 12.'
            },
            { c1: 'LUMA-5',
              c2: 'Publicación del valor de la UMA e inicio de vigencia de éste<br>El INEGI publicará en el Diario Oficial de la Federación dentro de los primeros diez días del mes de enero de cada año el valor diario, mensual y anual en moneda nacional de la UMA y entrarán en vigor dichos valores el 1o. de febrero de dicho año.'
            },

          ]
        },
        { title1: '', title2: 'SECCIÓN CUARTA<br>DEL RÉGIMEN FINANCIERO',
          content: [
            { c1: 'LSS-25-P1',
              c2: '25. Aportaciones por valuaciones actuariales'
            },
            { c1: 'LSS-25-P2',
              c2: 'CUOTA POR PRESTACIONES MEDICAS A PENSIONADOS<br>Para cubrir las prestaciones en especie del seguro de enfermedades y maternidad de los pensionados y sus beneficiarios, en los seguros de riesgos de trabajo, invalidez y vida, así como retiro, cesantía en edad avanzada y vejez, los patrones, los trabajadores y el Estado aportarán una cuota de uno punto cinco por ciento sobre el salario base de cotización. De dicha cuota corresponderá al patrón pagar el uno punto cero cinco por ciento, a los trabajadores el cero punto trescientos setenta y cinco por ciento y al Estado el cero punto cero setenta y cinco por ciento.'
            },
           
          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LSS-71',
              c2: 'Base para el pago de las cuotas por riesgo<br>Las cuotas que por el seguro de riesgos de trabajo deban pagar los patrones, se determinarán en relación con la cuantía del salario base de cotización, y con los riesgos inherentes a la actividad de la negociación de que se trate, en los términos que establezca el reglamento relativo.'
            },
            { c1: 'LSS-72',
              c2: 'Fórmula de siniestralidad<br>Para los efectos de la fijación de primas a cubrir por el seguro de riesgos de trabajo, las empresas deberán calcular sus primas, multiplicando la siniestralidad de la empresa por un factor de prima, y al producto se le sumará el 0.005. El resultado será la prima a aplicar sobre los salarios de cotización, conforme a la fórmula siguiente:<br><br>Prima = [(S/365)+V * (I + D)] * (F/N) + M<br><br>Donde:<br><br>V = 28 años, que es la duración promedio de vida activa de un individuo que no haya sido víctima de un accidente mortal o de incapacidad permanente total.<br><br>F = 2.3, que es el factor de prima.<br><br>N = Número de trabajadores promedio expuestos al riesgo.<br><br>S = Total de los días subsidiados a causa de incapacidad temporal.<br><br>I = Suma de los porcentajes de las incapacidades permanentes, parciales y totales, divididos entre 100.<br><br>D = Número de defunciones.<br><br>M = 0.005, que es la prima mínima de riesgo.'
            },
            { c1: 'LSS-72-P2',
              c2: 'PAGO DE PRIMA MEDIA<br>Al inscribirse por primera vez en el Instituto o al cambiar de actividad, las empresas cubrirán, en la clase que les corresponda conforme al reglamento, la prima media. Una vez ubicada la empresa en la prima a pagar, los siguientes aumentos o disminuciones de la misma se harán conforme al párrafo primero de este artículo.'
            },
            { c1: 'LSS-72-P3',
              c2: 'IMPACTO DE LOS ACCIDENTES EN TRAYECTO<br>No se tomarán en cuenta para la siniestralidad de las empresas, los accidentes que ocurran a los trabajadores al trasladarse de su domicilio al centro de labores o viceversa.'
            },
            { c1: 'LSS-72-P4',
              c2: 'FACTOR DE PRIMA PARA PATRONES CON UN SISTEMA DE ADMINISTRACION Y SEGURIDAD EN EL TRABAJO<br>Los patrones cuyos centros de trabajo cuenten con un sistema de administración y seguridad en el trabajo acreditado por la Secretaría del Trabajo y Previsión Social, aplicarán una F de 2.2 como factor de prima.'
            },
            { c1: 'LSS-72-P5',
              c2: 'DETERMINACION DE PRIMA DE PATRONES CON MENOS DE DIEZ TRABAJADORES<br>Las empresas de menos de 10 trabajadores, podrán optar por presentar la declaración anual correspondiente o cubrir la prima media que les corresponda conforme al reglamento, de acuerdo al artículo 73 de esta Ley.'
            },

          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LSS-73-P1',
              c2: '73. Prima por inscripción inicial<br>Al inscribirse por primera vez en el Instituto o al cambiar de actividad, las empresas cubrirán la prima media de la clase que conforme al Reglamento les corresponda, de acuerdo a la tabla siguiente:'
            },
            { c1: 'LSS-73-P2',
              c2: 'CAMBIO DE ACTIVIDAD POR SENTENCIA O DISPOSICION DE LEY<br>Se aplicará igualmente lo dispuesto por este artículo cuando el cambio de actividad de la empresa se origine por una sentencia definitiva o por disposición de esta Ley o de un reglamento.'
            },

          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LSS-74-P1',
              c2: 'Revisión anual de la siniestralidad<br>Las empresas tendrán la obligación de revisar anualmente su siniestralidad, conforme al periodo y dentro del plazo que señale el reglamento, para determinar si permanecen en la misma prima, se disminuye o aumenta.'
            },
            { c1: 'LSS-74-P2',
              c2: 'MODIFICACION DE LA PRIMA<br>La prima conforme a la cual estén cubriendo sus cuotas las empresas podrá ser modificada, aumentándola o disminuyéndola en una proporción no mayor al uno por ciento con respecto a la del año inmediato anterior, tomando en consideración los riesgos de trabajo terminados durante el lapso que fije el reglamento respectivo, con independencia de la fecha en que éstos hubieran ocurrido y la comprobación documental del establecimiento de programas o acciones preventivas de accidentes y enfermedades de trabajo. Estas modificaciones no podrán exceder los límites fijados para la prima mínima y máxima, que serán de cero punto cinco por ciento y quince por ciento de los salarios base de cotización respectivamente.'
            },
            { c1: 'LSS-74-P3',
              c2: 'La siniestralidad se fijará conforme al reglamento de la materia.'
            },

          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LSS-75',
              c2: 'Determinación de las clases<br>La determinación de las clases comprenderá una lista de los diversos tipos de actividades y ramas industriales, catalogándolas en razón de la mayor o menor peligrosidad a que están expuestos los trabajadores, y asignando a cada uno de los grupos que formen dicha lista, una clase determinada. Este supuesto sólo se aplicará a las empresas que se inscriben por primera vez en el Instituto o cambien de actividad.'
            },

          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LSS-76-P1',
              c2: '76. Revisión de la fórmula para el cálculo de la prima<br>El Consejo Técnico del Instituto promoverá ante las instancias competentes y éstas ante el H. Congreso de la Unión, cada tres años, la revisión de la fórmula para el cálculo de la prima, para asegurar que se mantenga o restituya en su caso, el equilibrio financiero de este seguro, tomando en cuenta a todas las empresas del país. Para tal efecto se considerará la opinión que al respecto sustente el Comité Consultivo del Seguro de Riesgos de Trabajo, el cual estará integrado de manera tripartita.'
            },
            { c1: 'LSS-76-P2',
              c2: 'Si la Asamblea General lo autorizare, el Consejo Técnico podrá promover la revisión a que alude este artículo en cualquier tiempo, tomando en cuenta la experiencia adquirida.'
            },

          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LSS-106-P1',
              c2: 'Financiamiento de prestaciones en especie en E y M<br>Las prestaciones en especie del seguro de enfermedades y maternidad, se financiarán en la forma siguiente:'
            },
            { c1: 'LSS-106-P1-F1',
              c2: 'CUOTA PATRONAL POR SALARIO MINIMO<br>Por cada asegurado se pagará mensualmente una cuota diaria patronal equivalente al trece punto nueve por ciento de un salario mínimo general diario para el Distrito Federal;'
            },
            { c1: 'LSS-106-P1-F2',
              c2: 'CUOTA ADICIONAL A TRES SALARIOS MINIMOS<br>Para los asegurados cuyo salario base de cotización sea mayor a tres veces el salario mínimo general diario para el Distrito Federal; se cubrirá además de la cuota establecida en la fracción anterior, una cuota adicional patronal equivalente al seis por ciento y otra adicional obrera del dos por ciento, de la cantidad que resulte de la diferencia entre el salario base de cotización y tres veces el salario mínimo citado, y'
            },
            { c1: 'LSS-106-P1-F3',
              c2: '"CUOTA ACTUALIZADA DEL ESTADO<br>El Gobierno Federal cubrirá mensualmente una cuota diaria por cada asegurado, equivalente a trece punto nueve por ciento de un salario mínimo general para el Distrito Federal, a la fecha de entrada en vigor de esta Ley, la cantidad inicial que resulte se actualizará trimestralmente de acuerdo a la variación del Indice Nacional de Precios al Consumidor.'
            },
          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LSS-T19-P1',
              c2: 'Tasas temporales aplicables a las cuotas de E y M<br>La tasa sobre el salario mínimo general diario del Distrito Federal (1) a que se refiere la fracción I del artículo 106, se incrementará el primero de julio de cada año en sesenta y cinco centésimas de punto porcentual. Estas modificaciones comenzarán en el año de 1998 y terminarán en el año 2007.'
            },
            { c1: 'LSS-T19-P2',
              c2: 'Las tasas a que se refiere la fracción II del artículo 106, se reducirán el primero de julio de cada año en cuarenta y nueve centésimas de punto porcentual la que corresponde a los patrones y en dieciséis centésimas de punto porcentual la que corresponde pagar a los Trabajadores. Estas modificaciones comenzarán en el año de 1998 y terminarán en el año 2007.'
            },

          ]
        },
        { title1: '', title2: 'ARTÍCULOS TRANSITORIOS<br>ARTÍCULOS TRANSITORIOS - DE LA LEY DEL SEGURO SOCIAL PUBLICADA EN EL DIARIO OFICIAL DE LA FEDERACIÓN DE 21 DE DICIEMBRE DE 1995',
          content: [
            { c1: 'LSS-107-P1',
              c2: 'Financiamiento de prestaciones en dinero en E y M<br>Las prestaciones en dinero del seguro de enfermedades y maternidad se financiarán con una cuota del uno por ciento sobre el salario base de cotización, que se pagará de la forma siguiente:'
            },
            { c1: 'LSS-107-P1-F1',
              c2: 'PARTE PATRONAL<br>A los patrones les corresponderá pagar el setenta por ciento de dicha cuota;'
            },
            { c1: 'LSS-107-P1-F2',
              c2: 'PARTE DE LOS TRABAJADORES<br>A los trabajadores les corresponderá pagar el veinticinco por ciento de la misma, y'
            },
            { c1: 'LSS-107-P1-F3',
              c2: 'PARTE DEL GOBIERNO FEDERAL<br>Al Gobierno Federal le corresponderá pagar el cinco por ciento restante.'
            },
          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LSS-108',
              c2: 'Aportaciones del gobierno federal<br>Las aportaciones del Gobierno Federal serán cubiertas en pagos mensuales iguales, equivalentes a la doceava parte de la estimación que presente el Instituto para el año siguiente a la Secretaría de Hacienda y Crédito Público, en el mes de julio de cada ejercicio. En el caso de que en un cuatrimestre la inflación sea cuatro puntos porcentuales mayor o menor a la prevista en dichos cálculos, se harán las compensaciones preliminares correspondientes antes de que termine el siguiente bimestre, realizándose los ajustes definitivos, en base a la inflación real anual, durante el mes de enero del año siguiente.'
            },

          ]
        },
        { title1: '', title2: 'ARTÍCULOS TRANSITORIOS<br>ARTÍCULOS TRANSITORIOS - DE LA LEY DEL SEGURO SOCIAL PUBLICADA EN EL DIARIO OFICIAL DE LA FEDERACIÓN DE 21 DE DICIEMBRE DE 1995',
          content: [
            { c1: 'LSS-T19-P1',
              c2: 'Tasas temporales aplicables a las cuotas de E y M<br>La tasa sobre el salario mínimo general diario del Distrito Federal (1) a que se refiere la fracción I del artículo 106, se incrementará el primero de julio de cada año en sesenta y cinco centésimas de punto porcentual. Estas modificaciones comenzarán en el año de 1998 y terminarán en el año 2007.'
            },
            { c1: 'LSS-T19-P2',
              c2: 'Las tasas a que se refiere la fracción II del artículo 106, se reducirán el primero de julio de cada año en cuarenta y nueve centésimas de punto porcentual la que corresponde a los patrones y en dieciséis centésimas de punto porcentual la que corresponde pagar a los Trabajadores. Estas modificaciones comenzarán en el año de 1998 y terminarán en el año 2007.'
            },

          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LSS-147',
              c2: 'Porcentaje para patrones y trabajadores<br>A los patrones y a los trabajadores les corresponde cubrir, para el seguro de invalidez y vida el uno punto setenta y cinco por ciento y el cero punto seiscientos veinticinco por ciento sobre el salario base de cotización, respectivamente.'
            },
   
          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LSS-168-P1',
              c2: '168. Cuantía de las cuotas de retiro, cesantía y vejez<br>Las cuotas y aportaciones a que se refiere el artículo anterior serán:'
            },
            { c1: 'LSS-168-P1-F1',
              c2: 'RETIRO<br>En el ramo de retiro, a los patrones les corresponde cubrir el importe equivalente al dos por ciento del salario base de cotización del trabajador.'
            },
            { c1: 'LSS-168-P1-F2',
              c2: 'CESANTIA Y VEJEZ<br>En los ramos de cesantía en edad avanzada y vejez:'
            },
            { c1: 'LSS-168-P1-F2-Inc. a)',
              c2: 'CUOTA PATRONAL<br>a) Los patrones cubrirán la cuota que corresponda sobre el salario base de cotización, calculada conforme a la siguiente tabla: …'
            },
            { c1: 'LSS-168-P1-F2-Inc. b)',
              c2: 'CUOTA OBRERA<br>b) Los trabajadores cubrirán una cuota del uno punto ciento veinticinco por ciento sobre el salario base de cotización.'
            },
          ]
        },
        { title1: '', title2: 'TRANSITORIOS - DEL DECRETO POR EL QUE SE REFORMAN, ADICIONAN Y DEROGAN DIVERSAS DISPOSICIONES DE LA LEY DEL SEGURO SOCIAL Y DE LA LEY DE LOS SISTEMAS DE AHORRO PARA EL RETIRO, PUBLICADO EN EL DIARIO OFICIAL DE LA FEDERACIÓN DE 16 DE DICIEMBRE DE 2020',
          content: [
            { c1: 'LSS-T1',
              c2: '1. Inicio de la vigencia<br>El presente Decreto entrará en vigor el 1 de enero de 2021, salvo lo dispuesto en los transitorios siguientes.'
            },
            { c1: 'LSS-T2',
              c2: '2. Incremento gradual de la cuota patronal del ramo de cesantía en edad avanzada y vejez…'
            },
            { c1: 'LSS-T2-P2',
              c2: 'CUOTAS DE FINANCIAMIENTO DEL RAMO DE CV PARA 2021 Y 2022<br>Del 1 de enero de 2021 al 31 de diciembre de 2022:'
            },
            { c1: 'LSS-T2-P2-F1',
              c2: 'CUOTA PATRONAL Y OBRERA<br>Los patrones seguirán cubriendo, para los ramos de cesantía en edad avanzada y vejez, una cuota del tres punto ciento cincuenta por ciento sobre el salario base de cotización del trabajador.'
            },
          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LSS-211',
              c2: 'Monto de la prima<br>El monto de la prima para este seguro será del uno por ciento sobre el salario base de cotización. Para prestaciones sociales solamente se podrá destinar hasta el veinte por ciento de dicho monto.'
            },

          ]
        },
        { title1: '', title2: '',
          content: [
            { c1: 'LINFONAVIT-29-P1',
              c2: 'Obligaciones patronales<br>Son obligaciones de los patrones:'
            },
            { c1: 'LINFONAVIT-29-P1-F2',
              c2: 'DETERMINACION Y ENTERO DE APORTACIONES<br>Determinar el monto de las aportaciones del cinco por ciento sobre el salario de los trabajadores a su servicio y efectuar el pago en las entidades receptoras que actúen por cuenta y orden del Instituto, para su abono en la subcuenta de vivienda de las cuentas individuales de los trabajadores previstas en los sistemas de ahorro para el retiro, en los términos de la presente Ley y sus reglamentos, así como en lo conducente, conforme a lo previsto en la Ley del Seguro Social y en la Ley Federal del Trabajo. En lo que corresponde a la integración y cálculo de la base y límite superior salarial para el pago de aportaciones, se aplicará lo contenido en la Ley del Seguro Social.'
            },

          ]
        },
        
       

      ],
    }
  },

  watch: {
  	'$parent.showFLegalesComp': function(newVal, oldVal) {
      if(newVal == true){
        this.$refs['modal-flegales'].show();
      }
    }
  }
}
</script>
