require('./bootstrap');

import Vue from 'vue';
//Rutas del website
import Router from './router.js';

//Librerias globales
import Library from './libs.js';
Vue.use(Library);

//Componentes del website
import components from './components/components.js';
Vue.use(components);

window.Vue=Vue;

//Instancia principal
const app = new Vue({
    el: '#app',
    router:Router,
    data:{
        logged:null,
        user:{

        },
    },
     methods:{
        auth:function(){
            axios.get(tools.url("/api/user")).then((response)=>{
                this.user = response.data;
                this.logged = true;
                //window.open('https://notaria42.online/#/inicio');
            }).catch(()=>{
                //no login
            });
        },
    },
    mounted(){
        this.auth();
    }

});
