<template lang="html">
  <div id="catalog-form-page">

    <section class="container oversized-container main-section">
      <div class="row pt-4">
        <div class="col col-lg-3 pl-sm-0 col-link">
          <a class="link-back" href="javascript:history.back();" ><i class="fal fa-chevron-left icon"></i> <span>Regresar</span></a>
        </div>

        <div class="col-lg col-titles">
          <h1>ISR por pago de PTU</h1>
          <hr />
        </div>

        <div class="col col-lg-3 col-empty"></div>
      </div>

      <div class="row justify-content-center">
        <!-- Paso 1, Form -->
        <div class="col-12 col-mid col-form" v-if="step == 1">
          <h5 class="mb-4 title">DATOS GENERALES</h5>

          <b-form @submit="onSubmit">

          	<b-form-group class="custom-form-group-s2"
              label="Año del cálculo: "
              label-for="i-anio">
              	<div class="row">
                	<div class="col-12 col-sm">
                  		<b-form-select v-model="form.anio" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
		                    <b-form-select-option :value="null" disabled selected>Año</b-form-select-option>
		                    <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>

		                </b-form-select>
                	</div>
             	</div>
            </b-form-group>



            <b-form-group class="custom-form-group-s2"
              label="PTU a pagar:"
              label-for="i-vent" id="input-ptu_pagar">
              <currency-input id="i-vent" class="form-control"
                v-model="form.ptu_pagar" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.ptu_pagar != null" style="color: red;"><small>{{validation.ptu_pagar}}</small></div>
            </b-form-group>
            <b-form-group class="custom-form-group-s2"
              label="Salario mensual ordinario:"
              label-for="i-vent" id="input-salario_mensual_ordinario">
              <currency-input id="i-vent" class="form-control"
                v-model="form.salario_mensual_ordinario" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.salario_mensual_ordinario != null" style="color: red;"><small>{{validation.salario_mensual_ordinario}}</small></div>
            </b-form-group>


            <p class="mt-3 mt-lg-5 pt-sm-4 text-center">
              <b-button type="submit" class="_btn-st1 btn-blue" id="btn_calc">Calcular</b-button>
            </p>
          </b-form>
        </div>
        <!-- Paso 1, FIN -->

        <!-- Paso 2, Mostrar datos -->
        <div class="col-12 col-mid col-results" v-if="step == 2">
          <!-- <h5 class="title">DATOS GENERALES</h5> -->
          <div class="row">
            <!-- <div class="col-12 col-line">
              <hr />
            </div> -->

            <div class="col-12 col-subbox">
              <div class="row align-items-center">
                <div class="col col-text">
                  <h2 class="trans-250 title with-link" @click="scrolltoTable">CONSULTAR FUNDAMENTOS LEGALES <i class="fas fa-info-circle icon"></i></h2>
                </div>

                <!-- <div class="col col-share">
                  <a class="trans-150 btn-share" v-b-modal.modal-compartir>Compartir <i class="fas fa-share-alt"></i></a>
                </div> -->
              </div>
            </div>

            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                  Ingresos brutos totales:
                </div>
                <div class="col col-med">
                  {{detailInfo.ingresos_brutos_totales}}
                </div>
              </div>
            </div>
            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                  Ingresos gravados totales:
                </div>
                <div class="col col-med">
                  {{detailInfo.ingresos_gravados_totales}}
                </div>
              </div>
            </div>
            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                  PTU gravada:
                </div>
                <div class="col col-med">
                  {{detailInfo.ptu_gravada}}
                </div>
              </div>
            </div>
            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                  PTU exenta:
                </div>
                <div class="col col-med">
                  {{detailInfo.ptu_exenta}}
                </div>
              </div>
            </div>

            <p style="text-align:center;"><b>ISR conforme la mecánica del artículo 96 de la LISR</b></p>
            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                  ISR a retener por el pago de la PTU:
                </div>
                <div class="col col-med">
                  {{detailInfo.isr_al_pago_aguinaldo}}
                </div>
              </div>
            </div>
            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                  ISR por el ingreso ordinario subsidiado:
                </div>
                <div class="col col-med">
                  {{detailInfo.isr_ingreso_ordinario_subisidiado}}
                </div>
              </div>
            </div>
            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                  Total de ISR a retener en el mes (o subsidio al empleo):
                </div>
                <div class="col col-med">
                  {{detailInfo.total_isri_retener_mes}}
                </div>
              </div>
            </div>
            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                  Ingreso neto a recibir conforme al artículo 96 de la LISR:
                </div>
                <div class="col col-med">
                  {{detailInfo.ingreso_neto_recibir_96}}
                </div>
              </div>
            </div>

            <p style="text-align:center;"><b>ISR conforme a la mecánica del artículo 174 del RISR</b></p>
            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                  ISR a retener por el pago de la PTU:
                </div>
                <div class="col col-med">
                  {{detailInfo.isr_retener_pago_ptu}}
                </div>
              </div>
            </div>
            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                  ISR por el ingreso ordinario subsidiado:
                </div>
                <div class="col col-med">
                  {{detailInfo.impuesto_aretener}}
                </div>
              </div>
            </div>
            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                  Total de ISR a retener en el mes (o subsidio al empleo):
                </div>
                <div class="col col-med">
                  {{detailInfo.total_isri_retener_mes_subsidioalempleo}}
                </div>
              </div>
            </div>
            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                  Ingreso neto a recibir conforme al artículo 174 del RISR:
                </div>
                <div class="col col-med">
                  {{detailInfo.ingreso_neto_recibir_174}}
                </div>
              </div>
            </div>
            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                  Tasa efectiva de retención según el artículo 174, Fracc. IV y V del RISR:
                </div>
                <div class="col col-med">
                  {{detailInfo.tasa_efectiva_retencion}}
                </div>
              </div>
            </div>

            <div class="col-12 col-options">
              <!-- <p class="mb-3">
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.excel" target="_blank">Exportar a Excel</a>
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.pdf" target="_blank">Exportar a PDF</a>
              </p> -->
              <p>
                <b-button type="submit" class="_btn-st1 btn-blue" @click="otherCalc">Hacer otro cálculo</b-button>
              </p>
            </div>

          </div>
        </div>

        <div class="col-12 ml-lg-4 col-actions" v-if="step == 2">
          <div class="box">
            <h5 class="mb-2 a-title-1">ACCIONES</h5>

           <div class="box-btns">
              <a class="btn-action" :href="detailInfo.links.excel" target="_blank"><i class="icon i-xls"></i> <span>Exportar a Excel</span></a>
              <a class="btn-action" :href="detailInfo.links.pdf" target="_blank"><i class="icon i-pdf"></i> Exportar a PDF</a>
              <!-- <a class="btn-action" href="#"><i class="icon i-share"></i> Compartir</a> -->
              <button class="btn-action" @click="$refs['modal-personalize'].show()"><i class="icon i-edt"></i> Personalizar</button>
            </div>

            <!-- <div class="box-line">
              <hr />
            </div>

            <div class="box-content">
              <b-card class="card-s1">
                <a v-b-toggle.collapse-1-inner class="a-title-1">FUNDAMENTOS LEGALES</a>
                <b-collapse id="collapse-1-inner" class="collapse-content">
                  <b-card>
                    <p>Proximamente...</p>
                  </b-card>
                </b-collapse>
              </b-card>
            </div> -->

          </div>
        </div>
        <!-- Paso 2, FIN -->

        <!-- Fundamentos legales -->
        <flegales-component id="table-flegales"></flegales-component>
        <!--  -->

      </div>
    </section>

    <!-- ******** Modals ******** -->
    <!-- Modal "Compartir a Correo" -->

    <!--  -->
    <b-modal id="modal-loading" ref="modal-loading" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc modal-class="modal-st-1">
        <div style="text-align: center;">
          <img class="img-fluid" src="public/images/shared/logo.svg" style="width: 230px">
          <br><br><h3>Realizando cálculo</h3>
        </div>
    </b-modal>

    <b-modal id="modal-personalize" ref="modal-personalize" hide-footer hide-header centered  modal-class="modal-st-1">
        <b-form @submit="onSubmitPersonalize">
            <b-form-group class="col-lg-6 custom-group-s1" label="Logo" >
                    <div class="col-sm-9">
                        <div class="fileinput fileinput-new" data-provides="fileinput">
                          <div class="fileinput-preview fileinput-exists thumbnail" >
                          </div>
                          <div>
                            <span class="btn btn-white btn-file">
                              <input type="file" accept=".pdf,image/*" name="logo">
                            </span>
                          </div>
                        </div>
                    </div>
              </b-form-group>
              <div class="box-footer">
                <button  type="button" class="btn _btn-st1" @click="$refs['modal-personalize'].hide()">Cancelar</button>
                <b-button style="text-align: center;" type="submit" class="_btn-st1 btn-blue">Personalizar</b-button>
               
              </div>
               <div class="box-footer" style="text-align: center;">
                 <a style="text-align: center;" v-if="pesonalizeUrl" class="btn _btn-st1 btn-blue" :href="pesonalizeUrl" target="_blank"><i class="fas fa-download"></i> Descargar</a>
               </div>

        </b-form>
    </b-modal>

  </div>
</template>

<script>
import FlegalesComponent from './components/fleg-comp-isr-por-pago-de-ptu.vue';
export default {
  data(){
    return{
      step: 1,
      // showModalFLegales: false,
      showFLegalesComp: false,

      form:{
        anio:null,
        salario_mensual_ordinario:null,
		ptu_pagar:null

      },

      formComp: {
        email: null
      },

      years:[],
      months:[
        {value:1,name:'Enero'},
        {value:2,name:'Febrero'},
        {value:3,name:'Marzo'},
        {value:4,name:'Abril'},
        {value:5,name:'Mayo'},
        {value:6,name:'Junio'},
        {value:7,name:'Julio'},
        {value:8,name:'Agosto'},
        {value:9,name:'Septiembre'},
        {value:10,name:'Octubre'},
        {value:11,name:'Noviembre'},
        {value:12,name:'Diciembre'},
      ],
      daysone:[],
      daystwo:[],

      detailInfo:[],
      validation:{
        anio:null,
        salario_mensual_ordinario:null,
		    ptu_pagar:null
      },
      pesonalizeUrl:null,
      rawImg:null

    }
  },
  watch:{
    'form.mes1':function(val)
    {
      var fecha = new Date();
      if (this.form.anio1 == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = this.form.anio1;
      }
      var d = new Date(anio, val, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daysone = days;
    },
    'form.anio1':function(val)
    {
      var fecha = new Date();
      if (val == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = val;
      }
      var d = new Date(anio, this.form.mes1, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daysone = days;
    },
    'form.mes2':function(val)
    {
      var fecha = new Date();
      if (this.form.anio2 == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio = this.form.anio2;
      }
      var d = new Date(anio, val, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daystwo = days;
    },
    'form.anio2':function(val)
    {
      var fecha = new Date();
      if (val == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = val;
      }
      var d = new Date(anio, this.form.mes2, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daystwo = days;
    },
  },
  methods: {
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    onSubmit(event) { // Enviar formulario del paso 1
      event.preventDefault();

		  /*this.validation.salario_diario = null;




      if (this.form.salario_diario == 0 || this.form.salario_diario == null) {
          this.validation.salario_diario = '"Salario diari" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-salario_diario").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      if (this.form.ultimo_sueldo == 0 || this.form.ultimo_sueldo == null) {
          this.validation.ultimo_sueldo = '"Importe del último sueldo mensual ordinario" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-ultimo_sueldo").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }*/

      this.$refs['modal-loading'].show();
      //$('#btn_calc').hide();

      axios.post(tools.url("/calculateSeventeen"),this.form).then((response)=>{
          this.detailInfo = response.data;
          this.$refs['modal-loading'].hide();
          this.step = 2;
      }).catch((error)=>{
          $('#btn_calc').show();
          this.$refs['modal-loading'].hide();
          alert('El calculo no puede ser realizado con la información ingresada, porfavor modifica los parámetros e intenta de nuevo.');
      });
    },
    otherCalc(){
      location.reload();
    },

    scrolltoTable(){
      var tablePosY = document.getElementById("table-flegales").offsetTop;
      this.showFLegalesComp = true;

      setTimeout( ()=>{
        window.scrollTo({ top: tablePosY, left: 0, behavior: 'smooth' });
      }, 200);
    },

    onSubmitComp(event) { // Enviar formulario compartir del Modal "Compartir a Correo"
      event.preventDefault();
      this.$refs['modal-compartir'].hide();

      console.log("Compartir cálculo");
    },

    onSubmitCalc(event) { // Guardar cálculo y regresar a paso 1
      event.preventDefault();

      var keys = Object.keys(this.form);
      var resetForm = {};
      keys.forEach(key => resetForm[key] = null);
      this.form = resetForm;

      this.step = 1;
      this.$refs['modal-gcalculo'].hide();

      console.log("Hacer otro cálculo");
    },
    onSubmitPersonalize(event) { 
      event.preventDefault();
        this.pesonalizeUrl = null;
        const file =  jQuery('input[name="logo"]')[0].files[0];
        const reader = new FileReader()
        var self = this;
        reader.onloadend = () => {
           self.rawImg = reader.result;
           self.onSubmitPersonalizeData();
        }
        reader.readAsDataURL(file);

    },
    onSubmitPersonalizeData(){
         //enviamos el resultado y el logo
        this.$refs['modal-loading'].show();

        var formData = new FormData();
        formData.append("logo", this.rawImg);
        formData.append("formdata", JSON.stringify(this.detailInfo));

        axios.post(tools.url("/calculatePesonalize/17"),formData).then((response)=>{
            this.pesonalizeUrl = response.data.link;
            this.$refs['modal-loading'].hide();
            
        }).catch((error)=>{
            this.$refs['modal-loading'].hide();
            alert('Ocurrio un error al generar pdf');
        });
    }
  },

  mounted(){
    var fecha = new Date();
    var anio = fecha.getFullYear();
    var years = [];
    for (var i = 2016; i <= anio; i++) {
        var aux = {
          'value':i
        };
        years.push(aux);
    }

    years.reverse();
    this.years = years;
  },

  components: {
    FlegalesComponent
  },
}
</script>
