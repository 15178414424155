<template lang="html">
	<footer id="footer" v-if="$route.name != 'accessPage'">
		<div class="container oversized-container">
			<div class="row align-items-center">
				<div class="col-lg-4 col-footer">
					<p>Última actualización: 12/02/2022</p>
					<p>V. 1.0.4</p>
				</div>

				<div class="col-lg-4 col-footer col-logo">
					<a class="logo-link" href="https://contadores.tirantonline.com.mx/base/conmex">
						<img class="img-fluid" src="public/images/shared/logo-white.svg">
					</a>
				</div>

				<div class="col-lg-4 col-footer">
					<!-- <p>
						<router-link to="/terminos-y-condiciones">Términos y condiciones</router-link><br />
						<router-link to="/aviso-de-privacidad">Aviso de privacidad</router-link>
					</p> -->
					<p class="mt-2 copyright">Todos los derechos reservados</p>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
}
</script>
