<template lang="html">
  <div id="catalog-form-page">

    <section class="container oversized-container main-section">
      <div class="row pt-4">
        <div class="col col-lg-3 pl-sm-0 col-link">
          <a class="link-back" href="javascript:history.back();" ><i class="fal fa-chevron-left icon"></i> <span>Regresar</span></a>
        </div>

        <div class="col-lg col-titles">
          <h1>Determinación de las cuotas obrero patronales mensuales</h1>
          <hr />
        </div>

        <div class="col col-lg-3 col-empty"></div>
      </div>

      <div class="row justify-content-center">
        <!-- Paso 1, Form -->
        <div class="col-12 col-mid col-form" v-if="step == 1">
          <h5 class="mb-4 title">DATOS GENERALES</h5>

          <b-form @submit="onSubmit">

          	<b-form-group class="custom-form-group-s2"
              label="Año del cálculo: "
              label-for="i-anio">
              	<div class="row">
                	<div class="col-12 col-sm">
                  		<b-form-select v-model="form.anio" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
		                    <b-form-select-option :value="null" disabled selected>Año</b-form-select-option>
		                    <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>

		                </b-form-select>
                	</div>
             	</div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="¿Zona geográfica del trabajador?: "
              label-for="i-zona_geografica">
                <div class="row">
                  <div class="col-12 col-sm">
                      <b-form-select v-model="form.zona_geografica" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                        <b-form-select-option :value="null" disabled selected>Selecciona una opción</b-form-select-option>
                        <b-form-select-option value="rp" >RP</b-form-select-option>
                        <b-form-select-option value="zlfn" >ZLFN</b-form-select-option>
                    </b-form-select>
                  </div>
              </div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="¿Días del mes?: "
              label-for="i-dias_mes">
                <div class="row">
                  <div class="col-12 col-sm">
                      <b-form-select v-model="form.dias_mes" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                        <b-form-select-option :value="null" disabled selected>Selecciona una opción</b-form-select-option>

                        <b-form-select-option value="61" >61</b-form-select-option>
                        <b-form-select-option value="60" >60</b-form-select-option>
                        <b-form-select-option value="59" >59</b-form-select-option>

                        <b-form-select-option value="28" v-if="aniobisiesto == false" >28</b-form-select-option>
                        <b-form-select-option value="29" v-if="aniobisiesto == true">29</b-form-select-option>

                        <b-form-select-option value="30" >30</b-form-select-option>
                        <b-form-select-option value="30.40" >30.40</b-form-select-option>
                        <b-form-select-option value="30.41666" >30.41666</b-form-select-option>
                        <b-form-select-option value="31" >31</b-form-select-option>

                    </b-form-select>
                  </div>
              </div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Período de la retención: "
              label-for="i-periodo_retencion">
                <div class="row">
                  <div class="col-12 col-sm">
                      <b-form-select v-model="form.periodo_retencion" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                        <b-form-select-option :value="null" disabled selected>Selecciona una opción</b-form-select-option>
                        <b-form-select-option value="7">Semanal</b-form-select-option>
                        <b-form-select-option value="10">Decenal</b-form-select-option>
                        <b-form-select-option value="15">Quincenal</b-form-select-option>
                        <b-form-select-option value="30">Mensual</b-form-select-option>

                        <b-form-select-option value="61">61 Días</b-form-select-option>
                        <b-form-select-option value="60">60 Días</b-form-select-option>
                        <b-form-select-option value="59">59 Días</b-form-select-option>
                        <b-form-select-option value="31">31 Días</b-form-select-option>
                        <b-form-select-option value="30">30 Días</b-form-select-option>
                        <b-form-select-option value="29">29 Días</b-form-select-option>
                        <b-form-select-option value="28">28 Días</b-form-select-option>
                    </b-form-select>
                  </div>
              </div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Salario bruto mensual:"
              label-for="i-vent" id="input-salario_bruto">
              <currency-input id="i-vent" class="form-control"
                v-model="form.salario_bruto" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.salario_bruto != null" style="color: red;"><small>{{validation.salario_bruto}}</small></div>
            </b-form-group>

             <b-form-group class="custom-form-group-s2"
              label="Prima de riesgo de trabajo (siniestralidad laboral):"
              label-for="i-agui" id="input-prima_riesgo" >
             <b-form-input
                id="i-co"
                v-model="form.prima_riesgo"
                type="text"
                placeholder="0.00"
                @keypress="isNumber($event)"

              ></b-form-input>
              <div v-if="validation.prima_riesgo != null" style="color: red;"><small>{{validation.prima_riesgo}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Factor de integración por las prestaciones laborales:"
              label-for="i-agui" id="input-factor_integracion" >
             <b-form-input
                id="i-co"
                v-model="form.factor_integracion"
                type="text"
                placeholder="0.00"
                @keypress="isNumber($event)"
                
              ></b-form-input>
              <div v-if="validation.factor_integracion != null" style="color: red;"><small>{{validation.factor_integracion}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Cualquier otro importe entregado al trabajador por su trabajo (ingresar el monto diario):"
              label-for="i-vent" id="input-otro_importe">
              <currency-input id="i-vent" class="form-control"
                v-model="form.otro_importe" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.otro_importe != null" style="color: red;"><small>{{validation.otro_importe}}</small></div>
            </b-form-group>
            <p class="mt-3 mt-lg-5 pt-sm-4 text-center">
              <b-button type="submit" class="_btn-st1 btn-blue" id="btn_calc">Calcular</b-button>
            </p>
          </b-form>
        </div>
        <!-- Paso 1, FIN -->

        <!-- Paso 2, Mostrar datos -->
        <div class="col-12 col-mid col-results" v-if="step == 2" style="max-width:100% !important">
          <!-- <h5 class="title">DATOS GENERALES</h5> -->
          <div class="row">
            <!-- <div class="col-12 col-line">
              <hr />
            </div> -->

            <div class="col-12 col-subbox">
              <div class="row align-items-center">
                <div class="col col-text">
                  <h2 class="trans-250 title with-link" @click="scrolltoTable">CONSULTAR FUNDAMENTOS LEGALES <i class="fas fa-info-circle icon"></i></h2>
                </div> 

                <!-- <div class="col col-share">
                  <a class="trans-150 btn-share" v-b-modal.modal-compartir>Compartir <i class="fas fa-share-alt"></i></a>
                </div> -->
              </div>
            </div>

            <div class="col-12 col-info">

                <table style="width: 800px;">
                    <thead style="font-size:10px">
                      <tr>
                        <th>Salario diario integrado</th>
                        <th>Salario diario base de cotizacion</th>
                        <th>Base de cotización para la determinación de las C.O.P.</th>
                        <th>Aportaciones al seguro social</th>
                        <th style="width: 80px;">UMA</th>
                        <th>IMSS patrón mensual</th>
                        <th>IMSS obrero mensual</th>
                        <th>Pago IMSS patronal</th>
                        <th>Año</th>
                        <th>Factor de integracion</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{{detailInfo.salario_diario_integrado}}</td>
                        <td>{{detailInfo.salario_diario_base_cotizacion}}</td>
                        <td>{{detailInfo.base_cotizacion}}</td>
                        <td>{{detailInfo.imss_obrero}}</td>
                        <td>{{detailInfo.uma}}</td>
                        <td>{{detailInfo.imss_patron}}</td>
                        <td>{{detailInfo.imss_obrero}}</td>
                        <td>{{detailInfo.pago_imss}}</td>
                        <td>{{detailInfo.anio}}</td>
                        <td>{{detailInfo.factor_integracion}}</td>
                      </tr>
                    </tbody>
                </table>
                <br>
                <p style="text-align: center;">Desglose de cuotas obrero patronales IMSS</p>
                <table style="width: 800px;">
                    <thead>
                      <tr>
                        <th rowspan="2">Seguro</th>
                        <th rowspan="2">Prestaciones</th>
                        <th colspan="3">% Patrón / Empleador</th>
                        <th colspan="2">% Trabajador / Colaborador</th>
                        <th colspan="2">Total</th>
                      </tr>
                      <tr>
                        <th>Notas</th>
                        <th style="width:80px">%</th>
                        <th style="width:80px">$</th>
                        <th style="width:80px">%</th>
                        <th style="width:80px">$</th>
                        <th style="width:80px">%</th>
                        <th style="width:80px">$</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>Riesgos de Trabajo</td>
                        <td>En especie y dinero</td>
                        <td>Conforme con su siniestralidad laboral</td>
                        <td>{{detailInfo.prima_riesgo}} %</td>
                        <td>{{detailInfo.lss_71}}</td>
                        <td>0.00 %</td>
                        <td>0.00 %</td>
                        <td>{{detailInfo.prima_riesgo}} %</td>
                        <td>{{detailInfo.lss_71}}</td>
                      </tr>
                       <tr>
                        <td rowspan="4">Enfermedades y Maternidad</td>
                        <td rowspan="2">En especie</td>
                        <td>Cuota fija por cada colaborador hasta por tres UMA</td>
                        <td>20.40 %</td>
                        <td>{{detailInfo.lss_106_f1}}</td>
                        <td>0.00 %</td>
                        <td>0.00 %</td>
                        <td>20.40 %</td>
                        <td>{{detailInfo.lss_106_f1}}</td>
                      </tr>
                      <tr>

                        <td>Cuota adicional por la diferencia del SBC y de tres veces UMA</td>
                        <td>1.10%</td>
                        <td>{{detailInfo.lss_106_f2}}</td>
                        <td>0.40 %</td>
                        <td>{{detailInfo.lss_106_f2_2}}</td>
                        <td>1.50 %</td>
                        <td>{{detailInfo.lss_106_f2_total}}</td>
                      </tr>
                      <tr>
                        <td>Gastos médicos para pensionados y beneficiarios</td>
                        <td></td>
                        <td>1.05 %</td>
                        <td>{{detailInfo.lss_25}}</td>
                        <td>0.3750 %</td>
                        <td>{{detailInfo.lss_25_2}}</td>
                        <td>1.4250 %</td>
                        <td>{{detailInfo.lss_25_total}}</td>
                      </tr>
                      <tr>
                        <td>En dinero</td>
                        <td></td>
                        <td>0.70 %</td>
                        <td>{{detailInfo.lss_107}}</td>
                        <td>0.25 %</td>
                        <td>{{detailInfo.lss_107_2}}</td>
                        <td>0.95 %</td>
                        <td>{{detailInfo.lss_107_total}}</td>
                      </tr>
                      <tr>
                        <td>Invalidez y Vida</td>
                        <td>En especie y dinero</td>
                        <td></td>
                        <td>1.75 %</td>
                        <td>{{detailInfo.lss_147}}</td>
                        <td>0.6250 %</td>
                        <td>{{detailInfo.lss_147_2}}</td>
                        <td>2.3750 %</td>
                        <td>{{detailInfo.lss_147_total}}</td>
                      </tr>
                      <tr>
                        <td>Guarderías y Prestaciones Sociales</td>
                        <td>En especie</td>
                        <td></td>
                        <td>1.00 %</td>
                        <td>{{detailInfo.lss_211}}</td>
                        <td>0.00 %</td>
                        <td>0.00</td>
                        <td>1.00 %</td>
                        <td>{{detailInfo.lss_211}}</td>
                      </tr>
                      <tr>
                        <td>Totales</td>
                        <td></td>
                        <td></td>
                        <td>{{detailInfo.total_percent_1}}%</td>
                        <td>{{detailInfo.imss_patron}}</td>
                        <td>1.65 %</td>
                        <td>{{detailInfo.imss_obrero}}</td>
                        <td>{{detailInfo.total_percent_2}}%</td>
                        <td>{{detailInfo.pago_imss}}</td>
                      </tr>
                    </tbody>
                </table>
            </div>


            <div class="col-12 col-options">
              <!-- <p class="mb-3">
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.excel" target="_blank">Exportar a Excel</a>
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.pdf" target="_blank">Exportar a PDF</a>
              </p> -->
              <p>
                <b-button type="submit" class="_btn-st1 btn-blue" @click="otherCalc">Hacer otro cálculo</b-button>
              </p>
            </div>

          </div>
        </div>

        <div class="col-12 ml-lg-4 col-actions" v-if="step == 2">
          <div class="box">
            <h5 class="mb-2 a-title-1">ACCIONES</h5>

            <div class="box-btns">
              <a class="btn-action" :href="detailInfo.links.excel" target="_blank"><i class="icon i-xls"></i> <span>Exportar a Excel</span></a>
              <a class="btn-action" :href="detailInfo.links.pdf" target="_blank"><i class="icon i-pdf"></i> Exportar a PDF</a>
              <!-- <a class="btn-action" href="#"><i class="icon i-share"></i> Compartir</a> -->
              <button class="btn-action" @click="$refs['modal-personalize'].show()"><i class="icon i-edt"></i> Personalizar</button>
            </div>

            <!-- <div class="box-line">
              <hr />
            </div>

            <div class="box-content">
              <b-card class="card-s1">
                <a v-b-toggle.collapse-1-inner class="a-title-1">FUNDAMENTOS LEGALES</a>
                <b-collapse id="collapse-1-inner" class="collapse-content">
                  <b-card>
                    <p>Proximamente...</p>
                  </b-card>
                </b-collapse>
              </b-card>
            </div> -->

          </div>
        </div>
        <!-- Paso 2, FIN -->

        <!-- Fundamentos legales -->
        <flegales-component id="table-flegales"></flegales-component>
        <!--  -->

      </div>
    </section>

    <!-- ******** Modals ******** -->
    <!-- Modal "Compartir a Correo" -->

    <!--  -->
    <b-modal id="modal-loading" ref="modal-loading" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc modal-class="modal-st-1">
        <div style="text-align: center;">
          <img class="img-fluid" src="public/images/shared/logo.svg" style="width: 230px">
          <br><br><h3>Realizando cálculo</h3>
        </div>
    </b-modal>

    <b-modal id="modal-personalize" ref="modal-personalize" hide-footer hide-header centered  modal-class="modal-st-1">
        <b-form @submit="onSubmitPersonalize">
            <b-form-group class="col-lg-6 custom-group-s1" label="Logo" >
                    <div class="col-sm-9">
                        <div class="fileinput fileinput-new" data-provides="fileinput">
                          <div class="fileinput-preview fileinput-exists thumbnail" >
                          </div>
                          <div>
                            <span class="btn btn-white btn-file">
                              <input type="file" accept=".pdf,image/*" name="logo">
                            </span>
                          </div>
                        </div>
                    </div>
              </b-form-group>
              <div class="box-footer">
                <button  type="button" class="btn _btn-st1" @click="$refs['modal-personalize'].hide()">Cancelar</button>
                <b-button style="text-align: center;" type="submit" class="_btn-st1 btn-blue">Personalizar</b-button>
               
              </div>
               <div class="box-footer" style="text-align: center;">
                 <a style="text-align: center;" v-if="pesonalizeUrl" class="btn _btn-st1 btn-blue" :href="pesonalizeUrl" target="_blank"><i class="fas fa-download"></i> Descargar</a>
               </div>

        </b-form>
    </b-modal>

  </div>
</template>

<script>
import FlegalesComponent from './components/fleg-comp-determinacion-de-las-coutas-obrero-patronales-mensuales.vue';
export default {
  data(){
    return{
      step: 1,
      // showModalFLegales: false,
      showFLegalesComp: false,



       form:{
        anio:null,
        zona_geografica:null,
        dias_mes:null,
        periodo_retencion:null,
        salario_bruto:null,
        prima_riesgo:null,
        factor_integracion:1.0493,
        otro_importe:null,

      },


      formComp: {
        email: null
      },

      years:[],
      months:[
        {value:1,name:'Enero'},
        {value:2,name:'Febrero'},
        {value:3,name:'Marzo'},
        {value:4,name:'Abril'},
        {value:5,name:'Mayo'},
        {value:6,name:'Junio'},
        {value:7,name:'Julio'},
        {value:8,name:'Agosto'},
        {value:9,name:'Septiembre'},
        {value:10,name:'Octubre'},
        {value:11,name:'Noviembre'},
        {value:12,name:'Diciembre'},
      ],
      daysone:[],
      daystwo:[],

      detailInfo:[],
      validation:{
        anio:null,
        zona_geografica:null,
        dias_mes:null,
        periodo_retencion:null,
        salario_bruto:null,
        prima_riesgo:null,
        factor_integracion:null,
        otro_importe:null,
      },
      aniobisiesto:false,
      pesonalizeUrl:null,
      rawImg:null,

      chage_factor_integracion:0,

    }
  },
 watch:{

    'form.anio':function(val)
    {
        this.aniobisiesto = ((val % 4 == 0 && val % 100 != 0) || val % 400 == 0) ? true : false;
        if(val >= 2023){
            this.form.factor_integracion = 1.0493;
        }
        else{ 
          this.form.factor_integracion = 1.0453;
        } 
    },
    'form.factor_integracion':function(val)
    {
        if (this.chage_factor_integracion == 0) {
          this.chage_factor_integracion = 1;
          alert('El factor de integracion de ley ha sido cambiado');
        }
    },

  },
  methods: {
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    onSubmit(event) { // Enviar formulario del paso 1
      event.preventDefault();

		  /*this.validation.salario_diario = null;




      if (this.form.salario_diario == 0 || this.form.salario_diario == null) {
          this.validation.salario_diario = '"Salario diari" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-salario_diario").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      if (this.form.ultimo_sueldo == 0 || this.form.ultimo_sueldo == null) {
          this.validation.ultimo_sueldo = '"Importe del último sueldo mensual ordinario" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-ultimo_sueldo").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }*/

      this.$refs['modal-loading'].show();
      //$('#btn_calc').hide();

      axios.post(tools.url("/calculateTwenty"),this.form).then((response)=>{
          this.detailInfo = response.data;
          this.$refs['modal-loading'].hide();
          this.step = 2;
      }).catch((error)=>{
          $('#btn_calc').show();
          this.$refs['modal-loading'].hide();
          alert('El calculo no puede ser realizado con la información ingresada, porfavor modifica los parámetros e intenta de nuevo.');
      });
    },
    otherCalc(){
      location.reload();
    },

    scrolltoTable(){
      var tablePosY = document.getElementById("table-flegales").offsetTop;
      this.showFLegalesComp = true;

      setTimeout( ()=>{
        window.scrollTo({ top: tablePosY, left: 0, behavior: 'smooth' });
      }, 200);
    },

    onSubmitComp(event) { // Enviar formulario compartir del Modal "Compartir a Correo"
      event.preventDefault();
      this.$refs['modal-compartir'].hide();

      console.log("Compartir cálculo");
    },

    onSubmitCalc(event) { // Guardar cálculo y regresar a paso 1
      event.preventDefault();

      var keys = Object.keys(this.form);
      var resetForm = {};
      keys.forEach(key => resetForm[key] = null);
      this.form = resetForm;

      this.step = 1;
      this.$refs['modal-gcalculo'].hide();

      console.log("Hacer otro cálculo");
    },
    onSubmitPersonalize(event) { 
      event.preventDefault();
        this.pesonalizeUrl = null;
        const file =  jQuery('input[name="logo"]')[0].files[0];
        const reader = new FileReader()
        var self = this;
        reader.onloadend = () => {
           self.rawImg = reader.result;
           self.onSubmitPersonalizeData();
        }
        reader.readAsDataURL(file);

    },
    onSubmitPersonalizeData(){
         //enviamos el resultado y el logo
        this.$refs['modal-loading'].show();

        var formData = new FormData();
        formData.append("logo", this.rawImg);
        formData.append("formdata", JSON.stringify(this.detailInfo));

        axios.post(tools.url("/calculatePesonalize/20"),formData).then((response)=>{
            this.pesonalizeUrl = response.data.link;
            this.$refs['modal-loading'].hide();
            
        }).catch((error)=>{
            this.$refs['modal-loading'].hide();
            alert('Ocurrio un error al generar pdf');
        });
    }
  },

  mounted(){
    var fecha = new Date();
    var anio = fecha.getFullYear();
    var years = [];
    for (var i = 2016; i <= anio; i++) {
        var aux = {
          'value':i
        };
        years.push(aux);
    }

    years.reverse();
    this.years = years;
  },

  components: {
    FlegalesComponent
  },
}
</script>
<style>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 11px;
}

td {
  border: 1px solid #949494;
  text-align: left;
  padding: 8px;
}
th {
  border: 1px solid #949494;
  text-align: left;
  padding: 8px;
}

th {
  background-color: #dddddd;
}
</style>
