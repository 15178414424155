<template lang="html">
  <div id="user-access-page">

    <section class="container form-section">
      <div class="row justify-content-center">

        <div class="col-lg-10 box-content">
          <div class="row justify-content-center">
            <div class="col-12 col-banner">
              <img class="logo" src="public/images/shared/logo-white.svg">
            </div>

            <router-view></router-view>

          </div>
        </div>

      </div>
    </section>

  </div>
</template>

<script>
export default {

}
</script>
