<template lang="html">
  <div id="catalog-page">

    <section class="container oversized-container px-sm-0">
      <div class="d-block mt-3">
        <router-link class="link-back" :to="{ name: 'homePage'}"><i class="fal fa-chevron-left icon"></i> <span>Inicio</span></router-link>
      </div>
    </section>

    <section class="container oversized-container catalogs-e-section">
      <div class="row">
        <div class="col-12 box-gral-top">
          <div class="row align-items-center no-gutters">
            <div class="col">
              <h2 class="g-s1-title">Catálogo de calculadoras fiscales</h2>
            </div>
          </div>
        </div>

        <div class="col-12 col-catalogs">
          <div class="row">

            <div class="col-lg col-catalog" v-for="(catalog, cathInx) in catalogs" :key="'cathInx-'+cathInx">
              <router-link class="box" :to="{ name: catalog.url }">
              <!-- <router-link class="box" v-bind:style="{ backgroundImage: 'url('+catalog.img+')' }" :to="{ name: catalog.url }"> -->
                <div class="inside">
                  <div class="row">
                    <div class="col col-info">
                      <p>{{ catalog.text }}</p>
                    </div>

                    <div class="col col-icon">
                      <img :src="catalog.icon" width="50">
                    </div>
                  </div>
                </div>
              </router-link>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section class="container oversized-container mt-4 extra-section">
      <div class="row align-items-center">
        <div class="pl-sm-0 col-lg-5 col-xl-3 col-info">
          <h6 class="mb-2 txt-blue">¿Necesitas ayuda?</h6>
          <h6>¿No encuentras la herramienta que necesitabas?</h6>
        </div>

        <div class="col-lg col-buttons">
          <a class="btn _btn-st1" href="mailto:contacto@domain.com">Contáctanos</a>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      catalogs: [
        { //CALCULADORA 1
          url: 'catActuRecaPage', icon: 'public/images/pages/catalog/ic-1.svg', text: 'Actualización y recargos' },
        { //CALCULADORA 2
          url: 'catISREnaInmPerFis', icon: 'public/images/pages/catalog/ic-2.svg', text: 'ISR por enajenación de Inmuebles por personas físicas' },
        { //CALCULADORA 3
          url: 'catISRIVAactempypro', icon: 'public/images/pages/catalog/ic-3.svg', text: 'ISR e IVA por actividades empresariales y profesionales' },
        { //CALCULADORA 4
          url: 'catISRPagoAguiPage', icon: 'public/images/pages/catalog/ic-4.svg', text: 'ISR por pago de aguinaldo' },

        {//CALCULADORA 5
          url: 'IEPSVentaCerveza', icon: 'public/images/pages/catalog/ic-5.svg', text: 'IEPS por venta de cerveza' },
        {//CALCULADORA 6
          url: 'DividendosADistribuir', icon: 'public/images/pages/catalog/ic-6.svg', text: 'Dividendos por distribuir a accionistas e ISR a cargo' },

         {//CALCULADORA 7
          url: 'AjusteAnualPorInflacion', icon: 'public/images/pages/catalog/ic-7.svg', text: 'Ajuste anual por inflación' },
        {//CALCULADORA 8
          url: 'PagoContribucionesDiferidas', icon: 'public/images/pages/catalog/ic-8.svg', text: 'Pago de contribuciones diferidas' },

        {//CALCULADORA 9
          url: 'PagoContribucionesParcialidades', icon: 'public/images/pages/catalog/ic-9.svg', text: 'Pago de contribuciones en parcialidades' },

        {//CALCULADORA 10
          url: 'AmortizacionPerdidasFiscales', icon: 'public/images/pages/catalog/ic-10.svg', text: 'Amortizacion de perdidas fiscales' },

        {//CALCULADORA 11
          url: 'IsrEjercicioGananciaEnajecion', icon: 'public/images/pages/catalog/ic-11.svg', text: 'ISR del ejercicio por la ganancia en la enajenación de inmuebles' },

        {//CALCULADORA 12
        url: 'IsrPagoIndemnizacionesRetiro', icon: 'public/images/pages/catalog/ic-12.svg', text: 'ISR por el pago de las indemnizaciones por retiro' },

        {//CALCULADORA 13
        url: 'IsrIvaArrendamientoPagosProvisionales', icon: 'public/images/pages/catalog/ic-13.svg', text: 'ISR e IVA por arrendamiento pagos provisionales' },


        {//CALCULADORA 14 ** Referencia circular
        url: 'IsrPorlaPrestacionDeunServicioPersonalSubordinado', icon: 'public/images/pages/catalog/ic-14.svg', text: 'ISR por la prestación de un servicio personal subordinado (salarios)' },


        {//CALCULADORA 15
        url: 'IsrPorelPagoDelFiniquitoPorelRetiroVoluntario', icon: 'public/images/pages/catalog/ic-15.svg', text: 'ISR por el pago del finiquito por retiro voluntario' },

        {//CALCULADORA 16
        url: 'IsrPorIngresosAsimiladosAlaPrestacionDeunServicioPersonalSubordinado', icon: 'public/images/pages/catalog/ic-16.svg', text: 'ISR por ingresos asimilados a la prestación de un servicio personal subordinado (salarios)' },
        {//CALCULADORA 17
        url: 'IsrPorPagoDePtu', icon: 'public/images/pages/catalog/ic-17.svg', text: 'ISR por pago de PTU' },
        {//CALCULADORA 18
        url: 'DeterminacionDelasCuotasObreroPatronalesBimestrales', icon: 'public/images/pages/catalog/ic-18.svg', text: 'Determinación de las cuotas obrero patronales bimestrales' },
        {//CALCULADORA 19
        url: 'SalarioDiarioBaseDeCotizacionParaEfectosDelImssEInfonavit', icon: 'public/images/pages/catalog/ic-19.svg', text: 'Salario diario base de cotización para efectos del IMSS e INFONAVIT' },

        {//CALCULADORA 20
        url: 'DeterminacionDelasCuotasObreroPatronalesMensuales', icon: 'public/images/pages/catalog/ic-20.svg', text: 'Determinación de las cuotas obrero patronales mensuales' },


        {//CALCULADORA 21
        url: 'DeterminacionDeLasCuotasObreroPatronalesMensuales', icon: 'public/images/pages/catalog/ic-19.svg', text: 'Determinación de las cuotas obrero patronales mensuales y bimestrales en conjunto' },

         {//CALCULADORA 22
          url: 'MultasDerivadasNom035', icon: 'public/images/pages/catalog/ic-22.svg', text: 'Multas Derivadas Nom035' },

          {//CALCULADORA 23
            url: 'DeterminacionAnualVacacionesLFT', icon: 'public/images/pages/catalog/ic-22.svg', text: 'Determinación anual de las vacaciones LFT (Sin retroactividad de la ley)' },
      ],
    }
  }
}
</script>
