<template lang="html">
    <div id="catalog-form-page">
  
      <section class="container oversized-container main-section">
        <div class="row pt-4">
          <div class="col col-lg-3 pl-sm-0 col-link">
            <a class="link-back" href="javascript:history.back();" ><i class="fal fa-chevron-left icon"></i> <span>Regresar</span></a>
          </div>
  
          <div class="col-lg col-titles">
            <h1>Determinación anual de las vacaciones LFT (Sin retroactividad de la  ley)</h1>
            <hr />
          </div>
  
          <div class="col col-lg-3 col-empty"></div>
        </div>
  
        <div class="row justify-content-center">
          <!-- Paso 1, Form -->
          <div class="col-12 col-mid col-form" v-if="step == 1">
            <h5 class="mb-4 title">DATOS GENERALES</h5>
  
            <b-form @submit="onSubmit">
  
                <b-form-group class="custom-form-group-s2"
                label="Año del cálculo: "
                label-for="i-anio">
                    <div class="row">
                      <div class="col-12 col-sm">
                            <b-form-select v-model="form.anio" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                              <b-form-select-option :value="null" disabled selected>Año</b-form-select-option>
                              <b-form-select-option :value="year.value" v-for="(year, yindx) in yearsone" :key="yindx">{{year.value}}</b-form-select-option>
  
                          </b-form-select>
                      </div>
                   </div>
              </b-form-group>
  
              <b-form-group class="custom-form-group-s2"
                label="¿Zona geográfica del trabajador?: "
                label-for="i-zona_geografica">
                  <div class="row">
                    <div class="col-12 col-sm">
                        <b-form-select v-model="form.zona_geografica" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                          <b-form-select-option :value="null" disabled selected>Selecciona una opción</b-form-select-option>
                          <b-form-select-option value="rp" >RP</b-form-select-option>
                          <b-form-select-option value="zlfn" >ZLFN</b-form-select-option>
                      </b-form-select>
                    </div>
                </div>
              </b-form-group> 

            
  
              <!-- <b-form-group class="custom-form-group-s2"
                label="¿Días del mes?: "
                label-for="i-dias_mes">
                  <div class="row">
                    <div class="col-12 col-sm">
                        <b-form-select v-model="form.dias_mes" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                          <b-form-select-option :value="null" disabled selected>Selecciona una opción</b-form-select-option>
                          <b-form-select-option value="28" v-if="aniobisiesto == false" >28</b-form-select-option>
                          <b-form-select-option value="29" v-if="aniobisiesto == true">29</b-form-select-option>
  
                          <b-form-select-option value="30" >30</b-form-select-option>
                          <b-form-select-option value="30.40" >30.40</b-form-select-option>
                          <b-form-select-option value="30.41666" >30.41666</b-form-select-option>
                          <b-form-select-option value="31" >31</b-form-select-option>
  
                      </b-form-select>
                    </div>
                </div>
              </b-form-group> -->

                
            <b-form-group class="custom-form-group-s2"
              label="Salario diario no integrado:"
              label-for="i-vent" id="input-salario_diario">

              <currency-input id="i-vent" class="form-control"
                v-model="form.salario_diario" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.salario_diario != null" style="color: red;"><small>{{validation.salario_diario}}</small></div>
            </b-form-group>


              <b-form-group class="custom-form-group-s2"
              label="Dias de vacaciones según la LFT: "
              label-for="i-anio">
              	<div class="row">
                	<div class="col-12 col-sm">
                  		<b-form-select v-model="form.vacaciones_lft" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
		                    <b-form-select-option :value="null" disabled selected>Año</b-form-select-option>
		                    <b-form-select-option :value="1">Si</b-form-select-option>

                        <b-form-select-option :value="0">No</b-form-select-option>

		                </b-form-select>
                	</div>
             	</div>
                </b-form-group>

  
            <b-form-group class="custom-form-group-s2"
              label="Días de vacaciones según las prestaciones del empleador:"
              label-for="i-nul" id="input-dias_vacaciones" v-if="form.vacaciones_lft == 0">

              <b-form-input
                id="input-dias_vacaciones"
                v-model="form.dias_vacaciones"
                type="text"
                placeholder="0.00"
                @keypress="isNumber($event)"
                required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"

              ></b-form-input>
              <div v-if="validation.dias_vacaciones != null" style="color: red;"><small>{{validation.dias_vacaciones}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Días de vacaciones pendientes de otorgar (años anteriores):"
              label-for="i-nul" id="input-dias_vacaciones_pedientes_otorgar">
              <b-form-input
                id="i-nul"
                v-model="form.dias_vacaciones_pedientes_otorgar"
                type="text"
                placeholder="0.00"
                @keypress="isNumber($event)"
                required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"
              ></b-form-input>
              <div v-if="validation.dias_vacaciones_pedientes_otorgar != null" style="color: red;"><small>{{validation.dias_vacaciones_pedientes_otorgar}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Días de vacaciones pendientes de descontar (años anteriores):"
              label-for="i-nul" id="input-dias_vacaciones_descontar">
              <b-form-input
                id="i-nul"
                v-model="form.dias_vacaciones_descontar"
                type="text"
                placeholder="0.00"
                @keypress="isNumber($event)"
                required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"
              ></b-form-input>
              <div v-if="validation.dias_vacaciones_descontar != null" style="color: red;"><small>{{validation.dias_vacaciones_descontar}}</small></div>
            </b-form-group>


            <b-form-group class="custom-form-group-s2"
              label="Fecha de ingreso del colaborador:" >
              <div class="row">
                <div class="col-12 col-sm">
                  <b-form-select v-model="form.mes1" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Mes</b-form-select-option>
                    <b-form-select-option :value="month.value" v-for="(month, mindx) in months" :key="mindx">{{month.name}}</b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select v-model="form.dia1" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Día</b-form-select-option>
                    <b-form-select-option :value="day.value" v-for="(day, dndx) in daysone" :key="dndx">{{day.value}}</b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select v-model="form.anio1" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Año</b-form-select-option>
                    <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>
                  </b-form-select>
                </div>
              </div>
              
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Fecha de aniversario del ingreso del colaborador:" >
              <div class="row">
                <div class="col-12 col-sm">
                  <b-form-select v-model="form.mes2" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')" disabled>
                    <b-form-select-option :value="null" disabled>Mes</b-form-select-option>
                    <b-form-select-option :value="month.value" v-for="(month, mindx) in months" :key="mindx">{{month.name}}</b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select v-model="form.dia2" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')" disabled>
                    <b-form-select-option :value="null" disabled>Día</b-form-select-option>
                    <b-form-select-option :value="day.value" v-for="(day, dndx) in daysone" :key="dndx">{{day.value}}</b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select v-model="form.anio2" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Año</b-form-select-option>
                    <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>
                  </b-form-select>
                </div>
              </div>
              
            </b-form-group>

  
             
              <p class="mt-3 mt-lg-5 pt-sm-4 text-center">
                <b-button type="submit" class="_btn-st1 btn-blue" id="btn_calc">Calcular</b-button>
              </p>
            </b-form>
          </div>
          <!-- Paso 1, FIN -->
  
          <!-- Paso 2, Mostrar datos -->
          <div class="col-12 col-mid col-results" v-if="step == 2" style="max-width:100% !important">
            <!-- <h5 class="title">DATOS GENERALES</h5> -->
            <div class="row">
              <!-- <div class="col-12 col-line">
                <hr />
              </div> -->
  
              <div class="col-12 col-subbox">
                <div class="row align-items-center">
               <div class="col col-text">
                    <h2 class="trans-250 title with-link" @click="scrolltoTable">CONSULTAR FUNDAMENTOS LEGALES <i class="fas fa-info-circle icon"></i></h2>
                  </div>
  
                  <!-- <div class="col col-share">
                    <a class="trans-150 btn-share" v-b-modal.modal-compartir>Compartir <i class="fas fa-share-alt"></i></a>
                  </div> -->
                </div>
              </div>
  
              <div class="col-12 col-info">
                  <p style="text-align:center"><b>Determinación de las vacaciones LFT 2023: sin retroactividad de la ley </b></p>

                  <div class="row row-body">
                    <div class="col col-detail">
                      Días de vacaciones proporcionales LFT 2022:
                    </div>
                    <div class="col col-med">
                      {{detailInfo.dias_vaciones_2022}}
                    </div>
                  </div>

                  <div class="row row-body">
                    <div class="col col-detail">
                      Días de vacaciones proporcionales LFT 2023:
                    </div>
                    <div class="col col-med">
                      {{detailInfo.dias_vaciones_2023}}
                    </div>
                  </div>

                  <div class="row row-body">
                    <div class="col col-detail">
                      Días de vacaciones según la LFT 2022 y LFT 2023:
                    </div>
                    <div class="col col-med">
                      {{detailInfo.dias_vaciones_2022_2023}}
                    </div>
                  </div>

                  <div class="row row-body">
                    <div class="col col-detail col-strong">
                      Total de días de vacaciones por otorgar al colaborador:
                    </div>
                    <div class="col col-med col-strong" >
                      {{detailInfo.total_dias_vacaciones}}
                    </div>
                  </div>

                  <div class="row row-body">
                    <div class="col col-detail">
                      Vacaciones a otorgar al trabajador únicamente con la LFT 2022:
                    </div>
                    <div class="col col-med">
                      {{detailInfo.vacaciones_a_otorgar_unicamente_2022}}
                    </div>
                  </div>


                  <p style="text-align:center"><b>Vacaciones según la tabla de la LFT </b></p>
                  <div class="row row-body">
                    <div class="col col-detail">
                      Días de vacaciones según las prestaciones del empleador: ( O ART. 76 LFT  2023)
                    </div>
                    <div class="col col-med">
                      {{detailInfo.dias_vacaciones_prestaciones}}
                    </div>
                  </div>

                  <div class="row row-body">
                    <div class="col col-detail">
                      Años cumplidos de servicio del colaborador al  2023:
                    </div>
                    <div class="col col-med">
                      {{detailInfo.anio_ccumplios_servicio}}
                    </div>
                  </div>

                  <p style="text-align:center"><b>Determinación de días de vacaciones en dinero </b></p>
                  <div class="row row-body">
                    <div class="col col-detail">
                      Días de vacaciones proporcionales LFT 2022:
                    </div>
                    <div class="col col-med">
                      {{detailInfo.dias_vacaciones_propocionales_2022}}
                    </div>
                  </div>

                  <div class="row row-body">
                    <div class="col col-detail">
                      Días de vacaciones proporcionales LFT 2023:
                    </div>
                    <div class="col col-med">
                      {{detailInfo.dias_vacaciones_propocionales_2023}}
                    </div>
                  </div>

                  <div class="row row-body">
                    <div class="col col-detail">
                      Días de vacaciones según la LFT 2022 y LFT 2023:
                    </div>
                    <div class="col col-med">
                      {{detailInfo.dias_vacaciones_segun_lft_2022_2023}}
                    </div>
                  </div>

                  <div class="row row-body">
                    <div class="col col-detail">
                      Total de días de vacaciones por otorgar al colaborador:
                    </div>
                    <div class="col col-med">
                      {{detailInfo.total_dias_vaciones_otorgar}}
                    </div>
                  </div>

                  <div class="row row-body">
                    <div class="col col-detail">
                      Vacaciones por otorgar al colaborador únicamente con la LFT 2022:
                    </div>
                    <div class="col col-med">
                      {{detailInfo.vacaciones_a_otorgar}}
                    </div>
                  </div>

                  <div class="row row-body">
                    <div class="col col-detail">
                      Prima vacacional exenta:
                    </div>
                    <div class="col col-med">
                      {{detailInfo.prima_vacional}}
                    </div>
                  </div>

                 
              </div>
  
              <div class="col-12 col-options">
                <!-- <p class="mb-3">
                  <a type="button" class="btn _btn-st1" :href="detailInfo.links.excel" target="_blank">Exportar a Excel</a>
                  <a type="button" class="btn _btn-st1" :href="detailInfo.links.pdf" target="_blank">Exportar a PDF</a>
                </p> -->
                <p>
                  <b-button type="submit" class="_btn-st1 btn-blue" @click="otherCalc">Hacer otro cálculo</b-button>
                </p>
              </div>
  
            </div>
          </div>
  
          <div class="col-12 ml-lg-4 col-actions" v-if="step == 2">
            <div class="box">
              <h5 class="mb-2 a-title-1">ACCIONES</h5>
  
              <div class="box-btns">
                <a class="btn-action" :href="detailInfo.links.excel" target="_blank"><i class="icon i-xls"></i> <span>Exportar a Excel</span></a>
                <a class="btn-action" :href="detailInfo.links.pdf" target="_blank"><i class="icon i-pdf"></i> Exportar a PDF</a>
                <!-- <a class="btn-action" href="#"><i class="icon i-share"></i> Compartir</a> -->
                <button class="btn-action" @click="$refs['modal-personalize'].show()"><i class="icon i-edt"></i> Personalizar</button>
              </div>
  <!-- 
              <div class="box-line">
                <hr />
              </div>
  
              <div class="box-content">
                <b-card class="card-s1">
                  <a v-b-toggle.collapse-1-inner class="a-title-1">FUNDAMENTOS LEGALES</a>
                  <b-collapse id="collapse-1-inner" class="collapse-content">
                    <b-card>
                      <p>Proximamente...</p>
                    </b-card>
                  </b-collapse>
                </b-card>
              </div> -->
  
            </div>
          </div>
          <!-- Paso 2, FIN -->
  
          <!-- Fundamentos legales -->
          <flegales-component id="table-flegales"></flegales-component>
          <!--  -->
  
        </div>
      </section>
  
      <!-- ******** Modals ******** -->
      <!-- Modal "Compartir a Correo" -->
  
      <!--  -->
      <b-modal id="modal-loading" ref="modal-loading" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc modal-class="modal-st-1">
          <div style="text-align: center;">
            <img class="img-fluid" src="public/images/shared/logo.svg" style="width: 230px">
            <br><br><h3>Realizando cálculo</h3>
          </div>
      </b-modal>
  
      <b-modal id="modal-personalize" ref="modal-personalize" hide-footer hide-header centered  modal-class="modal-st-1">
          <b-form @submit="onSubmitPersonalize">
              <b-form-group class="col-lg-6 custom-group-s1" label="Logo" >
                      <div class="col-sm-9">
                          <div class="fileinput fileinput-new" data-provides="fileinput">
                            <div class="fileinput-preview fileinput-exists thumbnail" >
                            </div>
                            <div>
                              <span class="btn btn-white btn-file">
                                <input type="file" accept=".pdf,image/*" name="logo">
                              </span>
                            </div>
                          </div>
                      </div>
                </b-form-group>
                <div class="box-footer">
                  <button  type="button" class="btn _btn-st1" @click="$refs['modal-personalize'].hide()">Cancelar</button>
                  <b-button style="text-align: center;" type="submit" class="_btn-st1 btn-blue">Personalizar</b-button>
                 
                </div>
                 <div class="box-footer" style="text-align: center;">
                   <a style="text-align: center;" v-if="pesonalizeUrl" class="btn _btn-st1 btn-blue" :href="pesonalizeUrl" target="_blank"><i class="fas fa-download"></i> Descargar</a>
                 </div>
  
          </b-form>
      </b-modal>
  
    </div>
  </template>
  
  <script>
  import FlegalesComponent from './components/fleg-comp-determinacion-anual-vacaciones-lft.vue';
  export default {
    data(){
      return{
        step: 1,
        // showModalFLegales: false,
        showFLegalesComp: false,
  
  
  
         form:{
          anio:null,
          zona_geografica:null,
          dias_mes:null,
          periodo_retencion:null,
          salario_bruto:null,
          prima_riesgo:null,
          factor_integracion:1.0452,
          otro_importe:null,
  
        },
  
       /* form:{
          anio:2022,
          zona_geografica:'rp',
          dias_mes:'30.40',
          periodo_retencion:'semanal',
          salario_bruto: '80000.00',
          prima_riesgo:'0.5000',
          factor_integracion:1.0452,
          otro_importe:0,
  
        },*/
  
  
        formComp: {
          email: null
        },
  
        years:[],
        yearsone:[],
        months:[
          {value:1,name:'Enero'},
          {value:2,name:'Febrero'},
          {value:3,name:'Marzo'},
          {value:4,name:'Abril'},
          {value:5,name:'Mayo'},
          {value:6,name:'Junio'},
          {value:7,name:'Julio'},
          {value:8,name:'Agosto'},
          {value:9,name:'Septiembre'},
          {value:10,name:'Octubre'},
          {value:11,name:'Noviembre'},
          {value:12,name:'Diciembre'},
        ],
        daysone:[],
        daystwo:[],
  
        detailInfo:[],
        validation:{
          anio:null,
          zona_geografica:null,
          dias_mes:null,
          periodo_retencion:null,
          salario_bruto:null,
          prima_riesgo:null,
          factor_integracion:null,
          otro_importe:null,
          salario_diario:null,
          dias_vacaciones:null
        },
        aniobisiesto:false,
        pesonalizeUrl:null,
        rawImg:null
      }
    },
    watch:{
        'form.dia1':function(val){
            this.form.dia2 = val;
        },
        'form.mes1':function(val)
        {
            var fecha = new Date();
            if (this.form.anio1 == null) {
                var anio = fecha.getFullYear();
            }
            else{
                var anio  = this.form.anio1;
            }
            var d = new Date(anio, val, 0);
            var days =[];
            for (var i = 1; i <= d.getDate(); i++) {
                var aux = {
                'value':i
                };
                days.push(aux);
            }
            this.daysone = days;
            this.form.mes2 = val;
        },
        'form.anio1':function(val)
        {
            var fecha = new Date();
            if (val == null) {
                var anio = fecha.getFullYear();
            }
            else{
                var anio  = val;
            }
            var d = new Date(anio, this.form.mes1, 0);
            var days =[];
            for (var i = 1; i <= d.getDate(); i++) {
                var aux = {
                'value':i
                };
                days.push(aux);
            }
            this.daysone = days;
        },
        /*'form.mes2':function(val)
        {
            var fecha = new Date();
            if (this.form.anio2 == null) {
                var anio = fecha.getFullYear();
            }
            else{
                var anio = this.form.anio2;
            }
            var d = new Date(anio, val, 0);
            var days =[];
            for (var i = 1; i <= d.getDate(); i++) {
                var aux = {
                'value':i
                };
                days.push(aux);
            }
            this.daystwo = days;
        },
        'form.anio2':function(val)
        {
            var fecha = new Date();
            if (val == null) {
                var anio = fecha.getFullYear();
            }
            else{
                var anio  = val;
            }
            var d = new Date(anio, this.form.mes2, 0);
            var days =[];
            for (var i = 1; i <= d.getDate(); i++) {
                var aux = {
                'value':i
                };
                days.push(aux);
            }
            this.daystwo = days;
        },*/

      'form.anio':function(val)
      {
          this.aniobisiesto = ((val % 4 == 0 && val % 100 != 0) || val % 400 == 0) ? true : false;
      },
  
    },
    methods: {
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
  
      onSubmit(event) { // Enviar formulario del paso 1
        event.preventDefault();
        this.validation.salario_diario = null;
        if (this.form.vacaciones_lft == 0) {

            if (this.form.anio <= 2022) {
                if (this.form.dias_vacaciones < 6 ) {
                    this.validation.dias_vacaciones = '"Días de vacaciones según las prestaciones del empleador" debe de ser mayor a 6';
                    var scrollt = document.getElementById("input-dias_vacaciones").offsetTop;
                    setTimeout( ()=>{
                    window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
                    }, 200);
                    return; 
                }
            }
            else{
                if (this.form.dias_vacaciones < 12 ) {
                    this.validation.dias_vacaciones = '"Días de vacaciones según las prestaciones del empleador" debe de ser mayor a 12';
                    var scrollt = document.getElementById("input-dias_vacaciones").offsetTop;
                    setTimeout( ()=>{
                    window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
                    }, 200);
                    return; 
                }
            }
            
        }
        
  
            /*this.validation.salario_diario = null;
  
  
  
  
        if (this.form.salario_diario == 0 || this.form.salario_diario == null) {
            this.validation.salario_diario = '"Salario diari" debe de ser mayor a 0';
            var scrollt = document.getElementById("input-salario_diario").offsetTop;
            setTimeout( ()=>{
              window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
            }, 200);
            return;
        }
        if (this.form.ultimo_sueldo == 0 || this.form.ultimo_sueldo == null) {
            this.validation.ultimo_sueldo = '"Importe del último sueldo mensual ordinario" debe de ser mayor a 0';
            var scrollt = document.getElementById("input-ultimo_sueldo").offsetTop;
            setTimeout( ()=>{
              window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
            }, 200);
            return;
        }*/
  
        this.$refs['modal-loading'].show();
        //$('#btn_calc').hide();
  
        axios.post(tools.url("/calculateTwentyThree"),this.form).then((response)=>{
            this.detailInfo = response.data;
            this.$refs['modal-loading'].hide();
            this.step = 2;
        }).catch((error)=>{
            $('#btn_calc').show();
            this.$refs['modal-loading'].hide();
            alert('El calculo no puede ser realizado con la información ingresada, porfavor modifica los parámetros e intenta de nuevo.');
        });
      },
      otherCalc(){
        location.reload();
      },
  
      scrolltoTable(){
        var tablePosY = document.getElementById("table-flegales").offsetTop;
        this.showFLegalesComp = true;
  
        setTimeout( ()=>{
          window.scrollTo({ top: tablePosY, left: 0, behavior: 'smooth' });
        }, 200);
      },
  
      onSubmitComp(event) { // Enviar formulario compartir del Modal "Compartir a Correo"
        event.preventDefault();
        this.$refs['modal-compartir'].hide();
  
        console.log("Compartir cálculo");
      },
  
      onSubmitCalc(event) { // Guardar cálculo y regresar a paso 1
        event.preventDefault();
  
        var keys = Object.keys(this.form);
        var resetForm = {};
        keys.forEach(key => resetForm[key] = null);
        this.form = resetForm;
  
        this.step = 1;
        this.$refs['modal-gcalculo'].hide();
  
        console.log("Hacer otro cálculo");
      },
      onSubmitPersonalize(event) { 
        event.preventDefault();
          this.pesonalizeUrl = null;
          const file =  jQuery('input[name="logo"]')[0].files[0];
          const reader = new FileReader()
          var self = this;
          reader.onloadend = () => {
             self.rawImg = reader.result;
             self.onSubmitPersonalizeData();
          }
          reader.readAsDataURL(file);
  
      },
      onSubmitPersonalizeData(){
           //enviamos el resultado y el logo
          this.$refs['modal-loading'].show();
  
          var formData = new FormData();
          formData.append("logo", this.rawImg);
          formData.append("formdata", JSON.stringify(this.detailInfo));
  
          axios.post(tools.url("/calculatePesonalize/23"),formData).then((response)=>{
              this.pesonalizeUrl = response.data.link;
              this.$refs['modal-loading'].hide();
              
          }).catch((error)=>{
              this.$refs['modal-loading'].hide();
              alert('Ocurrio un error al generar pdf');
          });
      }
    },
  
    mounted(){
      var fecha = new Date();
      var anio = fecha.getFullYear() + 2;
      var years = [];
      for (var i = 2016; i <= anio; i++) {
          var aux = {
            'value':i
          };
          years.push(aux);
      }
      years.reverse();

      var yearsone = [];
      for (var i = 2016; i <= fecha.getFullYear(); i++) {
          var aux = {
            'value':i
          };
          yearsone.push(aux);
      }
      yearsone.reverse();
      
      this.years = years;
      this.yearsone = yearsone;
    },
  
    components: {
      FlegalesComponent
    },
  }
  </script>
  <style>
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    font-size: 11px;
  }
  
  td {
    border: 1px solid #949494;
    text-align: left;
    padding: 8px;
  }
  th {
    border: 1px solid #949494;
    text-align: left;
    padding: 8px;
  }
  
  th {
    background-color: #dddddd;
  }
  </style>
  