<template lang="html">
  <b-form @submit="onSubmit()">
    <h6>Datos de personales</h6>
    <hr class="c-hr" />

    <b-form-group class="custom-input"
      label="Nombre completo:"
      label-for="i-nombre">
      <b-form-input id="i-nombre" type="text"
        v-model="form.nombre" size="sm"
        required placeholder="Escriba su nombre completo" />
    </b-form-group>

    <b-form-group class="custom-input"
      label="Correo electrónico:"
      label-for="i-email">
      <b-form-input id="i-email" type="email"
        v-model="form.email" size="sm"
        required placeholder="Escriba correo electrónico" />
    </b-form-group>

    <b-form-group class="custom-input"
      label="Teléfono:"
      label-for="i-tel">
      <b-form-input id="i-tel" type="text"
        v-model="form.tel" size="sm"
        required placeholder="Escriba su teléfono" />
    </b-form-group>

    <!-- <h6 class="mt-5">Dirección</h6>
    <hr class="c-hr" />

    <div class="row mx-0 no-gutters">
      <div class="col-md-7 col-lg-6">
        <b-form-group class="custom-input"
          label="Calle:"
          label-for="i-calle">
          <b-form-input id="i-calle" type="text"
            v-model="form.calle" size="sm"
            required placeholder="Calle donde vive" />
        </b-form-group>
      </div>
      <div class="col-md pl-md-3">
        <b-form-group class="custom-input"
          label="Numero ext:"
          label-for="i-num">
          <b-form-input id="i-num" type="text"
            v-model="form.numeroext" size="sm"
            required placeholder="0000" />
        </b-form-group>
      </div>

      <div class="col-md pl-md-3">
        <b-form-group class="custom-input"
          label="Numero int:"
          label-for="i-num">
          <b-form-input id="i-num" type="text"
            v-model="form.numeroint" size="sm"
            required placeholder="0000" />
        </b-form-group>
      </div>
    </div>

    <div class="row mx-0 no-gutters">
      <div class="col-md-8">
        <b-form-group class="custom-input"
          label="Colonia:"
          label-for="i-colonia">
          <b-form-input id="i-colonia" type="text"
            v-model="form.colonia" size="sm"
            required placeholder="Escriba su colonia" />
        </b-form-group>
      </div>

      <div class="col-md pl-md-3">
        <b-form-group class="custom-input"
          label="Código postal:"
          label-for="i-cp">
          <b-form-input id="i-cp" type="text"
            v-model="form.cp" size="sm"
            required placeholder="Escriba su código postal" />
        </b-form-group>
      </div>
    </div>


    <div class="row mx-0 no-gutters">
      <div class="col-md-6 pr-md-3">
        <b-form-group class="custom-input"
          label="Estado:"
          label-for="i-estado">

          <b-form-select v-model="form.estado" size="sm" required>
            <option :value="null">Por favor seleccione su estado</option>
            <option value="1">Estado de México</option>
            <option value="2">Jalisco</option>
            <option value="3">Zacatecas</option>
          </b-form-select>
        </b-form-group>
      </div>

      <div class="col-md-6">
        <b-form-group class="custom-input"
          label="Ciudad:"
          label-for="i-ciudad">
          <b-form-input id="i-ciudad" type="text"
            v-model="form.ciudad" size="sm"
            required placeholder="Escriba su Ciudad" />
        </b-form-group>
      </div>
    </div> -->

    <b-button type="submit" class="btn-red btn-form" variant="primary">Guardar cambios</b-button>

  </b-form>
</template>

<script>
export default {
  data(){
    return{
      form: {
        nombre: 'Eduardo Lopez Lopez'
      }
    }
  },

  methods: {
    onSubmit(){
      console.log('Mis datos...');
    }
  }
}
</script>
