<template lang="html">
  <div class="col-12 col-lg-11 col-xl-9 table-container-s1">
    <div v-if="$parent.showFLegalesComp" style="font-family: sans-serif !important; text-align: justify;">
      <h4 class="table-title">Fundamentos legales</h4>

      <table class="table table-hover" v-for="(item, tabl1Inx) in items" :key="'tabl1Inx-'+tabl1Inx">
        <thead>
          <tr>
            <th scope="col" width="178">{{ item.title1 }}</th>
            <th scope="col">{{ item.title2 }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, rtInx) in item.content">
            <th>{{ row.c1 }}</th>
            <td v-html="row.c2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  ddata(){
    return{
      items: [
        { title1: 'Ley/Art./Párrafo/ Fracc.', title2: 'SECCIÓN I DE LAS PERSONAS FÍSICAS CON ACTIVIDADES EMPRESARIALES Y PROFESIONALES',
          content: [
            { c1: 'LISR',
              c2: 'MOMENTO EN QUE SE CONSIDERAN EFECTIVAMENTE PERCIBIDOS LOS INGRESOS'
            },
            { c1: 'LISR-102-P2',
              c2: 'Los ingresos se consideran efectivamente percibidos cuando se reciban en efectivo, en bienes o en servicios, aun cuando aquéllos correspondan a anticipos, a depósitos o a cualquier otro concepto, sin importar el nombre con el que se les designe. Igualmente se considera percibido el ingreso cuando el contribuyente reciba títulos de crédito emitidos por una persona distinta de quien efectúa el pago. Cuando se perciban en cheque, se considerará percibido el ingreso en la fecha de cobro del mismo o cuando los contribuyentes transmitan los cheques a un tercero, excepto cuando dicha transmisión sea en procuración. También se entiende que es efectivamente percibido cuando el interés del acreedor queda satisfecho mediante cualquier forma de extinción de las obligaciones.'
            },

          ]
        },
        { title1: '', title2: 'Pagos provisionales mensuales',
          content: [
            { c1: 'LISR-106-P1',
              c2: 'Los contribuyentes a que se refiere esta Sección, efectuarán pagos provisionales mensuales a cuenta del impuesto del ejercicio, a más tardar el día 17 del mes inmediato posterior a aquél al que corresponda el pago, mediante declaración que presentarán ante las oficinas autorizadas. El pago provisional se determinará restando de la totalidad de los ingresos a que se refiere esta Sección obtenidos en el periodo comprendido desde el inicio del ejercicio y hasta el último día del mes al que corresponde el pago, las deducciones autorizadas en esta Sección correspondientes al mismo periodo y la participación de los trabajadores en las utilidades de las empresas pagada en el ejercicio, en los términos del artículo 123 de la Constitución Política de los Estados Unidos Mexicanos y, en su caso, las pérdidas fiscales ocurridas en ejercicios anteriores que no se hubieran disminuido.'
            },
            { c1: 'LISR-106-P2',
              c2: 'Al resultado que se obtenga conforme al párrafo anterior, se le aplicará la tarifa que se determine de acuerdo a lo siguiente:'
            },
            { c1: '',
              c2: 'TARIFA APLICABLE'
            },
            { c1: 'LISR-106-P3',
              c2: 'Se tomará como base la tarifa del artículo 96 de esta Ley, sumando las cantidades correspondientes a las columnas relativas al límite inferior, límite superior y cuota fija, que en los términos de dicho artículo resulten para cada uno de los meses del periodo a que se refiere el pago provisional de que se trate, y que correspondan al mismo renglón identificado por el mismo por ciento para aplicarse sobre el excedente del límite inferior. Las autoridades fiscales realizarán las operaciones aritméticas previstas en este párrafo para calcular la tarifa aplicable y la publicará en el Diario Oficial de la Federación."'
            },
            { c1: '',
              c2: 'ACREDITAMIENTO DE PAGOS PROVISIONALES ANTERIORES'
            },
            { c1: 'LISR-106-P4',
              c2: 'Contra el pago provisional determinado conforme a este artículo, se acreditarán los pagos provisionales del mismo ejercicio efectuados con anterioridad.'
            },
            { c1: '',
              c2: 'RETENCION DE UN 10% DE ISR POR PERSONAS MORALES'
            },
            { c1: 'LISR-106-P5',
              c2: 'Cuando los contribuyentes presten servicios profesionales a las personas morales, éstas deberán retener, como pago provisional, el monto que resulte de aplicar la tasa del 10% sobre el monto de los pagos que les efectúen, sin deducción alguna, debiendo proporcionar a los contribuyentes comprobante fiscal en el que conste el monto del impuesto retenido, el cual deberá enterarse, en su caso, conjuntamente con las retenciones señaladas en el artículo 96 de esta Ley. El impuesto retenido en los términos de este párrafo será acreditable contra el impuesto a pagar que resulte en los pagos provisionales de conformidad con este artículo.'
            },
            
          ]
        },
        { title1: '', title2: 'PLAZO PARA AMORTIZACION DE PERDIDAS',
          content: [
            { c1: 'LISR-109-P2-F1',
              c2: 'La pérdida fiscal ocurrida en un ejercicio podrá disminuirse de la utilidad fiscal determinada en los términos de esta Sección, de los diez ejercicios siguientes, hasta agotarla.'
            },
            { c1: 'LISR-109-P2-F2',
              c2: 'Las pérdidas fiscales que obtengan los contribuyentes por la realización de las actividades a que se refiere esta Sección, sólo podrán ser disminuidas de la utilidad fiscal derivada de las propias actividades a que se refiere la misma.'
            },
            
          ]
        },
        { title1: 'LIVA', title2: 'Sujetos del impuesto',
          content: [
            { c1: 'LIVA-1-P1',
              c2: 'Están obligadas al pago del impuesto al valor agregado establecido en esta Ley, las personas físicas y las morales que, en territorio nacional, realicen los actos o actividades siguientes:'
            },
            { c1: 'LIVA-1-P1-F1',
              c2: 'Enajenen bienes.'
            },
            { c1: 'LIVA-1-P1-F2',
              c2: 'Presten servicios independientes.'
            },
            { c1: 'LIVA-1-P1-F3',
              c2: 'Otorguen el uso o goce temporal de bienes.'
            },
            { c1: 'LIVA-1-P1-F4',
              c2: 'Importen bienes o servicios.'
            },
            { c1: '',
              c2: 'TASA DE IMPUESTO'
            },
            { c1: 'LIVA-1-P2',
              c2: 'El impuesto se calculará aplicando a los valores que señala esta Ley, la tasa del 16%. El impuesto al valor agregado en ningún caso se considerará que forma parte de dichos valores.'
            },

          ]
        },
        { title1: '', title2: 'Concepto de acreditamiento',
          content: [
            { c1: 'LIVA-4-P1',
              c2: 'El acreditamiento consiste en restar el impuesto acreditable, de la cantidad que resulte de aplicar a los valores señalados en esta Ley la tasa que corresponda según sea el caso.'
            },
            { c1: '',
              c2: 'CONCEPTO DE IMPUESTO ACREDITABLE'
            },
            { c1: 'LIVA-4-P2',
              c2: 'Para los efectos del párrafo anterior, se entiende por impuesto acreditable el impuesto al valor agregado que haya sido trasladado al contribuyente y el propio impuesto que él hubiese pagado con motivo de la importación de bienes o servicios, en el mes de que se trate.'
            },
          ]
        },
        { title1: '', title2: 'DETERMINACION DEL PAGO MENSUAL',
          content: [
            { c1: '',
              c2: 'Cálculo del impuesto mensual'
            },
            { c1: 'LIVA-5D-P1',
              c2: 'El impuesto se calculará por cada mes de calendario, salvo los casos señalados en los artículos 5o.-E, 5o.-F y 33 de esta Ley.'
            },
            { c1: 'LIVA-5D-P3',
              c2: 'El pago mensual será la diferencia entre el impuesto que corresponda al total de las actividades realizadas en el mes por el que se efectúa el pago, a excepción de las importaciones de bienes tangibles, y las cantidades por las que proceda el acreditamiento determinadas en los términos de esta Ley. En su caso, el contribuyente disminuirá del impuesto que corresponda al total de sus actividades, el impuesto que se le hubiere retenido en dicho mes.'
            },
            { c1: '',
              c2: 'Acreditamiento o devolución de saldos a favor'
            },
            { c1: 'LIVA-6-P1',
              c2: 'Cuando en la declaración de pago resulte saldo a favor, el contribuyente únicamente podrá acreditarlo contra el impuesto a su cargo que le corresponda en los meses siguientes hasta agotarlo o solicitar su devolución. Cuando se solicite la devolución deberá ser sobre el total del saldo a favor.'
            },
          ]
        },
        
      ],
    }
  },

  watch: {
  	'$parent.showFLegalesComp': function(newVal, oldVal) {
      if(newVal == true){
        this.$refs['modal-flegales'].show();
      }
    }
  }
}
</script>
