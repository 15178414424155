<template lang="html">
    <div id="catalog-form-page">
  
      <section class="container oversized-container main-section">
        <div class="row pt-4">
          <div class="col col-lg-3 pl-sm-0 col-link">
            <a class="link-back" href="javascript:history.back();" ><i class="fal fa-chevron-left icon"></i> <span>Regresar</span></a>
          </div>
  
          <div class="col-lg col-titles">
            <h1>Multas derivadas de la norma oficial mexicana NOM-035-STPS-2018</h1>
            <hr />
          </div>
  
          <div class="col col-lg-3 col-empty"></div>
        </div>
  
        <div class="row justify-content-center">
          <!-- Paso 1, Form -->
          <div class="col-12 col-mid col-form" v-if="step == 1">
            <h5 class="mb-4 title">DATOS GENERALES</h5>
  
            <b-form @submit="onSubmit">
                <b-form-group class="custom-form-group-s2"
                label="Año del cálculo:"
                label-for="i-anio">
                <b-form-select  v-model="form.anio" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled selected>Año</b-form-select-option>
                    <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>
                </b-form-select>
                </b-form-group>

                <b-form-group class="custom-form-group-s2"
                label="Número de empleados o colaboradores:"
                label-for="i-agui" id="input-numero_colaboradores" >
                <b-form-input
                    id="i-co"
                    v-model="form.numero_colaboradores"
                    type="text"
                    placeholder="0.00"
                    @keypress="isNumber($event)"

                ></b-form-input>
                <div v-if="validation.numero_colaboradores != null" style="color: red;"><small>{{validation.numero_colaboradores}}</small></div>
                </b-form-group>

                <b-form-group class="custom-form-group-s2"
                label="Clasificación de tu empresa:"
                label-for="i-anio">
                <b-form-select  v-model="form.clasificacion_empresa" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled selected>Selecciona una opcion</b-form-select-option>
                    <b-form-select-option :value="cla.value" v-for="(cla, yindx) in clasfications" :key="yindx">{{cla.name}}</b-form-select-option>
                </b-form-select>
                </b-form-group>

                <b-form-group class="custom-form-group-s2"
                label="¿Cuenta con centros de trabajo con 50 empleados o más?:"
                label-for="i-anio" v-if="form.numero_colaboradores < 50">
                <b-form-select  v-model="form.centrotrabajo_mascicuenta" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled selected>Selecciona una opcion</b-form-select-option>
                    <b-form-select-option :value="0">No</b-form-select-option>
                    <b-form-select-option :value="1">Si</b-form-select-option>
                </b-form-select>
                </b-form-group>

                <b-form-group class="custom-form-group-s2"
                label="¿Implemento la norma oficial mexicana nom-035-stps-2018?:"
                label-for="i-anio">
                <b-form-select  v-model="form.implmento_nom" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled selected>Selecciona una opcion</b-form-select-option>
                    <b-form-select-option :value="0">No</b-form-select-option>
                    <b-form-select-option :value="1">Si</b-form-select-option>
                </b-form-select>
                </b-form-group>

                <b-form-group class="custom-form-group-s2"
                label="¿Se trata de la reincidencia por no implementar la nom-035-stps-2018 a petición de la stps?:"
                label-for="i-anio"  v-if="form.implmento_nom == 0">
                <b-form-select  v-model="form.es_reincidencia" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled selected>Selecciona una opcion</b-form-select-option>
                    <b-form-select-option :value="0">No</b-form-select-option>
                    <b-form-select-option :value="1">Si</b-form-select-option>
                </b-form-select>
                </b-form-group>
                
                <b-form-group class="custom-form-group-s2"
                label="¿La implementación de la norma oficial mexicana nom-035-stps-2018, cumplio el estandar de verificación por parte de la stps?:"
                label-for="i-anio"  v-if="form.implmento_nom == 1">
                <b-form-select  v-model="form.cumplio_estandar" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled selected>Selecciona una opcion</b-form-select-option>
                    <b-form-select-option :value="0">No</b-form-select-option>
                    <b-form-select-option :value="1">Si</b-form-select-option>
                </b-form-select>
                </b-form-group>

                <b-form-group class="custom-form-group-s2"
                label="¿Se trata de reincidencia porque no se cumplió con el estandar de verificación por parte de la stps?:"
                label-for="i-anio"  v-if="form.cumplio_estandar == 0">
                <b-form-select  v-model="form.reincidencia_nocumplioestandar" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled selected>Selecciona una opcion</b-form-select-option>
                    <b-form-select-option :value="0">No</b-form-select-option>
                    <b-form-select-option :value="1">Si</b-form-select-option>
                </b-form-select>
                </b-form-group>

              <p class="mt-3 mt-lg-5 pt-sm-4 text-center">
                <b-button type="submit" class="_btn-st1 btn-blue" id="btn_calc">Calcular</b-button>
              </p>
            </b-form>
          </div>
          <!-- Paso 1, FIN -->
  
          <!-- Paso 2, Mostrar datos -->
          <div class="col-12 col-mid col-results" v-if="step == 2" style="flex: 0 0 1000px !important;max-width: 1000px !important;">
            <!-- <h5 class="title">DATOS GENERALES</h5> -->
            
            <div class="row">
              <!-- <div class="col-12 col-line">
                <hr />
              </div> -->
  
              <div class="col-12 col-subbox">
                <div class="row align-items-center">
                   <!-- <div class="col col-text">
                    <h2 class="trans-250 title with-link" @click="scrolltoTable">CONSULTAR FUNDAMENTOS LEGALES <i class="fas fa-info-circle icon"></i></h2>
                  </div> -->
  
                  <!-- <div class="col col-share">
                    <a class="trans-150 btn-share" v-b-modal.modal-compartir>Compartir <i class="fas fa-share-alt"></i></a>
                  </div> -->
                </div>
              </div>
  
              <div class="col-12 col-info">
                <div class="box-responsive">
                    <div class="content">
     

                    <div class="row mt-3" v-if="showNormas">
                        <div class="col-12 col-tableinfo">
                        <div class="row row-body mb-2">
                            <div class="col col-4 text-center"></div>
                            <div class="col col-4 text-center"><strong class="f-w-600">RANGO DE LA MULTA EN PESOS</strong></div>
                            <div class="col col-4 text-center"><strong class="f-w-600">RANGO DE LA MULTA EN UMA</strong></div>
                        </div>

                        <div class="row row-body table-irow table-irow-info bor-top">
                            <div class="col col-4">MULTAS POR INCUMPLIMIENTO A LA LEY FEDERAL DEL TRABAJO:</div>
                            <div class="col col-2">Mínima</div>
                            <div class="col col-2">Máxima</div>
                            <div class="col col-2">Mínima</div>
                            <div class="col col-2">Máxima</div>
                        </div>

                        <div class="row row-body table-irow">
                            <div class="col col-4">Por no observar en la instalación de sus establecimientos las normas de seguridad e higiene o las medidas que fijen las Leyes para prevenir los riesgos de trabajo (por 1 trabajador o colaborador)</div>
                            <div class="col col-2">{{detailInfo.pornoobserervar.min_pesos}}</div>
                            <div class="col col-2">{{detailInfo.pornoobserervar.max_pesos}}</div>
                            <div class="col col-2">{{detailInfo.pornoobserervar.min_uma}}</div>
                            <div class="col col-2">{{detailInfo.pornoobserervar.max_uma}}</div>
                        </div>

                        <div class="row row-body table-irow">
                            <div class="col col-4">Cuando en un solo acto u omisión se afecten a varios trabajadores, se impondrá sanción por cada uno de los trabajadores afectados. Si con un solo acto u omisión se incurre en diversas infracciones, se aplicarán las sanciones que correspondan a cada una de ellas, de manera independiente</div>
                            <div class="col col-2">{{detailInfo.cuando_afectatrabajadores.min_pesos}}</div>
                            <div class="col col-2">{{detailInfo.cuando_afectatrabajadores.max_pesos}}</div>
                            <div class="col col-2">{{detailInfo.cuando_afectatrabajadores.min_uma}}</div>
                            <div class="col col-2">{{detailInfo.cuando_afectatrabajadores.max_uma}}</div>
                        </div>

                        <div class="row row-body table-irow table-irow-info">
                            <div class="col col-4">MULTAS DERIVADAS DE UNA VERIFICACIÓN POR PARTE DE LA STPS</div>
                            <div class="col col-2">Mínima</div>
                            <div class="col col-2">Máxima</div>
                            <div class="col col-2">Mínima</div>
                            <div class="col col-2">Máxima</div>
                        </div>

                        <div class="row row-body table-irow">
                            <div class="col col-4">¿LA IMPLEMENTACIÓN DE LA NORMA Oficial Mexicana NOM-035-STPS-2018, NO CUMPLIO CON EL ESTANDAR DE VERIFICACIÓN POR PARTE DE LA STPS? IMPLEMENTACIÓN DE PAPEL</div>
                            <div class="col col-2">{{detailInfo.no_cumplio.min_pesos}}</div>
                            <div class="col col-2">{{detailInfo.no_cumplio.max_pesos}}</div>
                            <div class="col col-2">{{detailInfo.no_cumplio.min_uma}}</div>
                            <div class="col col-2">{{detailInfo.no_cumplio.max_uma}}</div>
                        </div>

                        <div class="row row-body table-irow" v-if="detailInfo.tipo_empresa == 'MICROEMPRESAS'" style="background: linear-gradient(to right, #00b506, #fbff00);">
                        <div class="col col-4">TIPO DE EMPRESA A CONSIDERAR POR LA AUTORIDAD AL MOMENTO DE FIJAR EL MONTO MÍNIMO O MÁXIMO DE LA MULTA</div>
                            <div class="col col-4">{{detailInfo.tipo_empresa}}</div>
                            <div class="col col-4">{{detailInfo.tipo_empresa}}</div>
                        </div>

                        <div class="row row-body table-irow" v-else-if="detailInfo.tipo_empresa == 'PEQUEÑAS EMPRESAS'" style="background: linear-gradient(to right,#00b506, #fbff00 15%, #ff5e00 170%);">
                            <div class="col col-4">TIPO DE EMPRESA A CONSIDERAR POR LA AUTORIDAD AL MOMENTO DE FIJAR EL MONTO MÍNIMO O MÁXIMO DE LA MULTA</div>
                            <div class="col col-4">{{detailInfo.tipo_empresa}}</div>
                            <div class="col col-4">{{detailInfo.tipo_empresa}}</div>
                        </div>

                        <div class="row row-body table-irow" v-else-if="detailInfo.tipo_empresa == 'MEDIANAS EMPRESAS'" style="background: linear-gradient(to right,#fbff00 , #ff5e00 15%, red 109%);">
                            <div class="col col-4">TIPO DE EMPRESA A CONSIDERAR POR LA AUTORIDAD AL MOMENTO DE FIJAR EL MONTO MÍNIMO O MÁXIMO DE LA MULTA</div>
                            <div class="col col-4">{{detailInfo.tipo_empresa}}</div>
                            <div class="col col-4">{{detailInfo.tipo_empresa}}</div>
                        </div>

                        <div class="row row-body table-irow" v-else-if="detailInfo.tipo_empresa == 'GRANDES EMPRESAS'" style="background: linear-gradient(to right,  #ff5e00, red 50%);">
                            <div class="col col-4">TIPO DE EMPRESA A CONSIDERAR POR LA AUTORIDAD AL MOMENTO DE FIJAR EL MONTO MÍNIMO O MÁXIMO DE LA MULTA</div>
                            <div class="col col-4">{{detailInfo.tipo_empresa}}</div>
                            <div class="col col-4">{{detailInfo.tipo_empresa}}</div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <br>

                <div v-if="showNormas">
                    <div class="col-12 px-0 text-center" v-if="detailInfo.desglose.length > 0">
                    <button type="button" class="btn f-w-500" style="background-color: #186baa;color: white;" @click="showBreakdown = true">Mostrar desglose</button>
                    </div>

                    <div class="box-responsive" v-if="showBreakdown">
                    <div class="content">
                        <div class="row mt-3" style="border-top: 1px solid rgba(0, 0, 0, 0.3);">
                        <br><br>
                        <div class="row row-body mb-2">
                            <div class="col col-4 text-center"></div>
                            <div class="col col-4 text-center"><strong class="f-w-600">RANGO DE LA MULTA EN PESOS</strong></div>
                            <div class="col col-4 text-center"><strong class="f-w-600">RANGO DE LA MULTA EN UMA</strong></div>
                        </div>

                        <div class="col-12 col-tableinfo" v-for="(des,indxs) in detailInfo.desglose" :key="indxs">

                            <div class="row row-body table-irow">
                            <div class="col col-4">{{des.name}}</div>
                            <div class="col col-2">{{des.min_total}}</div>
                            <div class="col col-2">{{des.max_total}}</div>
                            <div class="col col-2">{{des.min_uma}}</div>
                            <div class="col col-2">{{des.max_uma}}</div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
  
  
              </div>
  
              <div class="col-12 col-options">
                <p class="mb-3">
                  <a type="button" class="btn _btn-st1" :href="detailInfo.links.excel" target="_blank">Exportar a Excel</a>
                  <a type="button" class="btn _btn-st1" :href="detailInfo.links.pdf" target="_blank">Exportar a PDF</a>
                </p>
                <p>
                  <b-button type="submit" class="_btn-st1 btn-blue" @click="otherCalc">Hacer otro cálculo</b-button>
                </p>
              </div>
  
            </div>
          </div>
  
          <div class="col-12 ml-lg-4 col-actions" v-if="step == 2">
            <div class="box">
              <h5 class="mb-2 a-title-1">ACCIONES</h5>
  
              <div class="box-btns">
                <a class="btn-action" :href="detailInfo.links.excel" target="_blank"><i class="icon i-xls"></i> <span>Exportar a Excel</span></a>
                <a class="btn-action" :href="detailInfo.links.pdf" target="_blank"><i class="icon i-pdf"></i> Exportar a PDF</a>
                <!-- <a class="btn-action" href="#"><i class="icon i-share"></i> Compartir</a> -->
                <button class="btn-action" @click="$refs['modal-personalize'].show()"><i class="icon i-edt"></i> Personalizar</button>
              </div>
  
              <!-- <div class="box-line">
                <hr />
              </div>
  
              <div class="box-content">
                <b-card class="card-s1">
                  <a v-b-toggle.collapse-1-inner class="a-title-1">FUNDAMENTOS LEGALES</a>
                  <b-collapse id="collapse-1-inner" class="collapse-content">
                    <b-card>
                      <p>Proximamente...</p>
                    </b-card>
                  </b-collapse>
                </b-card>
              </div> -->
  
            </div>
          </div>
          <!-- Paso 2, FIN -->
  
          <!-- Fundamentos legales -->
          <flegales-component id="table-flegales"></flegales-component>
          <!--  -->
  
        </div>
      </section>
  
      <!-- ******** Modals ******** -->
      <!-- Modal "Compartir a Correo" -->
      <b-modal id="modal-compartir" ref="modal-compartir" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc modal-class="modal-st-1">
        <b-form class="content" @submit="onSubmitComp">
          <div class="box-content">
            <h5 class="title">Escribe el correo a compartir</h5>
  
            <b-form-group class="custom-form-group"
              label="Correo"
              label-for="il-email">
              <b-form-input
                id="il-email"
                v-model="formComp.email"
                type="email"
                placeholder="correo@ejemplo.com"
                required
              ></b-form-input>
            </b-form-group>
          </div>
  
          <div class="box-footer">
            <button type="button" class="btn _btn-st1" @click="$refs['modal-compartir'].hide()">Cancelar</button>
            <b-button type="submit" class="_btn-st1 btn-blue">Compartir</b-button>
          </div>
        </b-form>
      </b-modal>
      <!--  -->
  
      <!-- Modal "Guardar calculo" -->
      <b-modal id="modal-gcalculo" ref="modal-gcalculo" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc modal-class="modal-st-1">
        <b-form class="content" @submit="onSubmitCalc">
          <div class="text-center box-content">
            <i class="fal fa-check-circle icon-success"></i>
            <h5 class="title">¡Cálculo guardado con éxito!</h5>
          </div>
  
          <div class="box-footer">
            <router-link type="button" class="btn _btn-st1" to="/catalogos">Salir</router-link>
            <b-button type="submit" class="_btn-st1 btn-blue">Hacer otro cálculo</b-button>
          </div>
        </b-form>
      </b-modal>
      <!--  -->
      <b-modal id="modal-loading" ref="modal-loading" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc modal-class="modal-st-1">
          <div style="text-align: center;">
            <img class="img-fluid" src="public/images/shared/logo.svg" style="width: 230px">
            <br><br><h3>Realizando cálculo</h3>
          </div>
      </b-modal>
  
      <b-modal id="modal-personalize" ref="modal-personalize" hide-footer hide-header centered  modal-class="modal-st-1">
          <b-form @submit="onSubmitPersonalize">
              <b-form-group class="col-lg-6 custom-group-s1" label="Logo" >
                      <div class="col-sm-9">
                          <div class="fileinput fileinput-new" data-provides="fileinput">
                            <div class="fileinput-preview fileinput-exists thumbnail" >
                            </div>
                            <div>
                              <span class="btn btn-white btn-file">
                                <input type="file" accept=".pdf,image/*" name="logo">
                              </span>
                            </div>
                          </div>
                      </div>
                </b-form-group>
                <div class="box-footer">
                  <button  type="button" class="btn _btn-st1" @click="$refs['modal-personalize'].hide()">Cancelar</button>
                  <b-button style="text-align: center;" type="submit" class="_btn-st1 btn-blue">Personalizar</b-button>
                 
                </div>
                 <div class="box-footer" style="text-align: center;">
                   <a style="text-align: center;" v-if="pesonalizeUrl" class="btn _btn-st1 btn-blue" :href="pesonalizeUrl" target="_blank"><i class="fas fa-download"></i> Descargar</a>
                 </div>
  
          </b-form>
      </b-modal>
  
    </div>
  </template>
  
  <script>
  // import ComponentModalFlegales from './components/fleg-modal-actu-recargos.vue';
  import FlegalesComponent from './components/fleg-comp-actualizacion-y-recargos.vue';
  export default {
    data(){
      return{
        step: 1,
        // showModalFLegales: false,
        showFLegalesComp: false,
  
        form: {
          anio:null,
          numero_colaboradores:null,
          clasificacion_empresa:null,
          centrotrabajo_mascicuenta:null,
          implmento_nom:null,
          es_reincidencia:null,
          cumplio_estandar:null,
          reincidencia_nocumplioestandar:null,
          /*mes1: 1,
          dia1:17,
          anio1:2021,
  
          mes2: 2,
          dia2:23,
          anio2:2021,
          contribucion:369962.85*/
        },
  
        formComp: {
          email: null
        },
  
        years:[],
        months:[
          {value:1,name:'Enero'},
          {value:2,name:'Febrero'},
          {value:3,name:'Marzo'},
          {value:4,name:'Abril'},
          {value:5,name:'Mayo'},
          {value:6,name:'Junio'},
          {value:7,name:'Julio'},
          {value:8,name:'Agosto'},
          {value:9,name:'Septiembre'},
          {value:10,name:'Octubre'},
          {value:11,name:'Noviembre'},
          {value:12,name:'Diciembre'},
        ],
        clasfications:[
            {value:'micro',name:'Micro empresas'},
            {value:'pequenas',name:'Pequeñas empresas'},
            {value:'medianas',name:'Medianas empresas'},
            {value:'grandes',name:'Grandes empresas'},
        ],
        daysone:[],
        daystwo:[],
  
        detailInfo:[],
        validation:{
            numero_colaboradores:null,
         
        },
        pesonalizeUrl:null,
        rawImg:null,
        showNormas: true,
        showBreakdown:false,
      }
    },
    watch:{
      'form.mes1':function(val)
      {
        var fecha = new Date();
        if (this.form.anio1 == null) {
            var anio = fecha.getFullYear();
        }
        else{
            var anio  = this.form.anio1;
        }
        var d = new Date(anio, val, 0);
        var days =[];
        for (var i = 1; i <= d.getDate(); i++) {
          var aux = {
            'value':i
          };
          days.push(aux);
        }
        this.daysone = days;
      },
      'form.anio1':function(val)
      {
        var fecha = new Date();
        if (val == null) {
            var anio = fecha.getFullYear();
        }
        else{
            var anio  = val;
        }
        var d = new Date(anio, this.form.mes1, 0);
        var days =[];
        for (var i = 1; i <= d.getDate(); i++) {
          var aux = {
            'value':i
          };
          days.push(aux);
        }
        this.daysone = days;
      },
      'form.mes2':function(val)
      {
        var fecha = new Date();
        if (this.form.anio2 == null) {
            var anio = fecha.getFullYear();
        }
        else{
            var anio = this.form.anio2;
        }
        var d = new Date(anio, val, 0);
        var days =[];
        for (var i = 1; i <= d.getDate(); i++) {
          var aux = {
            'value':i
          };
          days.push(aux);
        }
        this.daystwo = days;
      },
      'form.anio2':function(val)
      {
        var fecha = new Date();
        if (val == null) {
            var anio = fecha.getFullYear();
        }
        else{
            var anio  = val;
        }
        var d = new Date(anio, this.form.mes2, 0);
        var days =[];
        for (var i = 1; i <= d.getDate(); i++) {
          var aux = {
            'value':i
          };
          days.push(aux);
        }
        this.daystwo = days;
      },
    },
    methods: {
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
  
      onSubmit(event) { // Enviar formulario del paso 1
        this.validation.numero_colaboradores = null;

        event.preventDefault();
        
        if (this.form.numero_colaboradores < 0 || this.form.numero_colaboradores == null) {
            this.validation.numero_colaboradores = '"Numero de empleados o colaboradores" debe de ser mayor a 0';
            return;
        }
  
        this.$refs['modal-loading'].show();
        $('#btn_calc').hide();
  
        axios.post(tools.url("/calculateTwentyTwo"),this.form).then((response)=>{
            this.detailInfo = response.data;
            this.$refs['modal-loading'].hide();
            this.step = 2;
        }).catch((error)=>{
            $('#btn_calc').show();
            this.$refs['modal-loading'].hide();
           
              alert('El calculo no puede ser realizado con la información ingresada, porfavor modifica los parámetros e intenta de nuevo.');
            
  
  
        });
      },
      otherCalc(){
        location.reload();
      },
  
      scrolltoTable(){
        var tablePosY = document.getElementById("table-flegales").offsetTop;
        this.showFLegalesComp = true;
  
        setTimeout( ()=>{
          window.scrollTo({ top: tablePosY, left: 0, behavior: 'smooth' });
        }, 200);
      },
  
      onSubmitComp(event) { // Enviar formulario compartir del Modal "Compartir a Correo"
        event.preventDefault();
        this.$refs['modal-compartir'].hide();
  
        console.log("Compartir cálculo");
      },
  
      onSubmitCalc(event) { // Guardar cálculo y regresar a paso 1
        event.preventDefault();
  
        var keys = Object.keys(this.form);
        var resetForm = {};
        keys.forEach(key => resetForm[key] = null);
        this.form = resetForm;
  
        this.step = 1;
        this.$refs['modal-gcalculo'].hide();
  
        console.log("Hacer otro cálculo");
      },
      onSubmitPersonalize(event) { 
        event.preventDefault();
          this.pesonalizeUrl = null;
          const file =  jQuery('input[name="logo"]')[0].files[0];
          const reader = new FileReader()
          var self = this;
          reader.onloadend = () => {
             self.rawImg = reader.result;
             self.onSubmitPersonalizeData();
          }
          reader.readAsDataURL(file);
  
      },
      onSubmitPersonalizeData(){
           //enviamos el resultado y el logo
          this.$refs['modal-loading'].show();
  
          var formData = new FormData();
          formData.append("logo", this.rawImg);
          formData.append("formdata", JSON.stringify(this.detailInfo));
  
          axios.post(tools.url("/calculatePesonalize/22"),formData).then((response)=>{
              this.pesonalizeUrl = response.data.link;
              this.$refs['modal-loading'].hide();
              
          }).catch((error)=>{
              this.$refs['modal-loading'].hide();
              alert('Ocurrio un error al generar pdf');
          });
      }
    },
  
    mounted(){
      var fecha = new Date();
      var anio = fecha.getFullYear();
      var years = [];
      for (var i = 1996; i <= anio; i++) {
          var aux = {
            'value':i
          };
          years.push(aux);
      }
  
      years.reverse();
      this.years = years;
    },
  
    components: {
      FlegalesComponent
    },
  }
  </script>
  <style>
    .col-tableinfo{
        .table-irow{
          div{
            padding: 15px 15px;
            border-left: 1px solid rgba(0,0,0,0.15);
            border-bottom: 1px solid rgba(0,0,0,0.15);
            word-break: break-word;

       
          }

          
        }

        .table-irow-info{
          background-color: rgba(193, 199, 202, 0.4);
        }
    }
</style>