<template lang="html">
  <div class="col-12 col-lg-8 col-form">
    <div class="box-action">
      <router-link class="link-back" to="/"><i class="fal fa-chevron-left icon"></i> <span>Regresar</span></router-link>
    </div>

    <div class="box-form">
      <h6 class="simple-title">¿Deseas registrarte?</h6>

      <p class="mt-3">
        Escribe tu correo y contraseña para crear tu cuenta.
      </p>

      <b-form class="form" @submit="onSubmit">
        <b-form-group class="custom-form-group"
          label="Correo"
          label-for="il-email">
          <b-form-input
            id="il-email"
            v-model="form.email"
            type="email"
            placeholder="correo@ejemplo.com"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group class="custom-form-group"
          label="Contraseña"
          label-for="il-email">
          <b-form-input
            id="il-email"
            v-model="form.password"
            type="password"
            placeholder="********"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group class="custom-form-group"
          label="Confirmar contraseña"
          label-for="il-email">
          <b-form-input
            id="il-email"
            v-model="form.passwordConfirm"
            type="password"
            placeholder="********"
            required
          ></b-form-input>
        </b-form-group>

        <p class="mt-5 pt-4">
          <b-button type="submit" class="btn _btn-st1 btn-blue">Registrarse</b-button>
        </p>

        <p class="mt-4">
          ¿Ya tienes una cuenta? <router-link class="link-blue" to="/">Inicia sesión</router-link>
        </p>
      </b-form>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        email: null,
        password: null,
        passwordConfirm: null,
      }
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault()
    }
  }
}
</script>
