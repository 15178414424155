<template lang="html">
  <div class="col-12 col-lg-11 col-xl-9 table-container-s1">
    <div v-if="$parent.showFLegalesComp" style="font-family: sans-serif !important; text-align: justify;">
      <h4 class="table-title">Fundamentos legales</h4>

      <table class="table table-hover" v-for="(item, tabl1Inx) in items" :key="'tabl1Inx-'+tabl1Inx">
        <thead>
          <tr>
            <th scope="col" width="178">{{ item.title1 }}</th>
            <th scope="col">{{ item.title2 }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, rtInx) in item.content">
            <th>{{ row.c1 }}</th>
            <td v-html="row.c2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      items: [
        { 
          title1: 'Ley/Art./Párrafo/ Fracc.', title2: 'Artículo 17-A. Código Fiscal de la Federación (CFF)',
          content: [
            { c1: 'CFF-17A-P1',
              c2: 'El monto de las contribuciones, aprovechamientos, así como de las devoluciones a cargo del fisco federal, se actualizará por el transcurso del tiempo y con motivo de los cambios de precios en el país, para lo cual se aplicará el factor de actualización a las cantidades que se deban actualizar. Dicho factor se obtendrá dividiendo el Indice Nacional de Precios al Consumidor del mes anterior al más reciente del periodo entre el citado índice correspondiente al mes anterior al más antiguo de dicho periodo. Las contribuciones, los aprovechamientos, así como las devoluciones a cargo del fisco federal, no se actualizarán por fracciones de mes.'
            },
            { c1: 'CFF-17A-P2-',
              c2: 'APLICACION DEL ULTIMO INPC PUBLICADO<br>En los casos en que el Indice Nacional de Precios al Consumidor del mes anterior al más reciente del periodo, no haya sido publicado por el Instituto Nacional de Estadística y Geografía, la actualización de que se trate se realizará aplicando el último índice mensual publicado.'
            },
            { c1: 'CFF-17A-P5',
              c2: 'CASO EN QUE EL FACTOR DE ACTUALIZACION SERA 1<br>Cuando el resultado de la operación a que se refiere el primer párrafo de este artículo sea menor a 1, el factor de actualización que se aplicará al monto de las contribuciones, aprovechamientos y devoluciones a cargo del fisco federal, así como a los valores de bienes u operaciones de que se traten, será 1.'
            },
            { c1: 'CFF-17A-P10',
              c2: 'FACTORES O PROPORCIONES HASTA EL DIEZMILESIMO<br>Cuando de conformidad con las disposiciones fiscales se deban realizar operaciones aritméticas, con el fin de determinar factores o proporciones, las mismas deberán calcularse hasta el diezmilésimo.'
            },
          ]
        },
        { title1: 'Ley/Art./Párrafo/ Fracc.', title2: '21. Actualización y recargos de contribuciones y aprovechamientos no cubiertos',
          content: [
            { c1: 'CFF-21-P1',
              c2: '(*) Cuando no se cubran las contribuciones o los aprovechamientos en la fecha o dentro del plazo fijado por las disposiciones fiscales, su monto se actualizará desde el mes en que debió hacerse el pago y hasta que el mismo se efectúe, además deberán pagarse recargos por concepto de indemnización al fisco federal por la falta de pago oportuno. Dichos recargos se calcularán aplicando al monto de las contribuciones o de los aprovechamientos actualizados por el periodo a que se refiere este párrafo, la tasa que resulte de sumar las aplicables en cada año para cada uno de los meses transcurridos en el periodo de actualización de la contribución o aprovechamiento de que se trate. La tasa de recargos para cada uno de los meses de mora será la que resulte de incrementar en 50% a la que mediante Ley fije anualmente el Congreso de la Unión, para tal efecto, la tasa se considerará hasta la centésima y, en su caso, se ajustará a la centésima inmediata superior cuando el dígito de la milésima sea igual o mayor a 5 y cuando la milésima sea menor a 5 se mantendrá la tasa a la centésima que haya resultado.'
            },
            { c1: 'CFF-21-P2',
              c2: 'CAUSACION Y CALCULO DE LOS RECARGOS<br>Los recargos se causarán hasta por cinco años, salvo en los casos a que se refiere el artículo 67 de este Código, supuestos en los cuales los recargos se causarán hasta en tanto no se extingan las facultades de las autoridades fiscales para determinar las contribuciones o aprovechamientos omitidos y sus accesorios, y se calcularán sobre el total del crédito fiscal, excluyendo los propios recargos, la indemnización a que se refiere el párrafo séptimo de este artículo, los gastos de ejecución y las multas por infracción a disposiciones fiscales.'
            },
            { c1: 'CFF-21-P4',
              c2: 'SOBRE DIFERENCIAS Y BASE<br>Cuando el pago hubiera sido menor al que corresponda, los recargos se computarán sobre la diferencia.'
            },
            { c1: 'CFF-21-P5',
              c2: 'SOBRE DIFERENCIAS Y BASE<br>Los recargos se causarán por cada mes o fracción que transcurra a partir del día en que debió hacerse el pago y hasta que el mismo se efectúe.'
            },
            
          ]
        },
        { title1: 'RCFF/Art./Párrafo', title2: '16. Tasa de recargos o intereses aplicable',
          content: [
            { c1: 'RCFF-16',
              c2: 'Para los efectos de los artículos 21 y 22-A del Código, cuando el contribuyente deba pagar recargos o las Autoridades Fiscales deban pagar intereses, la tasa aplicable en un mismo periodo mensual o fracción de éste, será siempre la que esté en vigor al primer día del mes o fracción de que se trate, independientemente de que dentro de dicho periodo la tasa de recargos o de interés varíe.'
            },

          ]
        },
        { title1: 'Ley/Art./Párrafo/ Fracc.', title2: 'Artículo 8o. Recargos por prórroga en el pago de créditos fiscales',
          content: [
            { c1: 'LIF-8-P1-I',
              c2: 'En los casos de prórroga para el pago de créditos fiscales se causarán recargos:<br>I. Al 0.98 por ciento mensual sobre los saldos insolutos."'
            },
          ]
        },
        { title1: 'Resolución Misc./Regla/Párrafo/ Fracc.', title2: '2.1.23 Tasa mensual de recargos',
          content: [
            { c1: 'RM-2.1.23',
              c2: 'Para los efectos de lo dispuesto en el artículo 21 del CFF y con base en la tasa de recargos mensual establecida en el artículo 8, fracción I de la LIF, la tasa mensual de recargos por mora aplicable en el ejercicio fiscal de 2021 es de 1.47%.'
            },
           
          ]
        },
        
      ],
    }
  },

  watch: {
  	'$parent.showFLegalesComp': function(newVal, oldVal) {
      if(newVal == true){
        this.$refs['modal-flegales'].show();
      }
    }
  }
}
</script>
